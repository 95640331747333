import store from '@/store';
import { i18n } from '@/lang';
import ERROR_CODE from './errorCode';

const QUNMS_IP_CONFLICT_INTERFACE_TYPE = {
  WAN: 'wan',
  LAN: 'lan',
  VLAN_WAN: 'vlanWan',
  VLAN_LAN: 'vlanLan',
  BRIDGE: 'bridge',
  STATIC_ROUTE: 'staticRoute',
  RGLINK: 'rglink',
  QVPN_SERVER: 'qvpnServer',
  RESERVED: 'reserved',
  EXTERNAL_VPN: 'extVpn',
  EXTERNAL_VPN_TUNNEL: 'extVpnTun',
  UNKNOWN: 'unknown',
};

const RMA_SPECIFIC_ERROR_MESSAGE = {
  [ERROR_CODE.RMA_QUWAN_DEVICE_STATUS_ERROR]: 'ID_RMA_QUWAN_DEVICE_STATUS_ERROR_MESSAGE',
};
const RMA_SPECIFIC_ERROR_ERROR_CODES = Object.keys(RMA_SPECIFIC_ERROR_MESSAGE)
  .map((errorCode) => Number(errorCode));

const RMA_NETWORK_ERROR_CODES = [
  ERROR_CODE.RMA_CLOUD_INFO_UNREACHABLE,
  ERROR_CODE.RMA_CLOUD_NOT_SIGN_IN,
  ERROR_CODE.RMA_QUWAN_LOGIN_ERROR,
  ERROR_CODE.RMA_QUWAN_CONNECTION_ERROR,
  ERROR_CODE.RMA_FW_COMPATIBLE_CHECK_ERROR,
  ERROR_CODE.RMA_LAUNCH_CLOUD_AGENT_ERROR,
  ERROR_CODE.RMA_SET_QUWAN_STATUS_ERROR,
];

const RMA_INTERNAL_ERROR_CODES = [
  ERROR_CODE.RMA_FOLDER_CREATION_ERROR,
  ERROR_CODE.RMA_SAVE_RESTORE_FILE_ERROR,
  ERROR_CODE.RMA_QUWAN_CONNECTION_ERROR,
  ERROR_CODE.RMA_RESTORE_FLAG_CREATION_ERROR,
  ERROR_CODE.RMA_TARGET_FW_FLAG_CREATION_ERROR,
];

const RMA_RESTORE_FILE_ERROR_CODES = [
  ERROR_CODE.RMA_RESTORE_FILE_NOT_EXIST,
];

export const RMA_ERROR_CODES = [
  ...RMA_SPECIFIC_ERROR_ERROR_CODES,
  ...RMA_NETWORK_ERROR_CODES,
  ...RMA_INTERNAL_ERROR_CODES,
  ...RMA_RESTORE_FILE_ERROR_CODES,
  ERROR_CODE.RMA_DEVICE_BINDING_ERROR,
];

/**
 * Get the error message with the error code
 *
 * @param {string} errorMessage - Error message displayed on GUI
 * @param {number} errorCode - Error code from API
 * @returns {string} Error message with error code
 */
export function getErrorMessageWithCode(errorMessage, errorCode) {
  return `${i18n.t(errorMessage)} (${i18n.t('ID_ERROR_CODE', { code: errorCode })})`;
}

/**
 * Handle result of response with error_code ERROR_CODE.QUNMS_INTERFACE_IP_CONFLICT
 * and generate error message
 * @param {Object} data - data item of result
 * @return {string} error message
 */
export function getQunmsInterfaceIpConflictMessage(data) {
  const { local, remote } = data;
  let messageId = '';
  let extraData = {};

  switch (remote.type) {
    case QUNMS_IP_CONFLICT_INTERFACE_TYPE.WAN:
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_WAN_MSG';
      extraData = { devicePortName: remote.id };
      break;
    case QUNMS_IP_CONFLICT_INTERFACE_TYPE.LAN:
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_LAN_MSG';
      extraData = { devicePortName: remote.id };
      break;
    case QUNMS_IP_CONFLICT_INTERFACE_TYPE.RGLINK:
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_RGLINK_MSG';
      extraData = { devicePortName: remote.id };
      break;
    case QUNMS_IP_CONFLICT_INTERFACE_TYPE.VLAN_WAN:
    case QUNMS_IP_CONFLICT_INTERFACE_TYPE.VLAN_LAN:
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_VLAN_MSG';
      extraData = { deviceVlanId: remote.id };
      break;
    case QUNMS_IP_CONFLICT_INTERFACE_TYPE.STATIC_ROUTE:
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_STATIC_ROUTE_MSG';
      break;
    case QUNMS_IP_CONFLICT_INTERFACE_TYPE.BRIDGE:
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_BRIDGE_MSG';
      extraData = { remoteIfaceDisplay: remote.ifDisplayName };
      break;
    case QUNMS_IP_CONFLICT_INTERFACE_TYPE.EXTERNAL_VPN:
    case QUNMS_IP_CONFLICT_INTERFACE_TYPE.EXTERNAL_VPN_TUNNEL:
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_ROUTE_BASE_VPN';
      break;
    default:
      // Unknown case
      messageId = 'ID_QUWAN_IP_SUBNET_IS_RESERVED_MSG';
  }

  const interfaceId = local.id;
  const interfaceName = store.getters['Network/getInterfaceDisplayText'](interfaceId);
  const message = i18n.t(messageId, {
    interfaceName,
    deviceName: remote.devName,
    ...extraData,
  });

  return message;
}

/**
 * Get the error message for RMA GUI
 * @param {Object} errorResponse - Error response from API
 * @returns {string} Error message for the RMA GUI
 */
export function getRmaErrorMessage(errorResponse) {
  const {
    error_code: errorCode,
    error_message: errorMessage,
  } = errorResponse;

  // The specific error message maps specific error code
  if (RMA_SPECIFIC_ERROR_MESSAGE[errorCode]) {
    return RMA_SPECIFIC_ERROR_MESSAGE[errorCode];
  }

  if (errorCode === ERROR_CODE.RMA_DEVICE_BINDING_ERROR) {
    return `${i18n.t('ID_RMA_NETWORK_ERROR_MESSAGE')} (${i18n.t('ID_ERROR_DETAIL_INFORMATION', { message: errorMessage })})`;
  }

  if (RMA_NETWORK_ERROR_CODES.includes(errorCode)) {
    return getErrorMessageWithCode('ID_RMA_NETWORK_ERROR_MESSAGE', errorCode);
  }

  if (RMA_INTERNAL_ERROR_CODES.includes(errorCode)) {
    return getErrorMessageWithCode('ID_RMA_INTERNAL_ERROR_MESSAGE', errorCode);
  }

  if (RMA_RESTORE_FILE_ERROR_CODES.includes(errorCode)) {
    return getErrorMessageWithCode('ID_RMA_RESTORE_FILE_ERROR_MESSAGE', errorCode);
  }

  return '';
}

/**
 * Get the default message of the unexpected error that the frontend
 * does not have the corresponding message.
 * @param {number} errorCode - The error code from API
 * @returns {string} The error message
 */
export function getDefaultErrorMessage(errorCode) {
  return i18n.t('ID_UNEXPECTED_ERROR_DEFAULT_MSG', { code: errorCode });
}
