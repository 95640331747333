<template>
  <InputValidationBase
    v-slot="{ validated, valid }"
    :rules="rules"
    :disabled="innerDisabled"
    :immediate="immediate"
    :invalid-message="invalidMessage"
    class="df fd-column ai-start"
    :value="value"
  >
    <q-row
      v-if="$slots.behind || short"
      class="w-full"
      align-v="center"
    >
      <q-col
        cols="6"
        class="q-input-col-main"
      >
        <div class="q-input-wrapper">
          <slot
            :input-class="getInputClass(validated, valid)"
            :input-disabled="innerDisabled"
          />
          <LoadingSpinner
            v-if="loading"
            class="loading-icon"
            :width="24"
            :height="24"
          />
        </div>
      </q-col>
      <q-col
        cols="6"
        class="q-input-col-after"
      >
        <slot name="behind" />
      </q-col>
    </q-row>
    <div
      v-else
      class="q-input-wrapper"
    >
      <slot
        :input-class="getInputClass(validated, valid)"
        :input-disabled="innerDisabled"
      />
      <LoadingSpinner
        v-if="loading"
        class="loading-icon"
        :width="24"
        :height="24"
      />
    </div>
  </InputValidationBase>
</template>

<script>
import LoadingSpinner from '@/components/Loading/LoadingSpinner.vue';
import InputValidationBase from './InputValidationBase.vue';

export default {
  name: 'InputWrapper',
  components: {
    InputValidationBase,
    LoadingSpinner,
  },
  props: {
    // This prop is only for <InputValidationBase>.
    value: {},

    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'number', 'select', 'password', 'file', 'textarea'].includes(value);
      },
    },
    rules: {
      type: Object,
      default: null,
    },
    size: {
      type: String,
      default: 'sm',
      validator(value) {
        return ['lg', 'md', 'sm'].includes(value);
      },
    },

    invalidMessage: {
      type: String,
      default: '',
    },

    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * If immediate is "true", Vee-validate will validate this field immediately
     * regardless of whether the user has clicked the submit button.
     * This property should only be used for key field which will greatly affect the layout.
     */
    immediate: {
      type: Boolean,
      default: false,
    },

    short: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    innerDisabled() {
      return !!(this.disabled || this.loading);
    },
  },
  methods: {
    /**
     * Get class list of input
     * @param {boolean|null} state - state of input
     * @returns {Object}
     */
    getInputClass(validated, valid) {
      let classes = {
        'q-input': true,
        [`q-input-type-${this.type}`]: true,
        [`q-input-size-${this.size}`]: true,
        'q-input-is-loading': this.loading,
      };

      if (validated) {
        classes = {
          ...classes,
          'q-input-state-valid': valid && !this.immediate,
          'q-input-state-error': !valid,
        };
      }

      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
$switch-icon: url("@/assets/icons/input/ic_input_select.svg")
  center right rem(12px)/rem(8px 10px) no-repeat;
.q-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.q-input-col-main {
  padding-right: rem(10px) !important;
}
.q-input-col-after {
  padding-left: rem(10px) !important;
}

.q-input-wrapper ::v-deep .q-input {
  //TODO: Move the select class into a individual CSS file to lower the css priority
  &.q-input-type-select {
    padding-right: rem(36px);
    background: color("white") $switch-icon;
    opacity: 1;
    appearance: none;

    &.placeholder {
      color: theme-color("placeholder");
    }

    &:invalid {
      color: theme-color("placeholder");
    }

    & > option {
      color: color("black");

      &:disabled {
        color: theme-color("placeholder", 0.9);
      }
    }
  }
  &.q-input-type-password {
    padding-right: rem(36px);
    background: none !important;
  }
}

.loading-icon {
  position: absolute !important;
  // 12px: half the height of <LoadingSpinner>
  top: calc(50% - 12px);
  left: 1rem;
}
</style>
