import MILLISECONDS from '@/enums/Milliseconds';
import { handleStoredTime } from './system';

const TOKEN_STARTING_USING_TIME_KEY = 'tokenStartingUsingTime';

// Be consistent with the backend setting
export const API_TOKEN_AVAILABLE_USAGE_TIME = 5 * MILLISECONDS.HOUR;

/**
 * Get the starting using time of the token
 * @returns {number} Time of starting using the token
 */
function getTokenStartingUsingTime() {
  const tokenUsingTime = localStorage.getItem(TOKEN_STARTING_USING_TIME_KEY);

  return handleStoredTime(tokenUsingTime);
}

/**
 * Remove the starting using time of token from local storage
 * @returns {void}
 */
export function clearTokenStartingUsingTime() {
  localStorage.removeItem(TOKEN_STARTING_USING_TIME_KEY);
}

/**
 * Check whether the starting using time of token exists
 * @returns {boolean} True if the starting using time of token exists
 */
export function hasTokenStartingUsingTime() {
  const startingUsingTime = localStorage.getItem(TOKEN_STARTING_USING_TIME_KEY);

  return handleStoredTime(startingUsingTime) > 0;
}

/**
 * Get the time which token is expiring
 * @returns {number} Time of expiring token
 */
export function getTokenExpiringTime() {
  return getTokenStartingUsingTime() + API_TOKEN_AVAILABLE_USAGE_TIME;
}

/**
 * Check whether the API token is expired
 * @returns {boolean} True if the API token has expired
 */
export function isTokenExpired() {
  const tokenUsedTime = Date.now() - getTokenStartingUsingTime();

  return tokenUsedTime > API_TOKEN_AVAILABLE_USAGE_TIME;
}

/**
 * Set the starting using time of the token
 * @param {number} startingIdleTime - Time for starting using the token
 * @returns {void}
 */
export function setTokenStartingUsingTime(startingUseTime) {
  localStorage.setItem(TOKEN_STARTING_USING_TIME_KEY, startingUseTime);
}
