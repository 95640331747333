import store from '@/store';

export default {
  install(Vue) {
    Vue.prototype.$profile = {
      /* model based properties */
      get name() {
        return store.state.Profile.modelName;
      },
      get isQpkg() {
        return store.state.Profile.isQpkg;
      },
      get isMiro() {
        return store.getters['Profile/isMiro'];
      },
      get isMiroPlus() {
        return store.getters['Profile/isMiroPlus'];
      },
      get isMiroSeries() {
        return store.getters['Profile/isMiroSeries'];
      },
      get isHora() {
        return store.getters['Profile/isHora'];
      },
      get isHora321() {
        return store.getters['Profile/isHora321'];
      },
      get isHora322() {
        return store.getters['Profile/isHora322'];
      },
      get isHoraSeries() {
        return store.getters['Profile/isHoraSeries'];
      },
      get isApMode() {
        return store.getters['Profile/isApMode'];
      },
      get isWebContentFilter() {
        return store.getters['Profile/isWebContentFilter'];
      },

      /* feature based properties */
      get supportWireless() {
        return store.getters['Profile/supportWireless'];
      },
      get supportApMode() {
        return store.getters['Profile/supportApMode'];
      },
      get supportUpnp() {
        return store.getters['Profile/supportUpnp'];
      },
      get supportIpv6() {
        return store.getters['Profile/supportIpv6'];
      },
      get supportUsb() {
        return store.getters['Profile/supportUsb'];
      },
      get supportNetworkV3() {
        return store.getters['Profile/supportNetworkV3'];
      },
      get supportIptv() {
        return store.getters['Profile/supportIptv'];
      },
      get supportMesh() {
        return store.getters['Profile/supportMesh'];
      },
      get supportFirewall() {
        return store.getters['Profile/supportFirewall'];
      },
      get supportCustomPort() {
        return store.getters['Profile/supportCustomPort'];
      },
      get supportDpiModule() {
        return store.getters['Profile/supportDpiModule'];
      },
    };
  },
};
