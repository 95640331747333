import store from '@/store';
import NOTIFY_LEVEL from '@/enums/NotifyLevel';

export default {
  install(Vue) {
    const confirmHandler = (type, options) => {
      options = (typeof options === 'string' || Array.isArray(options)) ? { title: options } : options;
      const emptyFunction = () => {};

      options = {
        confirm: emptyFunction,
        cancel: emptyFunction,
        title: '',
        confirmLabel: type === NOTIFY_LEVEL.HELP ? 'ID_YES' : 'ID_APPLY',
        cancelLabel: type === NOTIFY_LEVEL.HELP ? 'ID_NO' : 'ID_CANCEL',
        ...options,
      };
      options.type = type;

      const afterConfirm = options.confirm;
      const afterCancel = options.cancel;

      return new Promise((resolve) => {
        options.confirm = () => {
          afterConfirm();
          store.commit('Plugin/Confirm/hide');
          resolve(true);
        };
        options.cancel = () => {
          afterCancel();
          store.commit('Plugin/Confirm/hide');
          resolve(false);
        };

        store.commit('Plugin/Confirm/activate', options);
      });
    };

    Vue.prototype.$confirm = {
      help: (options) => confirmHandler(NOTIFY_LEVEL.HELP, options),
      warn: (options) => confirmHandler(NOTIFY_LEVEL.WARN, options),
    };
  },
};
