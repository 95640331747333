import { AirGap } from '@/services';
import { SET_AIR_GAP_DEVICES } from '@/store/mutation-types';

const state = {
  airGapDevices: [],
};

const getters = {
  pairedPortNames(state) {
    const backupJobs = state.airGapDevices
      .map((device) => device.backupJobs)
      .flat();
    const portIds = backupJobs
      .map((job) => job.portIds)
      .flat();

    // Remove the repetitive port ID
    return [...new Set(portIds)];
  },
};

const actions = {
  async fetchAirGapDevices({ rootGetters, commit }) {
    if (!rootGetters['Profile/supportAirGap']) {
      return;
    }

    const devices = await AirGap.getDevices();

    commit(SET_AIR_GAP_DEVICES, devices);
  },
};

const mutations = {
  [SET_AIR_GAP_DEVICES](state, devices) {
    state.airGapDevices = devices;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
