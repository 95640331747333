import 'whatwg-fetch';
import { merge, isEmpty } from 'lodash';
import store from '@/store';
import { CUSTOM_ERROR_TYPE } from '@/common/consts';
import { SYSTEM_RESTORE_MIN_FIRMWARE_VERSION } from '@/common/firmware';
import { clearSystemEnvironment } from '@/common/system';
import { getAuthorization } from '@/common/utilities';
import NOTIFY_LEVEL from '@/enums/NotifyLevel';
import { i18n } from '@/lang';
import router from '@/router';
import ERROR_CODE from './errorCode';
import {
  RMA_ERROR_CODES,
  getDefaultErrorMessage,
  getRmaErrorMessage,
  getErrorMessageWithCode,
} from './errorHandler';

const isHoraSeries = () => store.getters['Profile/isHoraSeries'];

const ERROR_MESSAGE = {
  18: 'ID_REMOTE_SUPPORT_SETTING_FAIL_MSG', // Unable to start the remote support session
  503: 'ID_ERR_MSG5', // unexpected error, ask user to reload (try again)

  10000: 'ID_ERR_MSG7', // Please contact support team, get QSN fail
  [ERROR_CODE.LOCAL_ACCOUNT_LOGIN_INVALID]: 'ID_LOGIN_CRED_WRG',
  10034: 'ID_HORA_INIT_TIMEOUT_MSG', // The initialization process timeout, please restart the QHora machine
  10035: 'ID_LOGIN_CRED_WRG', // Login failed : QID first login with invalid Local username or password
  [ERROR_CODE.QID_LOGIN_NETWORK_UNREACHABLE]: 'ID_LOGIN_CRED_WRG', // QID failed to connect to server
  10037: 'ID_LOGIN_CRED_WRG', // Login failed : The QID is not the owner of this device
  10038: 'ID_LOGIN_BMAC_EMPTY', // Login failed : BMAC is empty, please contact customer service
  10040: 'ID_NETWORK_ERROR', // myqnapcloud connect error

  10041: 'ID_LOGIN_CRED_WRG', // User not in the specified organization
  10042: 'ID_LOGIN_CRED_WRG', // No permission to access this organization device
  [ERROR_CODE.MYQNAPCLOUD_ORGANIZATION_MISSING_ERROR]: 'ID_ERROR_MESSAGE_JOIN_MISSING_ORGANIZATION',
  [ERROR_CODE.MYQNAPCLOUD_SITE_MISSING_ERROR]: 'ID_ERROR_MESSAGE_JOIN_MISSING_ORGANIZATION_SITE',
  [ERROR_CODE.MYQNAPCLOUD_API_SERVER_ERROR]: 'ID_ERROR_MESSAGE_JOIN_ORGANIZATION_MYQNAPCLOUD_SERVER_ISSUE',
  [ERROR_CODE.MYQNAPCLOUD_API_ORGANIZATION_LIST_ERROR]: 'ID_ERROR_MESSAGE_QUWAN_ORGANIZATION_LIST',
  [ERROR_CODE.MYQNAPCLOUD_API_SITE_LIST_ERROR]: 'ID_ERROR_MESSAGE_QUWAN_SITE_LIST',
  10048: 'ID_REMOTE_SUPPORT_TOKEN_INVALID_MSG', // myqnapcloud token invalid or expired

  // common errors
  10050: 'ID_ERR_MSG3', // Failed to access database
  [ERROR_CODE.UNDERLYING_COMMAND_ERROR]: 'ID_ERR_MSG4',
  10054: 'ID_ERR_MSG5', // Failure attempting to call API,
  10055: 'ID_ERR_MSG1', // Invalid request body: The format is invalid

  10056: 'ID_QUWAN_MESSAGE_DEVICE_EXIST', // Duplicated device (same serial number) on QuWAN

  [ERROR_CODE.NETWORK_CONFLICT]: 'ID_ERR_IP_CONFLICTED', // IP is conflicted

  10059: 'ID_ERR_MSG2', // Failed to update local account profiles
  [ERROR_CODE.LOCAL_ACCOUNT_DEFAULT_PASSWORD_ERROR]: 'ID_AC_OLD_WRG',
  10063: 'ID_AC_DFL_PRV', // You can’t use the default password as your new password. Please set up another password.
  10064: 'ID_AC_NEW_SAME', // The new password is same as old password.

  10065: 'ID_ERR_SSH1', // Failed to update ssh user
  10066: 'ID_ERR_SSH2', // Failed to create ssh user
  10067: 'ID_ERR_SSH3', // Failed to create user due to the new user name is reserved for system

  10073: 'ID_ERROR_MESSAGE_DUPLICATE_SERVICE_PORT', // service port has been used
  10074: 'ID_NETWORK_ERROR', // Can't connect to internet

  10075: 'ID_QUWAN_RENAME_CURRENT_DEVICE_MSG', // Duplicated device name (different serial number) on QuWAN

  [ERROR_CODE.QUNMS_SUBNET_INVALID_ERROR]: 'ID_ERROR_MESSAGE_INVALID_IP_ADDRESS',

  10103: 'ID_EDIT_DEV_NAME_DUP_QUWAN', // The device name has already been used by quwan.
  [ERROR_CODE.QUNMS_GET_VPN_GROUP_ERROR]: 'ID_ERROR_MESSAGE_QUWAN_VPN_GROUP_LIST',

  [ERROR_CODE.RESTORE_FILE_CORRUPTED_ERROR]: 'ID_RESTORE_FILE_CORRUPTED_ERROR_MESSAGE',
  [ERROR_CODE.RESTORE_DEVICE_VERSION_ERROR]: 'ID_RESTORE_DEVICE_VERSION_ERROR_MESSAGE',
  [ERROR_CODE.RESTORE_FILE_COPY_ERROR]: 'ID_RESTORE_FILE_COPY_ERROR_MESSAGE',
  [ERROR_CODE.RESTORE_DEVICE_MODEL_MISMATCH_ERROR]: 'ID_WRONG_DEVICE_MODEL_TO_RESTORE_ERROR_MESSAGE', // The file model is different.

  10121: 'ID_EDIT_DEV_NAME_DUP', //  Duplicated device name
  10122: 'ID_ERR_MSG5', // Failed to get device info by myqnapcloud.
  10204: 'ID_FAIL_QTS_HNAME',
  10205: 'ID_FAIL_QTS_HNAME',
  [ERROR_CODE.QUNMS_DEPLOYMENT_FAILED]: 'ID_QUWAN_DEPLOYMENT_FAILED_MESSAGE',
  [ERROR_CODE.QUNMS_CONNECT_DOMAIN_FAILED]: 'ID_QUWAN_CONNECT_DOMAIN_FAILED_MSG',

  10600: 'ID_VPN_WIREGUARD_GENERATE_KEY_ERR',
  10601: 'ID_VPN_WIREGUARD_PROFILE_NAME_DUP',
  10602: 'ID_VPN_WIREGUARD_DOWNLOAD_CONFIG_ERR',

  10705: 'ID_NETWORK_SETTINGS_ARE_PROVISIONING_MSG',

  10751: 'ID_REMOTE_SUPPORT_NETWORK_UNSTABLE_ERR',
  10752: 'ID_REMOTE_SUPPORT_REMOTE_SERVER_BUSY_ERR',
  [ERROR_CODE.RMA_ORGANIZATION_ID_NOT_EXIST]: 'ID_RMA_RESTORE_FILE_ORGANIZATION_ID_NOT_EXIST',
  [ERROR_CODE.USB_PACKET_CAPTURE_USB_NOT_PLUGED]: 'ID_USB_PACKET_CAPTURE_USB_NOT_PLUGED',
  [ERROR_CODE.USB_PACKET_CAPTURE_USB_SUPPORT]: 'ID_USB_SUPPORT',
  [ERROR_CODE.USB_PACKET_CAPTURE_USB_DETECT_REMOVING]: 'ID_USB_PACKET_CAPTURE_USB_REMOVED',
  [ERROR_CODE.USB_PACKET_CAPTURE_USB_NOT_ENOUGH]: 'ID_USB_PACKET_CAPTURE_USB_NOT_ENOUGH',
  [ERROR_CODE.LAN_INTERFACE_IS_LAST]: 'ID_LAN_INTERFACE_AT_LEAST_ONE_MSG',
};

const ERROR_CODE_TYPE = {
  10705: NOTIFY_LEVEL.WARN,
};

const PORT_TYPE = {
  WAN: 'WAN',
  LAN: 'LAN',
  VLAN: 'VLAN',
  STATIC_ROUTE: 'STATIC_ROUTE',
};

const QUNMS_PORT_TYPE = {
  WAN: 'wan',
  LAN: 'lan',
  REGIONAL_LINK: 'rglink',
};

const NETWORK_ERROR_STATUS = {
  RESERVED_SUBNET: 'device_setting_invalid_subnet',
  CONFLICT_SUBNET: 'device_setting_conflict_subnet',
  CONFLICT_VLAN: 'device_setting_conflict_vlan',
  CONFLICT_STATIC_ROUTE: 'device_setting_conflict_static_route',
};

const IP_CONFLICT_TYPE_TEXT_MAPPING = {
  vlan: 'ID_MENU_VLAN',
  vlanif_wan: 'ID_MENU_VLAN',
  vlanif_lan: 'ID_MENU_VLAN',
  wan: 'ID_MENU_WAN',
  lan: 'ID_MENU_LAN',
  static_route: 'ID_SR_STATIC_ROUTE',
};

const RULE_CONFLICT_TYPE_TEXT_MAPPING = {
  blockList: 'ID_CLIENT_BL',
  ddns: 'ID_MENU_DDNS',
  dmz: 'ID_DMZ_TITLE',
  firewall: 'ID_MENU_FIREWALL',
  portForward: 'ID_PF_PORT_FORWARDING',
  staticRoute4: `${i18n.t('ID_NETWORK_TAG_IPV4')} ${i18n.t('ID_SR_STATIC_ROUTE')}`,
  staticRoute6: `${i18n.t('ID_NETWORK_TAG_IPV6')} ${i18n.t('ID_SR_STATIC_ROUTE')}`,
  upnp: 'ID_MENU_UPNP',
  igmpProxy: `${i18n.t('ID_IPTV_IPTV_LABEL')} - ${i18n.t('ID_IPTV_MODE_IGMP_PROXY')}`,
};

// TODO: use const to handle this error code
const FAILED_TO_FETCH_ERROR = {
  error_code: 90000,
  message: 'ID_ERR_FAILED_TO_FETCH',
  type: NOTIFY_LEVEL.ERROR,
};

/**
 * Error type of custom message
 *
 * @param {string} name - Name of error
 * @param {string|string[]} message - Message of error
 * @param {string} [type] - Type of error
 * @returns {undefined}
 */
function CustomMessageError(name, message, type = NOTIFY_LEVEL.ERROR) {
  this.name = name;
  this.message = message;
  this.type = type;
}

/**
 * Get message of VLAN conflict on QuNMS
 *
 * @param {Object} port - Port conflict on QuNMS
 * @returns {string} Message of port conflict on QuNMS
 */
function getVlanInvalidMessage(port) {
  const qunmsPort = port.valueList;
  const vlanId = port.id.split(PORT_TYPE.VLAN)[1];
  const RESERVED_VLAN_ID = {
    LAN: '1',
    GUEST_WIFI: (isHoraSeries()) ? '4094' : '4080',
  };

  let message = '';

  if (port.status === NETWORK_ERROR_STATUS.CONFLICT_SUBNET) {
    switch (vlanId) {
      case RESERVED_VLAN_ID.LAN:
        message = i18n.t(
          'ID_ERROR_MESSAGE_LAN_CONFLICT_QUNMS_PORT',
          {
            ip: port.ip,
            device_name: qunmsPort.devName,
            qunms_port_type: qunmsPort.portType.toUpperCase(),
            qunms_port_number: qunmsPort.portNum,
          },
        );
        break;
      case RESERVED_VLAN_ID.GUEST_WIFI:
        message = i18n.t(
          'ID_ERROR_MESSAGE_GUEST_WIRELESS_CONFLICT_QUNMS_PORT',
          {
            ip: port.ip,
            device_name: qunmsPort.devName,
            qunms_port_type: qunmsPort.portType.toUpperCase(),
            qunms_port_number: qunmsPort.portNum,
          },
        );
        break;
      default:
        message = i18n.t(
          'ID_ERROR_MESSAGE_VLAN_CONFLICT_QUNMS_PORT',
          {
            ip: port.ip,
            vlan_id: vlanId,
            device_name: qunmsPort.devName,
            qunms_port_type: qunmsPort.portType.toUpperCase(),
            qunms_port_number: qunmsPort.portNum,
          },
        );
        break;
    }
  } else if (port.status === NETWORK_ERROR_STATUS.CONFLICT_VLAN) {
    switch (vlanId) {
      case RESERVED_VLAN_ID.LAN:
        message = i18n.t(
          'ID_ERROR_MESSAGE_LAN_CONFLICT_QUNMS_VLAN',
          {
            ip: port.ip,
            device_name: qunmsPort.devName,
            qunms_vlan_id: qunmsPort.vlanId,
          },
        );
        break;
      case RESERVED_VLAN_ID.GUEST_WIFI:
        message = i18n.t(
          'ID_ERROR_MESSAGE_GUEST_WIRELESS_CONFLICT_QUNMS_VLAN',
          {
            ip: port.ip,
            device_name: qunmsPort.devName,
            qunms_vlan_id: qunmsPort.vlanId,
          },
        );
        break;
      default:
        message = i18n.t(
          'ID_ERROR_MESSAGE_VLAN_CONFLICT_QUNMS_VLAN',
          {
            ip: port.ip,
            vlan_id: vlanId,
            device_name: qunmsPort.devName,
            qunms_vlan_id: qunmsPort.vlanId,
          },
        );
        break;
    }
  } else if (port.status === NETWORK_ERROR_STATUS.RESERVED_SUBNET) {
    switch (vlanId) {
      case RESERVED_VLAN_ID.GUEST_WIFI:
        message = i18n.t(
          'ID_ERROR_MESSAGE_GUEST_WIFI_SUBNET_RESERVED_ON_QUNMS',
          {
            subnet: `${port.ip}/${port.submask}`,
          },
        );
        break;
      default:
        if (isHoraSeries()) {
          message = i18n.t(
            'ID_ERROR_MESSAGE_VLAN_SUBNET_RESERVED_ON_QUNMS',
            {
              vlan_id: vlanId,
              subnet: `${port.ip}/${port.submask}`,
            },
          );
        } else {
          message = i18n.t(
            'ID_ERROR_MESSAGE_LAN_SUBNET_RESERVED_ON_QUNMS',
            {
              subnet: `${port.ip}/${port.submask}`,
            },
          );
        }
        break;
    }
  } else if (port.status === NETWORK_ERROR_STATUS.CONFLICT_STATIC_ROUTE) {
    if (isHoraSeries()) {
      message = i18n.t(
        'ID_ERROR_MESSAGE_VLAN_CONFLICT_QUNMS_STATIC_ROUTE',
        {
          vlan_id: vlanId,
          subnet: `${port.ip}/${port.submask}`,
          device_name: qunmsPort.devName,
        },
      );
    } else {
      message = i18n.t(
        'ID_ERROR_MESSAGE_VLAN_CONFLICT_QUNMS_STATIC_ROUTE_MIRO',
        {
          subnet: `${port.ip}/${port.submask}`,
          device_name: qunmsPort.devName,
        },
      );
    }
  }

  if (message) {
    return qunmsPort.sugIPNet
      ? `${message} ${i18n.t('ID_ERROR_MESSAGE_NETWORK_CONFLICT_SUGGESTION_SUBNET', { 'IP subnet': qunmsPort.sugIPNet })}`
      : message;
  }

  return ERROR_MESSAGE[ERROR_CODE.QUNMS_SUBNET_INVALID_ERROR];
}

/**
 * Handle result data of response with error_code ERROR_CODE.QUNMS_SUBNET_INVALID_ERROR
 * and generate error message
 * @param {Object} data - data of result
 * @returns {string} error message
 */
function getInterfaceInvalidMessage(data) {
  const deviceData = data.valueList;
  let messageId = '';
  let extraData = {};

  if (data.status === NETWORK_ERROR_STATUS.CONFLICT_SUBNET) {
    if (deviceData.portType === QUNMS_PORT_TYPE.WAN) {
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_WAN_MSG';
      extraData = { devicePortName: deviceData.portNum };
    } else if (deviceData.portType === QUNMS_PORT_TYPE.LAN) {
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_LAN_MSG';
      extraData = { devicePortName: deviceData.portNum };
    } else if (deviceData.portType === QUNMS_PORT_TYPE.REGIONAL_LINK) {
      messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_RGLINK_MSG';
      extraData = { devicePortName: deviceData.portNum };
    }
  } else if (data.status === NETWORK_ERROR_STATUS.CONFLICT_VLAN) {
    messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_VLAN_MSG';
    extraData = { deviceVlanId: deviceData.vlanId };
  } else if (data.status === NETWORK_ERROR_STATUS.CONFLICT_STATIC_ROUTE) {
    messageId = 'ID_QUWAN_IP_SUBNET_CONFLICT_STATIC_ROUTE_MSG';
  } else if (data.status === NETWORK_ERROR_STATUS.RESERVED_SUBNET) {
    messageId = 'ID_QUWAN_IP_SUBNET_IS_RESERVED_MSG';
  }

  if (messageId) {
    let interfaceId = data.id;

    if (interfaceId.includes(PORT_TYPE.WAN)) {
      [, interfaceId] = interfaceId.split(PORT_TYPE.WAN);
    } else if (interfaceId.includes(PORT_TYPE.LAN)) {
      [, interfaceId] = interfaceId.split(PORT_TYPE.LAN);
    }
    const interfaceName = store.getters['Network/getInterfaceDisplayText'](interfaceId);
    const message = i18n.t(messageId, {
      interfaceName,
      deviceName: deviceData.devName,
      ...extraData,
    });

    return deviceData.sugIPNet
      ? `${message} ${i18n.t('ID_ERROR_MESSAGE_NETWORK_CONFLICT_SUGGESTION_SUBNET', { 'IP subnet': deviceData.sugIPNet })}`
      : message;
  }

  return ERROR_MESSAGE[ERROR_CODE.QUNMS_SUBNET_INVALID_ERROR];
}

/**
 * Handle message of subnet invalid on QuNMS
 * Subnet invalid includes:
 * - Subnet conflict
 * - Subnet reserved on QuNMS
 * @param {Object[]} ports - List of ports invalid on QuNMS
 * @param {number} errorCode - The error code from API
 * @returns {string} Message of invalid network
 */
function handleInvalidSubnetMessage(ports, errorCode) {
  const errorMessages = [];

  ports.forEach((port) => {
    if (port.id.includes(PORT_TYPE.VLAN)) {
      errorMessages.push(getVlanInvalidMessage(port));
    } else {
      errorMessages.push(getInterfaceInvalidMessage(port));
    }
  });

  if (errorMessages.length) {
    return errorMessages;
  }

  return getDefaultErrorMessage(errorCode);
}

/**
 * Fetch API Init Setup
 *
 * @method fetchRequest
 * @param {String} path - API Path
 * @param {Object} data - Payload Data
 * @param {Object|null} [option] - Optional setting for the fetch request
 * @returns {Object} Fetch Initial Setting
 */
export function fetchRequest(path, data = '', option = null) {
  const body = data ? JSON.stringify(data) : data;
  let fetchOption = {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthorization()}`,
    }),
    mode: 'cors',
    cache: 'no-cache',
    // credentials: 'include', // request附帶cookie
  };

  if (body) fetchOption.body = body;
  fetchOption = merge({}, fetchOption, option);

  return new Request(path, fetchOption);
}

export function queryPath(path, query) {
  if (!isEmpty(query)) {
    const queryString = Object.keys(query)
      .map((key) => `${key}=${query[key]}`)
      .join('&');

    return `${path}?${queryString}`;
  }

  return path;
}

async function handleResponse(response) {
  let res = {};

  // Handle all error response
  if (!response.ok) {
    if (response.status !== 404) {
      try {
        // Call json() to fix incomplete response body issue
        res = await response.json();
      } catch (error) {
        res = { error_code: -1 };
      }
    } else {
      res = { error_code: 404 };
    }

    if (res.error_code === ERROR_CODE.QUNMS_INTERFACE_IP_CONFLICT) {
      const errorData = {
        name: CUSTOM_ERROR_TYPE.QUNMS_INTERFACE_IP_CONFLICT,
        conflictConfigs: res.result,
      };

      throw errorData;
    }
    const errorData = {
      error_code: res.error_code,
      message: ERROR_MESSAGE[res.error_code] || getDefaultErrorMessage(res.error_code),
      type: ERROR_CODE_TYPE[res.error_code] || NOTIFY_LEVEL.ERROR,
      result: res.result,
    };

    if (res.error_code === 10121) {
      // 10121: duplicate device name
      errorData.result = res.error_message;
    } else if (res.error_code === 22) {
      errorData.message = i18n.t('ID_NERWORK_IP_OR_SUBNET_CONFLICT', {
        title: i18n.t(IP_CONFLICT_TYPE_TEXT_MAPPING[res.result.conflict_type]),
        ip: res.result.conflict_IP,
      });
    } else if (res.error_code === ERROR_CODE.FEATURE_INTERFACE_CONFLICT) {
      errorData.message = i18n.t('ID_CHANGED_INTERFACE_USED_MSG', {
        features: res.result
          .map((item) => i18n.t(RULE_CONFLICT_TYPE_TEXT_MAPPING[item.ruleFunc]))
          .filter((item) => item)
          .join(', '),
      });
    } else if (res.error_code === ERROR_CODE.SCHEDULE_TIME_CONFLICT_WITH_FIRMWARE_SCHEDULE) {
      errorData.message = i18n.t('ID_SCHEDULE_CONFLICT_MSG', { feature: i18n.t('ID_FW_UPDATE_TITLE') });
    } else if (res.error_code === ERROR_CODE.SCHEDULE_TIME_CONFLICT_WITH_RESTART_SCHEDULE) {
      errorData.message = i18n.t('ID_SCHEDULE_CONFLICT_MSG', { feature: i18n.t('ID_RESTART_SCHEDULE_TITLE') });
    } else if (res.error_code === ERROR_CODE.SCHEDULE_TIME_CONFLICT_WITH_WIRELESS_SCHEDULE) {
      errorData.message = i18n.t('ID_SCHEDULE_CONFLICT_MSG', { feature: i18n.t('ID_WIRELESS_SCHEDULE_LABEL') });
    } else if (RMA_ERROR_CODES.includes(res.error_code)) {
      errorData.message = getRmaErrorMessage(res);
    } else if (res.error_code === ERROR_CODE.MYQNAPCLOUD_CONNECTION_ERROR
      || res.error_code === ERROR_CODE.DDNS_FAILED_TO_GET_DEIVCE_INFO) {
      errorData.message = getErrorMessageWithCode('ID_DDNS_NETWORK_DISCONNECTED_MSG', errorData.error_code);
    } else if (res.error_code === ERROR_CODE.RESTORE_FILE_INCOMPATIBILITY) {
      errorData.message = i18n.t('ID_RESTORE_FILE_INCOMPATIBILITY_MESSAGE', { version: SYSTEM_RESTORE_MIN_FIRMWARE_VERSION });
    }

    // workaround for duplicate service port condition
    if (res.error_code === ERROR_CODE.UNDERLYING_COMMAND_ERROR && res.error_message.includes('port range conflict')) {
      errorData.error_code = 10073;
      errorData.message = ERROR_MESSAGE[ERROR_CODE.DUPLICATED_SERVICE_PORT];
    }

    throw errorData;
  }

  if (response.status === 200) {
    try {
      res = await response.json();
    } catch (error) {
      return {};
    }

    if ([ERROR_CODE.TOKEN_INVALID, ERROR_CODE.TOKEN_EXPIRED].includes(res.error_code)) {
      const errorData = {
        error_code: res.error_code,
        message: '',
        type: NOTIFY_LEVEL.ERROR,
      };

      clearSystemEnvironment();
      router.push({ name: 'Login' }).catch((err) => {});

      throw errorData;
    }

    // Errors with basic data (error_code, message)
    if ([
      ERROR_CODE.LOCAL_ACCOUNT_LOGIN_INVALID, 10210, 10211, 10212,
    ].includes(res.error_code)) {
      // 10210, 10211, 10212: failed to add node
      const errorData = {
        error_code: res.error_code,
        message: ERROR_MESSAGE[res.error_code] || getDefaultErrorMessage(res.error_code),
        type: NOTIFY_LEVEL.ERROR,
      };

      throw errorData;
    }

    // Errors with additional data (error_code, original message, result)
    if ([10070, 10072, 10204, 10205, 10058].includes(res.error_code)) {
      // 10070, 10072: failed to verify country code
      // 10204, 10205: failed to get qts hostname
      // 10058: IP conflict
      const errorData = {
        error_code: res.error_code,
        message: res.error_message,
        type: NOTIFY_LEVEL.ERROR,
        result: res.result,
      };

      throw errorData;
    }

    // Errors will return original response
    if ([10012, 10014, 10015, 10016, 10017, 10019].includes(res.error_code)) {
      // 10012, 10014, 10015, 10016, 10017, 10019: firmware update
      return res;
    }

    if (res.error_code === ERROR_CODE.MYQNAPCLOUD_CONNECTION_ERROR && response.url.endsWith('/user_profiles/qid')) {
      // unbind success at local but failed at myqnapcloud
      return res;
    }

    // Errors with custom format
    switch (res.error_code) {
      case ERROR_CODE.QUNMS_SUBNET_INVALID_ERROR: {
        let errorMessage = res.result.data
          ? handleInvalidSubnetMessage(res.result.data, res.error_code)
          : res.result.errorMessage;

        errorMessage = Array.isArray(errorMessage) ? errorMessage : [errorMessage];

        throw new CustomMessageError(CUSTOM_ERROR_TYPE.QUNMS_NETWORK_CONFLICT, errorMessage);
      }
      case ERROR_CODE.QUNMS_ADD_VPN_TUNNEL_LIMITATION: {
        const devices = res.result.data || [];
        const DEFAULT_MAXIMUM_VPN_TUNNEL = 0;

        // The maximum VPN tunnel will apply to all devices which created in the same organization
        const vpnTunnelMaximum = devices[0]?.valueList?.maxVPNTunnels || DEFAULT_MAXIMUM_VPN_TUNNEL;
        const errorData = {
          name: CUSTOM_ERROR_TYPE.QUNMS_VPN_TUNNEL_CHECK,
          result: {
            vpnTunnelMaximum,
          },
        };

        throw errorData;
      }
      case ERROR_CODE.QUNMS_DEPLOYMENT_FAILED: {
        throw new CustomMessageError(
          CUSTOM_ERROR_TYPE.QUNMS_DEPLOYMENT,
          ERROR_MESSAGE[ERROR_CODE.QUNMS_DEPLOYMENT_FAILED],
        );
      }
      default: {
        if (res.error_code) {
          const errorData = {
            error_code: res.error_code,
            message: ERROR_MESSAGE[res.error_code] || getDefaultErrorMessage(res.error_code),
            type: ERROR_CODE_TYPE[res.error_code] || NOTIFY_LEVEL.ERROR,
            result: res.result,
          };

          throw errorData;
        }
      }
    }
  } else if (response.status === 204) {
    // No content
    res.error_code = 0;

    return res;
  }

  return res;
}

/**
* Retry the fetch API when fetch data failed
* Note:
* - To retry the APIs using the old token after the token has been refreshed
* - To avoid unexpected errors and behaviors in the QPKG environment
*   refrain from checking the extended idle time
* @param {Request} request - Request config for the fetch API
* @param {number} delay - Interval time between two API retries
* @param {number} remainingCount - Remaining counts to retry API
* @returns {Promise<Object>} Cloned response of fetch
*/
async function retryFetch(request, delay = 1000, remainingCount = 5) {
  const response = await fetch(request);
  const clonedResponse = response.clone();

  if (response.status === 200) {
    try {
      const jsonResponse = await response.json();

      if ([ERROR_CODE.TOKEN_INVALID, ERROR_CODE.TOKEN_EXPIRED].includes(jsonResponse.error_code)) {
        if (remainingCount > 0) {
          // Avoid retrying API too intensive
          await new Promise((resolve) => {
            setTimeout(resolve, delay);
          });
          const token = getAuthorization();

          request.headers.set('Authorization', `Bearer ${token}`);

          return retryFetch(request, delay, remainingCount - 1);
        }

        return clonedResponse;
      }
    } catch (error) {
      // When invoking the API with myqnapcloud domain name
      // the API returns empty response with status 200 if the QuRouter API has network error
      // Handle the kind of error the same as HTTP status 204
      return clonedResponse;
    }
  }

  return clonedResponse;
}

export const qFetch = {
  async get(path, query = {}, options = {}) {
    try {
      options.method = 'GET';
      const formattedPath = queryPath(path, query);
      const request = fetchRequest(formattedPath, '', options);
      const response = await retryFetch(request);
      const rs = await handleResponse(response);

      return rs;
    } catch (error) {
      if (error.toString().indexOf('fetch') >= 0) { // Failed to fetch
        throw FAILED_TO_FETCH_ERROR;
      }

      throw error;
    }
  },
  async post(path, payload, options = {}) {
    try {
      options.method = 'POST';
      const request = fetchRequest(path, payload, options);
      const rs = await retryFetch(request).then((response) => handleResponse(response));

      return rs;
    } catch (error) {
      if (error.toString().indexOf('fetch') >= 0) { // Failed to fetch
        throw FAILED_TO_FETCH_ERROR;
      }

      throw error;
    }
  },
  async put(path, payload, options = {}) {
    try {
      options.method = 'PUT';
      const request = fetchRequest(path, payload, options);
      const rs = await retryFetch(request).then((response) => handleResponse(response));

      return rs;
    } catch (error) {
      if (error.toString().indexOf('fetch') >= 0) { // Failed to fetch
        throw FAILED_TO_FETCH_ERROR;
      }

      throw error;
    }
  },
  async delete(path, payload, options = {}) {
    try {
      options.method = 'DELETE';
      const request = fetchRequest(path, payload, options);
      const rs = await retryFetch(request).then((response) => handleResponse(response));

      return rs;
    } catch (error) {
      if (error.toString().indexOf('fetch') >= 0) { // Failed to fetch
        throw FAILED_TO_FETCH_ERROR;
      }

      throw error;
    }
  },
  async patch(path, payload, options = {}) {
    try {
      options.method = 'PATCH';
      const request = fetchRequest(path, payload, options);
      const rs = await retryFetch(request).then((response) => handleResponse(response));

      return rs;
    } catch (error) {
      if (error.toString().indexOf('fetch') >= 0) { // Failed to fetch
        throw FAILED_TO_FETCH_ERROR;
      }

      throw error;
    }
  },
  async upload(path, formData) {
    try {
      const options = {
        method: 'POST',
        body: formData,
        headers: new Headers({
          Authorization: `Bearer ${getAuthorization()}`,
        }),
      };
      const rs = await fetch(path, options).then((response) => handleResponse(response));

      return rs;
    } catch (error) {
      if (error.toString().indexOf('fetch') >= 0) { // Failed to fetch
        throw FAILED_TO_FETCH_ERROR;
      }

      throw error;
    }
  },

  // Download file with fetch API
  // Based on: https://stackoverflow.com/a/42274086
  async download(path, filename) {
    const request = fetchRequest(path);
    const response = await fetch(request);

    if (response.status === 200) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      let downloadFilename = 'download';

      if (filename) {
        downloadFilename = filename;
      } else {
        const contentDisposition = response.headers.get('content-disposition');
        const filenameParameter = contentDisposition
          .split(';')
          .find((param) => param.includes('filename'));

        if (filenameParameter) {
          [, downloadFilename] = filenameParameter.split('=');
        }
      }

      link.href = url;
      link.download = downloadFilename;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      await handleResponse(response);
    }
  },
};
