<template>
  <div id="app">
    <transition
      name="component-fade"
      mode="out-in"
    >
      <router-view v-if="isRouterDisplayed" />
    </transition>

    <DialogContainer
      v-for="dialog in dialogs"
      :key="dialog.type"
      :type="dialog.type"
      :config="dialog.config"
    />
    <AppLoading />
    <ToastBase />
    <ModalConfirm
      :id="`app-confirm${$store.state.Plugin.Confirm.key}`"
      :key="$store.state.Plugin.Confirm.key"
      :message="$store.state.Plugin.Confirm.title"
      :type="$store.state.Plugin.Confirm.type"
      :visible="$store.state.Plugin.Confirm.visible"
      :confirm-label="$store.state.Plugin.Confirm.confirmLabel"
      :cancel-label="$store.state.Plugin.Confirm.cancelLabel"
      @cancel="$store.state.Plugin.Confirm.cancel"
      @confirm="$store.state.Plugin.Confirm.confirm"
    />

    <ModalConfirm
      id="redirect_warning"
      type="help"
      message="ID_REDIRECT_MSG"
      confirm-label="ID_REDIRECT_QTS"
      cancel-label="ID_REDIRECT_QUROUTER"
      @confirm="redirectQts"
    />
    <FirmwareUpdateConfirm
      :qurouter-version="qurouterVersion"
      :force-update="forceUpdate"
    />
    <QpkgUpdateConfirm :force-update="forceUpdate" />

    <!-- The order of the notification modal is behind the confirmation modal -->
    <ModalNotify
      id="notify"
      :visible="$store.state.Plugin.Notify.visible"
      :message="$store.state.Plugin.Notify.text"
      :type="$store.state.Plugin.Notify.type"
      @cancel="$store.state.Plugin.Notify.hidden"
    />
  </div>
</template>

<script>
import { get as getCookie } from 'js-cookie';
import { mapState, mapActions, mapMutations } from 'vuex';
import SERVICES from '@/services';
import { versionCompare } from '@/common/utilities';
import Language from '@/mixins/Language';
import DialogContainer from '@/components/Dialog/DialogContainer.vue';
import ModalConfirm from '@/components/Modal/ModalConfirm.vue';
import ModalNotify from '@/components/Modal/ModalNotify.vue';
import ToastBase from '@/components/ToastBase.vue';
import AppLoading from '@/views/App/AppLoading.vue';
import { LANGUAGE_CODE } from '@/lang';
import FirmwareUpdateConfirm from './FirmwareUpdateConfirm.vue';
import QpkgUpdateConfirm from './QpkgUpdateConfirm.vue';

export default {
  name: 'App',
  components: {
    DialogContainer,
    AppLoading,
    ToastBase,
    ModalNotify,
    ModalConfirm,
    FirmwareUpdateConfirm,
    QpkgUpdateConfirm,
  },
  mixins: [Language],
  data() {
    return {
      isRouterDisplayed: false,
      retryGetInitStatusCnt: 0,
      qts_ip: '',
      qurouterVersion: '',
      qpkgVersion: '',
    };
  },
  computed: {
    ...mapState('Plugin/Dialog', ['dialogs']),
    ...mapState('Initial', ['machineInfo', 'initPathName']),

    forceUpdate() {
      return this.qurouterVersion.startsWith('1.') || this.qpkgVersion.startsWith('1.');
    },
  },
  watch: {
    '$route.name': {
      handler() {
        // Remove loading modal when leave page
        this.$loading.hide();
      },
    },
  },
  created() {
    // Indicate whether browser is IE, based on: https://stackoverflow.com/a/22551342
    if (/MSIE|Trident/.test(window.navigator.userAgent)) {
      this.$notify.warn('ID_BROSWER_ERR');
    }

    this.init();
  },
  methods: {
    ...mapMutations('Profile', ['setProfile']),
    ...mapActions('Initial', ['getInitStatus', 'getQtsRedirect']),

    async init() {
      try {
        this.$loading.show();
        this.isRouterDisplayed = false;

        await this.getInitStatus();

        this.setProfile({
          modelName: this.machineInfo.model,
          isQpkg: this.machineInfo.is_qpkg,
        });
        document.title = `${this.machineInfo.hostname} | ${this.machineInfo.model}`;

        if (this.$profile.isMiroPlus && !this.$profile.isQpkg) {
          await this.checkQtsRedirectStatus();
        }

        if (this.$profile.isQpkg) {
          const qtsLang = getCookie('nas_lang') || LANGUAGE_CODE.AUTO;

          await this.changeLang(qtsLang);
          await this.checkFirmwareVersion();
        } else {
          await this.changeLang(this.machineInfo.language);
        }

        this.isRouterDisplayed = true;

        if (this.initPathName !== '' && this.initPathName !== this.$router.currentRoute.name
          && this.$router.currentRoute.name !== 'Login') {
          this.$router.push({ name: this.initPathName });
        }

        this.$loading.hide();
      } catch (error) {
        if (this.retryGetInitStatusCnt <= 60) {
          this.retryGetInitStatusCnt += 1;

          setTimeout(() => {
            this.init();
          }, 3000);
        } else {
          this.$notify.error('ID_ROUTER_ERR');
        }
      }
    },

    /**
     * Check whether the redirection to the QTS page is enabled
     * @returns {Promise<void>}
     */
    async checkQtsRedirectStatus() {
      const qtsRedirect = await this.getQtsRedirect();

      if (!Object.prototype.hasOwnProperty.call(qtsRedirect, 'result')) {
        return;
      }

      if (!Object.prototype.hasOwnProperty.call(qtsRedirect.result, 'able_redirect')
        || !qtsRedirect.result.able_redirect) {
        return;
      }

      if (!Object.prototype.hasOwnProperty.call(qtsRedirect.result, 'qts_ip')
        || qtsRedirect.result.qts_ip.length === 0) {
        return;
      }

      if (this.$router.currentRoute.name !== 'DebugLogin'
        && this.$router.currentRoute.name !== 'Debug') {
        this.qts_ip = qtsRedirect.result.qts_ip;
        this.$modal.show('redirect_warning');
      }
    },

    /**
     * Redirect URL to the IP of QTS page
     * @returns {void}
     */
    redirectQts() {
      window.location.href = this.qts_ip;
    },

    /**
     * Check the QuRouter firmware version and QPKG version are consistent
     * @returns {Promise<void>}
     */
    async checkFirmwareVersion() {
      try {
        const {
          newerVersionDevice,
          qpkgVersion,
          qurouterVersion,
        } = await SERVICES.INITIAL.getFirmwareVersion();
        const VERSION_COMPARE_RESULT = {
          same: 0,
          qpkg: 1,
          qurouter: -1,
        };
        let newerDevice = VERSION_COMPARE_RESULT[newerVersionDevice];

        if (newerDevice === undefined) {
          newerDevice = versionCompare(qpkgVersion, qurouterVersion);
        }
        this.qurouterVersion = qurouterVersion;
        this.qpkgVersion = qpkgVersion;

        if (newerDevice === VERSION_COMPARE_RESULT.qpkg) {
          this.$modal.show('firmware_update_confirm');
        }

        if (newerDevice === VERSION_COMPARE_RESULT.qurouter) {
          this.$modal.show('qpkg_update_confirm');
        }
      } catch (error) {
        // when frontend app is not launched in qpkg, this api will return 404, just ignore now
      }
    },
  },
};
</script>
