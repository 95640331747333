// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import FloatingVue, { createTooltip, destroyTooltip } from 'floating-vue';
import Vue from 'vue';
import VueSidebarMenu from 'vue-sidebar-menu';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css';
import 'floating-vue/dist/style.css';
import 'vue2-datepicker/index.css';

import '@/scss/app.scss';

import QCol from './components/Layout/QCol.vue';
import QRow from './components/Layout/QRow.vue';

import dateTime from './filters/dateTime';
import vpnProtocol from './filters/vpnProtocol';
import './common/validation';

import { i18n } from './lang';
import Confirm from './plugins/Confirm';
import Dialog from './plugins/Dialog';
import Loading from './plugins/Loading';
import Modal from './plugins/Modal';
import Notify from './plugins/Notify';
import Page from './plugins/Page';
import Profile from './plugins/Profile';
import Toast from './plugins/Toast';
import ViewInit from './plugins/ViewInit';
import router from './router';
import store from './store';
import App from './views/App.vue';

dayjs.extend(isSameOrBefore);

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    Vue,
    dsn: import.meta.env.VITE_SENTRY_DSN,

    // Used to distinguish which environment this event is generated from
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,

        // Capture the request/respond body
        // Based on: https://docs.sentry.io/platforms/javascript/session-replay/configuration/#network-details
        networkDetailAllowUrls: [window.location.origin],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/\/miro\/api/],
    // Session Replay
    // This sets the sample rate at 10%.
    // You may want to change it to 100% while in development
    // and then sample at a lower rate in production.
    replaysSessionSampleRate: 0.1,
    // If you're not already sampling the entire session
    // change the sample rate to 100% when sampling sessions where errors occur.
    replaysOnErrorSampleRate: 1.0,
  });
}

Vue.filter('dateTime', dateTime);
Vue.filter('vpnProtocol', vpnProtocol);

Vue.use(VueSidebarMenu);
Vue.use(FloatingVue, {
  overflowPadding: 15,
  themes: {
    'q-dropdown': {
      $extend: 'dropdown',
    },
    'q-tooltip': {
      $extend: 'tooltip',
      distance: 8,
    },
    'q-popover': {
      $extend: 'menu',
      distance: 8,
      delay: {
        show: 0,
        hide: 200,
      },
    },
  },
});

Vue.use(Dialog);
Vue.use(Loading);
Vue.use(Notify);
Vue.use(Profile);
Vue.use(Confirm);
Vue.use(ViewInit);
Vue.use(Page);
Vue.use(Toast);
Vue.use(Modal);

{
  const PLACEMENTS = [
    'auto', 'auto-start', 'auto-end',
    'top', 'top-start', 'top-end',
    'right', 'right-start', 'right-end',
    'bottom', 'bottom-start', 'bottom-end',
    'left', 'left-start', 'left-end',
  ];
  const createQTooltip = (theme, el, binding) => {
    if (binding.value) {
      let { value } = binding;

      if (typeof value === 'string') {
        value = { content: value };
      }
      value.content = i18n.t(value.content);

      const placement = PLACEMENTS.find((item) => binding.modifiers[item]);

      if (placement) {
        value.placement = placement;
      }

      createTooltip(el, {
        theme,
        ...value,
      });
    }
  };

  Vue.directive('q-tooltip', {
    inserted(el, binding) {
      createQTooltip('q-tooltip', el, binding);
    },
    update(el, binding) {
      destroyTooltip(el);
      createQTooltip('q-tooltip', el, binding);
    },
    unbind(el) {
      destroyTooltip(el);
    },
  });
  Vue.directive('q-popover', {
    inserted(el, binding) {
      createQTooltip('q-popover', el, binding);
    },
    update(el, binding) {
      destroyTooltip(el);
      createQTooltip('q-popover', el, binding);
    },
    unbind(el) {
      destroyTooltip(el);
    },
  });
}

Vue.component('QRow', QRow);
Vue.component('QCol', QCol);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
