import { Firmware } from '@/services';
import { checkFwVersionIsOlder } from '@/common/utilities';

const state = {
  localFirmware: {
    type: '',
    version: '',
    buildDate: 0,
    updateTime: 0,
  },
  officialFirmware: {
    version: '',
    isUpgradeAvailable: false,
    changeLogUrl: '',
  },
  // TODO: show beta firmware remote version info in Dashboard
  betaFirmware: {
    version: '',
    isUpgradeAvailable: false,
    changeLogUrl: '',
  },
  scheduleInfo: {
    updateTime: '',
    updateType: '',
  },
  isBetaJoined: false,
  changeLogUrl: '',
  currentUpgradeType: '',
  retryBackupCount: 0,
  retryBackupInfo: {
    firmwareVersion: '',
    firmwareMd5: '',
  },
};

const storeGetters = {
  isBetaUpgradeAvailable(state) {
    return state.betaFirmware.isUpgradeAvailable
      && checkFwVersionIsOlder(state.officialFirmware.version, state.betaFirmware.version);
  },
};

const mutations = {
  setFirmwareInfo(state, info) {
    const {
      localFwInfo,
      remoteFwInfo,
      remoteBetaFwInfo,
      isUpgradeAvailable,
    } = info;

    /**
     * The QPKG backend version may be older than the frontend version,
     * so need to handle the old API format here.
     *
     * The changes to the API are as follows:
     * - `info.isUpgradeAvailable` -> `info.remoteFwInfo.isUpgradeAvailable`
     * - The new key: `remoteBetaFwInfo`
     */

    state.localFirmware = {
      type: localFwInfo[0].type,
      version: localFwInfo[0].fwVer,
      buildDate: localFwInfo[0].buildDate,
      updateTime: localFwInfo[0].updateTime,
    };
    state.officialFirmware = {
      version: remoteFwInfo.fwVer,
      isUpgradeAvailable: typeof isUpgradeAvailable === 'boolean'
        ? info.isUpgradeAvailable
        : remoteFwInfo.isUpgradeAvailable,
      changeLogUrl: remoteFwInfo.updateInfoURL,
    };

    if (remoteBetaFwInfo) {
      state.betaFirmware = {
        version: remoteBetaFwInfo.fwVer,
        isUpgradeAvailable: remoteBetaFwInfo.isUpgradeAvailable,
        changeLogUrl: remoteBetaFwInfo.updateInfoURL,
      };
    }
  },
  setScheduleInfo(state, scheduleInfo) {
    state.scheduleInfo = scheduleInfo;
  },
  setIsBetaJoined(state, isBetaJoined) {
    state.isBetaJoined = isBetaJoined;
  },
  setChangeLogUrl(state, url) {
    state.changeLogUrl = url;
  },
  startFirmwareUpgrade(state, upgradeType) {
    state.currentUpgradeType = upgradeType;
    state.retryBackupCount = 0;
  },
  increaseRetryBackupCount(state) {
    state.retryBackupCount += 1;
  },
  setRetryBackupInfo(state, retryBackupInfo) {
    state.retryBackupInfo = {
      firmwareVersion: retryBackupInfo.version,
      firmwareMd5: retryBackupInfo.fwImgMd5,
    };
  },
};

const actions = {
  async getFirmwareInfo({ commit }) {
    const res = await Firmware.getFirmware();

    commit('setFirmwareInfo', res.result);
  },
  async getScheduleInfo({ commit }) {
    const scheduleInfo = await Firmware.getSchedule();

    commit('setScheduleInfo', scheduleInfo);
  },
  async getBetaConfig({ commit }) {
    const betaConfig = await Firmware.getBetaConfig();

    commit('setIsBetaJoined', betaConfig.enabled);
  },
  async toggleIsBetaJoined({ state, commit, dispatch }) {
    const newConfig = await Firmware.updateBetaConfig({
      enabled: !state.isBetaJoined,
    });

    commit('setIsBetaJoined', newConfig.enabled);
    await dispatch('getFirmwareInfo');
  },
  async continueUpgrade({ state, commit }, skipBackup) {
    if (!skipBackup) {
      commit('increaseRetryBackupCount');
    }

    await Firmware.continueUpgrade({
      upgradeType: state.currentUpgradeType,
      fwVersion: state.retryBackupInfo.firmwareVersion,
      fwImgMd5: state.retryBackupInfo.firmwareMd5,
      skip: skipBackup,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters: storeGetters,
  mutations,
  actions,
};
