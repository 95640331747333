<template>
  <v-chart
    :id="chartId"
    :style="chartSize"
    :options="options"
  />
</template>

<script>
import { cloneDeep } from 'lodash';
import { getPieDataColor } from '@/common/chart';

// Best-looking size
const PIE_SIZE = 60;
const outsideRadius = 1.2;

export default {
  name: 'ChartPie',
  components: {
    'v-chart': () => import(/* webpackChunkName:"echarts" */ 'vue-echarts'),
  },
  props: {
    dataset: {
      type: Array,
      required: true,
      validator(dataset) {
        return dataset.every((data) => typeof data === 'object'
          && typeof data.name === 'string'
          && (typeof data.value === 'string' || typeof data.value === 'number'));
      },
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      chartId: `pie_chart_${Date.now()}`,
      options: {
        series: {
          type: 'pie',
          radius: [`${PIE_SIZE}%`, `${PIE_SIZE * outsideRadius}%`],
          center: ['50%', '50%'],
          data: this.formattedDataset,
          labelLine: {
            show: false,
          },
          label: {
            show: false,
          },
        },
      },
      chartSize: {
        width: '100%',
        height: `${this.height}px`,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await import(/* webpackChunkName:"echarts" */ 'echarts/lib/chart/pie');

      const options = cloneDeep(this.options);

      // Update data color must wait for pie chart rendered
      options.series.data = this.dataset.map((data, index) => ({
        ...data,
        itemStyle: {
          color: getPieDataColor(index),
        },
      }));

      // Update pie chart
      // Based on: https://github.com/ecomfe/vue-echarts#props-all-reactive
      this.options = options;
    },
  },
};
</script>
