import { IPv4, IPv6, subnetMatch } from 'ipaddr.js';
import { isEqual } from 'lodash';
import { toASCII as toASCIIPunycode } from 'punycode';
import { configure, extend } from 'vee-validate';
import {
  required,
  min,
  max,
  between,
  regex,
} from 'vee-validate/dist/rules';
import store from '@/store';
import { WIRELESS_LIMIT } from '@/common/consts';
import {
  IPSEC_PORTS,
  validateIpv6SubnetId,
  validateIpv6Suffix,
  validatePrefixLength,
  validateFQDN,
  compareIpv6Address,
  normalizeIpv6Ip,
  checkIp4CIDR,
  ip6SubnetOverlapSubnet,
  ip6AddressInSubnet,
  ip6AddressInRange,
  checkIp6Subnet,
  isIp6IpRange,
} from '@/common/network';
import {
  isIpInSubnet,
  isIpRange,
  isIpNetwork,
  convertIpToInt,
  checkMacAddressValid,
  checkIpValid,
  subnetNotOverlapSubnet,
  isASCII,
  validatePorts,
  getProtocolDisplayed,
  convertLinebreak,
  validateWireguardPrivateKey,
  ip4AddressInRange,
  isInteger,
  validateOverlappingPort,
} from '@/common/utilities';
import MTU_LIMIT from '@/enums/MtuLimit';
import NETWORK_INTERFACE from '@/enums/NetworkInterface';
import NETWORK_INTERFACE_ACTION from '@/enums/NetworkInterfaceAction';
import NETWORK_INTERFACE_TYPE from '@/enums/NetworkInterfaceType';
import SERVICE_PORT_PROTOCOL from '@/enums/ServicePortProtocol';
import WAN_IP4_TYPE from '@/enums/WanIp4Type';
import WAN_IP6_TYPE from '@/enums/WanIp6Type';
import { i18n } from '@/lang';
import { checkPortOfServicePortConflict } from './servicePort';

/**
 * List of IPv4 reserved subnet need to check.
 */
const IPV4_RESERVED_SUBNETS = [
  '127.0.0.0/8',
  '169.254.0.0/16',
  '198.18.0.0/15',
  '224.0.0.0/4',
];

// reference: https://github.com/whitequark/ipaddr.js/blob/master/lib/ipaddr.js#L532]
const IPV6_GATEWAY_RESERVED_RANGE_LIST = {
  // RFC4291, here and after
  unspecified: [new IPv6([0, 0, 0, 0, 0, 0, 0, 0]), 128],
  multicast: [new IPv6([0xff00, 0, 0, 0, 0, 0, 0, 0]), 8],
  loopback: [new IPv6([0, 0, 0, 0, 0, 0, 0, 1]), 128],
  uniqueLocal: [new IPv6([0xfc00, 0, 0, 0, 0, 0, 0, 0]), 7],
  ipv4Mapped: [new IPv6([0, 0, 0, 0, 0, 0xffff, 0, 0]), 96],
  // RFC6145
  rfc6145: [new IPv6([0, 0, 0, 0, 0xffff, 0, 0, 0]), 96],
  // RFC6052
  rfc6052: [new IPv6([0x64, 0xff9b, 0, 0, 0, 0, 0, 0]), 96],
  // RFC3056
  '6to4': [new IPv6([0x2002, 0, 0, 0, 0, 0, 0, 0]), 16],
  // RFC6052, RFC6146
  teredo: [new IPv6([0x2001, 0, 0, 0, 0, 0, 0, 0]), 32],
  // RFC4291
  reserved: [new IPv6([0x2001, 0xdb8, 0, 0, 0, 0, 0, 0]), 32],
  benchmarking: [new IPv6([0x2001, 0x2, 0, 0, 0, 0, 0, 0]), 48],
  amt: [new IPv6([0x2001, 0x3, 0, 0, 0, 0, 0, 0]), 32],
  as112v6: [new IPv6([0x2001, 0x4, 0x112, 0, 0, 0, 0, 0]), 48],
  deprecated: [new IPv6([0x2001, 0x10, 0, 0, 0, 0, 0, 0]), 28],
  orchid2: [new IPv6([0x2001, 0x20, 0, 0, 0, 0, 0, 0]), 28],

  // additional
  discardPrefix: [new IPv6([0x100, 0, 0, 0, 0, 0, 0, 0]), 64],
};
const IPV6_IP_RESERVED_RANGE_LIST = {
  ...IPV6_GATEWAY_RESERVED_RANGE_LIST,

  // RFC4291, here and after
  linkLocal: [new IPv6([0xfe80, 0, 0, 0, 0, 0, 0, 0]), 10],
};
const USERNAME_FORMAT = /^[0-9a-zA-Z]{5,32}$/;

export const DHCP_SERVER_RANGE = 65535;

/**
 * Validate whether the description format is valid
 * @param {string} description - Description string from GUI
 * @returns {boolean} True if the description format is valid
 */
export function validateDescription(description) {
  if (typeof description !== 'string') {
    return false;
  }

  if (!description.trim()) {
    return false;
  }

  // Lookup the ASCII code form wiki
  // Based on: https://en.wikipedia.org/wiki/ASCII#Printable_characters
  const FIRST_PRINTABLE_ASCII_CODE = 32;
  const LAST_PRINTABLE_ASCII_CODE = 126;
  const FIRST_DIGIT_CODE = 48;
  const LAST_DIGIT_CODE = 57;
  const FIRST_UPPERCASE_LETTER_CODE = 65;
  const LAST_UPPERCASE_LETTER_CODE = 90;
  const FIRST_LOWERCASE_LETTER_CODE = 97;
  const LAST_LOWERCASE_LETTER_CODE = 122;
  const ACCEPTABLE_SYMBOLS = " -_,.'\"!?;()[]";
  const acceptableSymbolCodes = ACCEPTABLE_SYMBOLS
    .split('')
    .map((symbol) => symbol.charCodeAt());
  const acceptableSymbolCodeSet = new Set(acceptableSymbolCodes);

  // Avoid using the .split('') to the multi-language string to get an unexpected result
  // Based on: https://stackoverflow.com/questions/4547609/how-can-i-get-a-character-array-from-a-string/34717402#34717402
  return [...description]
    .map((char) => char.charCodeAt())
    // Filter the printable characters
    .filter((charCode) => charCode >= FIRST_PRINTABLE_ASCII_CODE
      && charCode <= LAST_PRINTABLE_ASCII_CODE)
    // Filter the symbols
    .filter((charCode) => (charCode < FIRST_DIGIT_CODE || charCode > LAST_DIGIT_CODE)
        && (charCode < FIRST_UPPERCASE_LETTER_CODE || charCode > LAST_UPPERCASE_LETTER_CODE)
        && (charCode < FIRST_LOWERCASE_LETTER_CODE || charCode > LAST_LOWERCASE_LETTER_CODE))
    .every((charCode) => acceptableSymbolCodeSet.has(charCode));
}

/**
 * Check whether the DHCP server range is valid
 * @param {string} startIp - The first IP of the DHCP server
 * @param {string} endIp - The last IP of the DHCP server
 * @returns {boolean} True if the DHCP server range is valid
 */
export function validateDhcpServerRange(startIp, endIp) {
  if (!checkIpValid(startIp)) {
    return true;
  }

  if (!checkIpValid(endIp)) {
    return true;
  }

  const startIpInteger = convertIpToInt(startIp);
  const endIpInteger = convertIpToInt(endIp);

  return endIpInteger - startIpInteger <= DHCP_SERVER_RANGE;
}

/**
 * Validate whether the DS-Lite WAN is allowance
 * @param {string} ip4Type - Type of the IPv4 WAN connection
 * @param {string} ip6Type - Type of the IPv6 WAN connection
 * @returns {boolean} True if the DS-Lite WAN is allowance
 */
export function validateDsliteAllowance(ip4Type, ip6Type) {
  if (ip4Type === WAN_IP4_TYPE.DSLITE) {
    return ip6Type !== WAN_IP6_TYPE.DISABLED;
  }

  return true;
}

/**
 * Validate whether the format of username is valid
 *
 * @param {string} username - Username to validate
 * @returns {boolean} True if the format of username is valid
 */
export function validateUsername(username) {
  return typeof username === 'string'
    && !!username
    && USERNAME_FORMAT.test(username);
}

/**
 * Check IP is not in reserved subnets
 *
 * @param {string} ip
 * @returns {boolean} True if IP is not in reserved subnets or IP is not valid
 */
function validateIpNotInReservedSubnet(ip) {
  if (!checkIpValid(ip)) {
    return true;
  }

  return IPV4_RESERVED_SUBNETS.every((subnet) => {
    const [subnetIp, subnetMask] = subnet.split('/');

    return !isIpInSubnet(ip, subnetIp, subnetMask);
  });
}

/**
 * Check the subnet is not overlap reserved subnets
 * @param {string} ip
 * @param {string|number} mask
 * @returns {boolean} True if subnet not overlapped reserved subnets or given IP is not valid
 */
function validateSubnetNotOverlapReservedSubnets(ip, mask) {
  if (!checkIpValid(ip)) {
    return true;
  }

  return IPV4_RESERVED_SUBNETS.every((subnet) => {
    const [reservedSubnetIp, reservedSubnetMask] = subnet.split('/');

    return subnetNotOverlapSubnet(ip, mask, reservedSubnetIp, reservedSubnetMask);
  });
}

/**
 * Check ip is greater than compared ip
 *
 * @param {string} ip
 * @param {string} params.comparedIp
 * @param {boolean} params.equal - If True, operator is ">=" instead of ">"
 * @returns {boolean}
 */
export function validateIpGreaterThanCompareIp(ip, { comparedIp, equal = true }) {
  const ipInt = convertIpToInt(ip);
  const comparedIpInt = convertIpToInt(comparedIp);

  return equal ? ipInt >= comparedIpInt : ipInt > comparedIpInt;
}

/**
 * Check whether IP is in subnet by given ip and mask.
 *
 * @param {string} checkedIp - IP to check
 * @param {string} subnet.ip4Address
 * @param {string|number} subnet.ip4Prefix
 * @returns {boolean} True if IP is in subnet
 */
function validateIpInSubnet(checkedIp, { ip4Address, ip4Prefix }) {
  return isIpInSubnet(checkedIp, ip4Address, ip4Prefix);
}

/**
 * @typedef Ipv4SubnetItem
 * @type {Object}
 * @property {string} ip4Address - IPv4 address
 * @property {string|number} ip4Prefix - subnet mask
 * @property {string} [title] - title of error message.
 *                            - Required if neither message of validation
 *                              nor message of this item is provided.
 * @property {string} [message] - error message. If message is given, title will be ignore.
 */
/**
 * Check whether IP is in any given subnets
 *
 * @param {string} checkedIp - IP to check
 * @param {Ipv4SubnetItem[]} params.list - subnets list
 * @param {string} params.message - error message
 * @returns {boolean|string} True if IP is valid and IP is in any subnet and not its reserved IP,
 *                   else error message
 */
function validateIp4AddressInAnySubnets(checkedIp, { list, message }) {
  if (!checkIpValid(checkedIp)) {
    return '';
  }
  let ipReservedFlag = false;
  const subnetItem = list.find(({ ip4Address, ip4Prefix }) => {
    if (isIpInSubnet(checkedIp, ip4Address, ip4Prefix)) {
      if (!checkIp4CIDR(`${checkedIp}/${ip4Prefix}`)) {
        ipReservedFlag = true;

        return false;
      }

      return true;
    }

    return false;
  });

  if (!subnetItem) {
    return ipReservedFlag ? 'ID_ERROR_MESSAGE_IP_OR_SUBNET_IS_RESERVED' : message;
  }

  return true;
}

function validateLeaseTime(value, { leaseTime }) {
  return parseInt(leaseTime, 10) !== 0;
}

/**
 * Check whether value is not in given list
 * @param {any} fieldValue - value of field to check
 * @param {Object} params
 * @param {any[]} params.list - list to check
 * @param {any} params.value - if specified value is given in params, value of field will be ignored
 * @returns {boolean} True if value is not in given list
 */
function validateExcluded(fieldValue, { list, value }) {
  if (value !== undefined) {
    fieldValue = value;
  }

  return !list.find((item) => isEqual(item, fieldValue));
}

/**
 * validate IPv6 format.
 * @param {string} value
 * @returns {boolean} True if valid IPv6 format
 */
function validateIpv6Format(value) {
  return IPv6.isValid(value);
}

/**
 * validate AFTR address is FQDN or IPv6 format.
 * @param {string} value
 * @returns {boolean} True if valid AFTR address
 */
function validateAftrAddress(value) {
  return validateFQDN(value) || IPv6.isValid(value);
}

/**
 * Check whether the IPv6 address and gateway are in the same subnet
 * @param {string} ipv6Address - IPv6 address of the WAN interface
 * @param {string} ip6Prefix - IPv6 prefix of the WAN interface
 * @param {string} ip6Gateway - IPv6 gateway of the WAN interface
 * @returns {boolean} True if the IPv6 address and the gateway are in the same subnet
 */
export function validateIpv6InSubnet(ipv6Address, ip6Prefix, ip6Gateway) {
  if (!IPv6.isValid(ipv6Address) || !IPv6.isValid(ip6Gateway) || !isInteger(Number(ip6Prefix))) {
    return false;
  }

  const isSameSubnet = ip6AddressInSubnet(ip6Gateway, ipv6Address, ip6Prefix);

  if (!isSameSubnet) {
    const parsedGateway = IPv6.parse(ip6Gateway);
    const [linkLocalIp, linkLocalPrefix] = IPV6_IP_RESERVED_RANGE_LIST.linkLocal;

    return parsedGateway.match(linkLocalIp, linkLocalPrefix);
  }

  return true;
}

/**
 * Check IPv6 suffix is greater than compared IPv6 suffix
 *
 * @param {string} suffix
 * @param {string} params.comparedSuffix
 * @param {boolean} params.equal - If True, operator is ">=" instead of ">"
 * @param {string} param.realSuffix - if realSuffix is given, suffix will replace by realSuffix
 * @returns {boolean} True if suffix greater than compared suffix
 */
export function validateIpv6SuffixGreaterThanCompareSuffix(suffix, {
  comparedSuffix, equal = true, realSuffix,
}) {
  if (realSuffix !== undefined) {
    suffix = realSuffix;
  }

  if (!IPv6.isValid(suffix) || !IPv6.isValid(comparedSuffix)) {
    return false;
  }
  const compare = compareIpv6Address(suffix, comparedSuffix);

  return equal ? compare >= 0 : compare > 0;
}

/**
 * Check subnet not overlap any subnets
 * @param {string} ip - IPv4 address
 * @param {Object} param
 * @param {string|number} param.ip4Prefix - subnet mask
 * @param {Ipv4SubnetItem[]} param.subnets - subnets
 * @returns {boolean} True if subnet(ip+mask) not overlap any subnets
 */
function validateSubnetNotOverlapSubnets(ip, { ip4Prefix, subnets }) {
  if (!checkIpValid(ip)) {
    return false;
  }
  const conflictSubnets = subnets
    .find((subnet) => !subnetNotOverlapSubnet(ip, ip4Prefix, subnet.ip4Address, subnet.ip4Prefix));

  if (!conflictSubnets) {
    return true;
  }

  return conflictSubnets.message ? conflictSubnets.message
    : convertLinebreak(i18n.t('ID_NERWORK_IP_OR_SUBNET_CONFLICT', {
      title: i18n.t(conflictSubnets.title),
      ip: `${conflictSubnets.ip4Address}/${conflictSubnets.ip4Prefix}`,
    }));
}

/**
 * validate blob length of Wifi SSID
 * @param {string} ssid - Wifi SSID
 * @returns {boolean|string} True if SSID is valid, else error message
 */
function validateWifiSsid(ssid) {
  if ((new Blob([ssid]).size) < WIRELESS_LIMIT.SSID_LOWBOUND) {
    return i18n.t('ID_WIFI_SSID_ERR_SHORT');
  }

  if ((new Blob([ssid]).size) > WIRELESS_LIMIT.SSID_UPBOUND) {
    return i18n.t('ID_WIFI_SSID_ERR_LONG');
  }

  return true;
}

/**
 * Validate device name length and only contain available characters
 * @param {string} inputName
 * @param {Object} param
 * @param {string} param.value - final device name contain qts prefix
 * @returns {boolean} True if device name is valid
 */
function validateDeviceName(inputName, { value: finalName }) {
  if (finalName.length < 3 || finalName.length > 15) {
    return false;
  }

  return /^[0-9a-zA-Z]+$/.test(finalName);
}

function validateValuesEqual(value, { value: comparedValue }) {
  return value === comparedValue;
}

/**
 * Validate allowed remote ips format
 * - empty, or an IP range, or an IP subnet, or up to 15 IP addresses
 *
 * @param {string} value - allowed remote ips
 * @returns {boolean} True if valid format
 */
function validateAllowedRemoteIps(value) {
  if (value === '') {
    return true;
  }

  if (value.indexOf('-') !== -1) {
    return isIpRange(value);
  }

  if (value.indexOf('/') !== -1) {
    return isIpNetwork(value);
  }

  if (value.indexOf(',') !== -1) {
    const ips = value.split(',');

    return ips.length <= 15 && ips.every((ip) => checkIpValid(ip));
  }

  return checkIpValid(value);
}

/**
 * Check (IP+mask) match Network IP
 * ex: 172.16.32.4/16 -> Network IP: 172.16.0.0
 * @param {string|number} mask
 * @param {Object} params
 * @param {string} params.ip - IP address
 * @returns {boolean} True if IP and Network IP equal
 */
function validateMaskMatchNetworkAddress(mask, { ip }) {
  if (!checkIpValid(ip) || !isInteger(mask)) {
    return true;
  }
  const networkIp = IPv4.networkAddressFromCIDR(`${ip}/${mask}`).toString();

  return networkIp === ip ? true : `${i18n.t('ID_SR_SUBNET_MASK_ERR_MSG')}${networkIp ? ` (${networkIp})` : ''}`;
}

/**
 * validate minimum number of items
 * @param {any} value - value of field
 * @param {Object} param
 * @param {any[]} param.list - list of items
 * @param {number} param.min - the minimum number of items
 * @returns {boolean} True if number of items is valid
 */
function validateMinListLength(value, { list, min: minLength }) {
  return list.length >= minLength;
}

/**
 * @typedef Ipv6SubnetItem
 * @type {Object}
 * @property {string} ip6Address - IPv6 prefix or complete IPv6 address
 * @property {string|number} ip6Prefix - prefix length
 * @property {string} [title] - title of error message. Required if message is not given.
 * @property {string} [message] - error message. If message is given, title will be ignore.
 */
/**
 * Check IPv6 address do not overlap any range of prefixes
 * @param {string} fieldValue - IPv6 address from field to check
 * @param {Object} params
 * @param {Ipv6SubnetItem[]} params.ip6Subnets - IPv6 subnets
 * @param {string} params.value - if specified IPv6 address is given in params,
 *                                IPv6 address of field will be ignored
 * @param {string|number} ip6Prefix - prefix length of given prefix
 * @returns {boolean|string} - Error message if IPv6 prefix do not overlap any range of prefixes.
 *                           - False if invalid IPv6 format of prefix
 */
function validateIp6SubnetNotOverlapSubnets(fieldValue, { ip6Subnets, ip6Prefix, value }) {
  if (value !== undefined) {
    fieldValue = value;
  }

  if (!IPv6.isValid(fieldValue)) {
    return false;
  }
  const conflictedItem = ip6Subnets
    .find((item) => ip6SubnetOverlapSubnet(fieldValue, ip6Prefix, item.ip6Address, item.ip6Prefix));

  if (!conflictedItem) {
    return true;
  }

  return conflictedItem.message ? conflictedItem.message : i18n.t('ID_NETWORK_IPV6_IP_CONFLICT', {
    title: i18n.t(conflictedItem.title),
    ip: `${conflictedItem.ip6Address}/${conflictedItem.ip6Prefix}`,
  });
}

/**
 * Detail: https://vee-validate.logaretm.com/v3/guide/required-fields.html#creating-a-required-rule
 * @typedef VeeValidateComputedRequiredResult
 * @type {Object}
 * @property {boolean} valid
 * @property {boolean} required
 */

/**
 * Check IPv6 address not in reserved subnet
 * @param {string} fieldValue - value of field to check
 * @param {Object} params
 * @param {string} params.value - if specified value is given in params,
 *                                value of field will be ignored
 * @returns {VeeValidateComputedRequiredResult} valid is true if IPv6 address not in reserved subnet
 */
function validateIpv6AddressNotInReservedSubnet(fieldValue, { value }) {
  const valid = (() => {
    if (value !== undefined) {
      fieldValue = value;
    }

    if (!IPv6.isValid(fieldValue)) {
      return true;
    }

    return subnetMatch(IPv6.parse(fieldValue), IPV6_IP_RESERVED_RANGE_LIST, 'unicast') === 'unicast';
  })();

  return {
    valid,
    required: false,
  };
}

/**
 * Check whether the IPv6 gateway excludes the reserved subnet
 * @param {string} gatewayIp - IPv6 gateway of port
 * @returns {boolean} True if the IPv6 gateway is not in the reserved subnet
 */
export function validateIpv6GatewayExcludeReservedSubnet(gatewayIp) {
  if (!IPv6.isValid(gatewayIp)) {
    return true;
  }

  return subnetMatch(IPv6.parse(gatewayIp), IPV6_GATEWAY_RESERVED_RANGE_LIST, 'unicast') === 'unicast';
}

/**
 * Check IPv6 prefix not in reserved subnet
 * @param {string} fieldValue - IPv6 address
 * @param {Object} params
 * @param {string} params.ip6Prefix - IPv6 prefix length
 * @param {string} params.value - if specified value is given in params,
 *                                value of field will be ignored
 * @returns {boolean} true if IPv6 prefix not in reserved subnet
 */
function validateIpv6PrefixNotOverlapReservedSubnet(fieldValue, { ip6Prefix, value }) {
  if (value !== undefined) {
    fieldValue = value;
  }

  if (!IPv6.isValid(fieldValue)) {
    return true;
  }
  const ipItem = IPv6.networkAddressFromCIDR(`${fieldValue}/${ip6Prefix}`);

  return Object.values(IPV6_IP_RESERVED_RANGE_LIST)
    .every(([reservedIpItem, length]) => !ipItem.match(reservedIpItem, length)
      && !reservedIpItem.match(ipItem, ip6Prefix));
}

/**
 * Validate whether the PPPoE WAN is allowance
 * @param {string} ip4Type - Type of the IPv4 WAN connection
 * @param {string} ip6Type - Type of the IPv6 WAN connection
 * @returns {boolean} True if the PPPoE WAN is allowance
 */
export function validatePppoeAllowance(ip4Type, ip6Type) {
  if (ip4Type === WAN_IP4_TYPE.PPPOE) {
    return ip6Type === WAN_IP6_TYPE.DISABLED || ip6Type === WAN_IP6_TYPE.DHCP;
  }

  return true;
}

/**
 * Validate whether the PPPoE WAN is not used by the IPTV service
 * @param {string} wanConnectionType - Type of the WAN connection
 * @param {boolean} isIptvInUsed - True if the WAN port is used in IPTV service
 * @returns {boolean} True if the PPPoE WAN is not used by IPTV service
 */
export function validatePppoeNotInIptv(wanConnectionType, isIptvInUsed) {
  return wanConnectionType !== WAN_IP4_TYPE.PPPOE || !isIptvInUsed;
}

/**
 * Check whether the ESP protocol of the service port is available.
 * @param {string} protocol - The protocol of the service port
 * @param {Object} params
 * @param {boolean} params.espUsed - Ture if ESP protocol has been used
 * @param {boolean} params.isQuwan - True if the device is on QuWAN
 * @param {boolean} params.isL2tp - True if QVPN L2TP server is enabled
 * @returns {boolean} True if the ESP protocol of the service port is available
 */
export function validateServicePortEspProtocol(protocol, { espUsed, isQuwan, isL2tp } = {}) {
  if (protocol === SERVICE_PORT_PROTOCOL.ESP) {
    if (espUsed) {
      return i18n.t('ID_SERVICE_PORT_DUPLICATE_SERVICE_PORT_MSG', { protocol: 'ESP', number: '' });
    }

    if (isQuwan) {
      return 'ID_PF_QUWAN_ENABLE_LIMITATION';
    }

    if (isL2tp) {
      return 'ID_PF_L2TP_ENABLE_LIMITATION';
    }
  }

  return true;
}

/**
 * Check whether service port is reserved by other system service
 * @param {Object} params
 * @param {string|number} params.port - The port number of the service port
 * @param {string} params.protocol - The protocol of the service port
 * @param {string} params.isL2tp - If true, reserve the ports for L2TP
 * @param {Object[]} params.customReservedPorts - additional reserved ports to check
 * @returns {boolean|string} True if service port is available, else error message
 */
export function validateReservedServicePort({
  port,
  protocol,
  isL2tp,
  customReservedPorts = [],
} = {}) {
  if (typeof port === 'number') {
    port = port.toString();
  }

  if (typeof port !== 'string' || !port || !protocol) {
    return true;
  }

  const baseReservedPortList = [
    { protocol: SERVICE_PORT_PROTOCOL.TCP, port: '5000' },
    { protocol: SERVICE_PORT_PROTOCOL.UDP, port: '61001-62000' },
  ];

  if (isL2tp) {
    baseReservedPortList.push({ protocol: SERVICE_PORT_PROTOCOL.UDP, port: '500,4500' });
  }

  let reservedPortList = [
    ...baseReservedPortList,
    ...customReservedPorts,
  ];

  if (protocol !== SERVICE_PORT_PROTOCOL.ALL) {
    reservedPortList = reservedPortList
      .filter((item) => item.protocol === SERVICE_PORT_PROTOCOL.ALL || item.protocol === protocol);
  }

  const conflictResult = checkPortOfServicePortConflict(reservedPortList, port);

  if (conflictResult) {
    if (baseReservedPortList.includes(conflictResult.conflictServicePort)) {
      return 'ID_ERROR_MESSAGE_DUPLICATE_SERVICE_PORT';
    }

    return i18n.t(
      'ID_ERROR_MESSAGE_DUPLICATE_SERVICE_PORT_EDITABLE',
      { service: i18n.t('ID_MENU_SERVICE_PORT_MANAGEMENT') },
    );
  }

  return true;
}

/**
 * Check whether wan service port is available
 * @param {string} port - value of port field to check
 * @param {Object} params
 * @param {Object[]} params.list - port list which is already used
 * @param {string} params.protocol - current rule protocol
 * @returns {boolean|string} True if wan service port is available, else error message
 */
function validateWanServicePort(port, { list, protocol }) {
  if (port.includes(' ')) {
    return 'ID_SERVICE_PORT_INVALID_VALUES_MSG';
  }

  const conflictResult = checkPortOfServicePortConflict(list, port);

  if (conflictResult) {
    return i18n.t(
      'ID_SERVICE_PORT_DUPLICATE_SERVICE_PORT_MSG',
      { protocol: getProtocolDisplayed(protocol), number: conflictResult.conflictPort },
    );
  }

  return true;
}

/**
 * Check IPv6 is enabled if IPv4 WAN type is DS-Lite or MAP-E
 * @param {string} ip4Type - IPv4 WAN type
 * @param {Object} params
 * @param {boolean} params.ip6Enabled - enabled of IPv6
 * @returns {boolean} - True if IPv4 WAN type is valid
 */
function validateAdvanceWanType(ip4Type, { ip6Enabled }) {
  return (ip4Type !== 'DSLITE' && ip4Type !== 'MAPE') || ip6Enabled;
}

/**
 * Check two values are not equal
 * @param {any} value - value of field
 * @param {Object} params
 * @param {any} params.comparedValue - value to compare
 * @param {any} params.value - if specified value is given in params,
 *                                value of field will be ignored
 * @returns {VeeValidateComputedRequiredResult} valid is true if two values are no equal
 */
function validateValuesNotEqual(fieldValue, { comparedValue, value }) {
  if (value !== undefined) {
    fieldValue = value;
  }

  return {
    valid: fieldValue !== comparedValue,
    required: false,
  };
}

/**
 * Validate whether the required DHCP IPv6 type is valid
 * @param {string} ip6Type - The connection type of IPv6 WAN
 * @returns {boolean} True if the WAN type is DHCP IPv6
 */
export function validateDhcpv6Required(ip6Type) {
  return ip6Type === WAN_IP6_TYPE.DHCP;
}

/**
 * Check IPv6 address and prefix length match network address
 * @param {string|number} fieldValue - IPv6 prefix length
 * @param {Object} params
 * @param {string} params.ip6Address - IPv6 address
 * @returns {boolean|string} True if prefix length is valid, else error message
 */
function validateIp6PrefixMatchNetworkAddress(fieldValue, { ip6Address }) {
  if (!IPv6.isValid(ip6Address)) {
    return true;
  }
  ip6Address = normalizeIpv6Ip(ip6Address);
  const networkIp = IPv6.networkAddressFromCIDR(`${ip6Address}/${fieldValue}`).toString();

  return ip6Address === networkIp ? true : `${i18n.t('ID_SR_SUBNET_MASK_ERR_MSG')}${networkIp ? ` (${networkIp})` : ''}`;
}

/**
 * Check IPv4 IP address with prefix is not reserved IP
 * @param {string} fieldValue - IPv4 address
 * @param {number} ip4Prefix - IPv4 subnet mask
 * @returns {boolean} True if IP address is not reserved IP
 */
export function validateIp4AddressWithPrefix(fieldValue, ip4Prefix) {
  return !checkIpValid(fieldValue) || checkIp4CIDR(`${fieldValue}/${ip4Prefix}`);
}

/**
 * Check IPv6 IP address with prefix is not reserved IP
 * @param {string|number} fieldValue - IPv6 address
 * @param {Object} params
 * @param {number} params.ip6Prefix - IPv6 prefix length
 * @param {any} params.value - if specified value is given in params, value of field will be ignored
 * @returns {boolean|string} True if IP address is valid, else error message
 */
function validateIp6AddressWithPrefix(fieldValue, { ip6Prefix, value }) {
  if (value !== undefined) {
    fieldValue = value;
  }

  if (!IPv6.isValid(fieldValue)) {
    return '';
  }

  if (ip6Prefix === 128) {
    return true;
  }
  const ipParts = IPv6.parse(fieldValue).parts;
  const ipBinary = ipParts.map((part) => part.toString(2).padStart(16, '0')).join('');
  const pre = ipBinary.slice(0, ip6Prefix);
  const startBinary = pre.padEnd(128, '0');

  if (ipBinary === startBinary) {
    return 'ID_NETWORK_IPV6_ADDRESS_IN_RESERVED_SUBNET_ERR';
  }

  return true;
}

/**
 * Check the bytes length of field value is in range
 * @param {string} fieldValue - field value
 * @param {Object} params
 * @param {number} [params.max] - max length
 * @param {number} [params.min] - min length
 * @param {string} [params.message] - error message
 * @returns {boolean|string} True if the bytes length of field value is in range, else error message
 */
function validateBytesLength(fieldValue, { max: maxLength, min: minLength, message }) {
  const length = new Blob([fieldValue]).size;

  if (typeof maxLength === 'number' && length > maxLength) {
    if (typeof minLength !== 'number' && typeof message !== 'string') {
      // default message
      return i18n.t('ID_FIELD_MAX_BYTES_LENGTH_ERR', { max: maxLength });
    }

    return message;
  }

  if (typeof minLength === 'number' && length < minLength) {
    return message;
  }

  return true;
}

/**
 * Check if the value is valid domain name.
 * @param {string} domainName - The domain name
 * @returns {boolean} True if the value is valid domain name
 */
export function validateDomainName(domainName) {
  if (!domainName || typeof domainName !== 'string') {
    return false;
  }

  const encodedDomainName = toASCIIPunycode(domainName);

  return /^[a-zA-Z0-9.-]+$/.test(encodedDomainName);
}

/**
 * Check diagnostic destination is valid IPv4 address or valid domain name.
 * @param {string} fieldValue - value of field to check
 * @returns {boolean} True if destination is valid
 */
function validateDiagnosticDestination(fieldValue) {
  if (checkIpValid(fieldValue)) {
    return true;
  }

  return validateDomainName(fieldValue);
}

/**
 * Check LAN interface MTU is valid for VAP
 * @param {string} fieldValue - value of field
 * @param {Object} params
 * @param {number} params.lanMtu - LAN interface MTU
 * @returns {boolean} True if LAN interface MTU is valid
 */
function validateVapLanMtu(fieldValue, { lanMtu }) {
  return lanMtu <= MTU_LIMIT.LAN_WIRELESS_MAX;
}

/**
 * Check IP address not conflict with any given IPs.
 * @param {string} fieldValue - value of field
 * @param {Object} params
 * @param {{ ip: string, title: string, inSameForm?: boolean }[]} params.ips - IPs to check
 * @param {any} params.value - if specified value is given in params, value of field will be ignored
 * @returns {boolean|string} True if no any IP conflict, else error message
 */
function validateIpNotConflict(fieldValue, { ips, value }) {
  if (value !== undefined) {
    fieldValue = value;
  }
  const conflictedIps = ips
    .filter((item) => item.ip === fieldValue)
    .map((item) => ({ inSameForm: false, ...item }));

  if (conflictedIps.length === 0) {
    return true;
  }
  const firstItem = conflictedIps[0];

  if (firstItem.inSameForm) {
    return i18n.t('ID_ERROR_MESSAGE_IP_CONFLICT_WITH_FIELD', {
      title: conflictedIps
        .filter((item) => item.inSameForm)
        .map((item) => i18n.t(item.title))
        .join(', '),
    });
  }

  return i18n.t('ID_NERWORK_IP_OR_SUBNET_CONFLICT', {
    title: i18n.t(firstItem.title),
    ip: firstItem.ip,
  });
}

/**
 * Check value of enabled of IPv6 VLAN
 * @param {boolean} fieldValue - value of field
 * @param {Object} params
 * @param {boolean} params.configInvalid - whether config is invalid
 * @returns {boolean} True if enabled of IPv6 VLAN is false or config is not invalid
 */
function validateVlanIp6Enabled(fieldValue, { configInvalid }) {
  return !fieldValue || !configInvalid;
}

/**
 * Check value is valid IPv4 Firewall IP address
 * @param {string} fieldValue - value of field
 * @returns {boolean} True if value is valid
 */
function validateFirewallRuleIp(fieldValue) {
  return checkIpValid(fieldValue) || checkIp4CIDR(fieldValue) || isIpRange(fieldValue);
}

/**
 * Check current number of LAN and WAN is valid
 * @param {string} fieldValue - value of field
 * @param {Object} params
 * @param {string} params.originalValue - original value of field before editing
 * @param {boolean} params.isWan - true if current port type is WAN
 * @param {Object[]} params.wans - WAN list
 * @param {Object[]} params.lans - LAN list
 * @param {string} params.portName - current port ID
 * @returns {boolean|string} true if number of LAN and WAN is valid, else error message
 */
function validateNumberOfPorts(fieldValue, {
  originalValue, isWan, wans, lans, portName,
}) {
  if (fieldValue === originalValue) {
    return true;
  }

  if (!isWan && wans.length === 1 && wans[0].portName === portName) {
    return 'ID_PORT_AT_LEAST_ONE_WAN_MSG';
  }

  if (isWan && lans.length === 1 && lans[0].portName === portName) {
    return 'ID_PORT_AT_LEAST_ONE_LAN_MSG';
  }

  return true;
}

/**
 * Check enabled value of WAN interface IPv6 config
 * @param {boolean} fieldValue - value of field
 * @param {Object} params
 * @param {string} params.interfaceId - current interfaceId
 * @returns {boolean|string} true if value is valid, else error message
 */
function validateWanIp6Enabled(fieldValue, { interfaceId }) {
  if (!fieldValue) {
    return store.getters['Network/checkWanUsedAsLanIp6Uplink'](interfaceId);
  }

  return true;
}

/**
 * Check enabled value of DHCP-PD of WAN interface IPv6 config
 * @param {boolean} fieldValue - value of field
 * @param {Object} params
 * @param {boolean} params.originalValue - original value of DHCP-PD
 * @param {string} params.interfaceId - current interface ID
 * @param {Object[]} params.lanConfigItems - LAN config items (LAN / VLAN interface / Bridge)
 *                                           contains config and title
 * @returns {boolean|string} true if value is valid, else error message
 */
function validateWanIp6PdEnabled(fieldValue, { originalValue, interfaceId, lanConfigItems }) {
  if (fieldValue === originalValue) {
    return true;
  }
  const useWanIp6UplinkLanTitles = lanConfigItems
    .filter((item) => item.config.ip6Uplink === interfaceId)
    .map((item) => item.title);

  if (useWanIp6UplinkLanTitles.length > 0) {
    const messageId = fieldValue ? 'ID_PORT_WAN_PD_ENABLE_LAN_IPV6_UPLINK_USED_MSG' : 'ID_PORT_WAN_PD_DISABLE_LAN_IPV6_UPLINK_USED_MSG';

    return i18n.t(messageId, {
      interfaces: useWanIp6UplinkLanTitles.join(', '),
    });
  }

  return true;
}

/**
 * Check type (WAN or LAN) of interface is valid
 * @param {string} fieldValue - value of field
 * @param {Object} params
 * @param {string} params.originalValue - original value of field before editing
 * @param {boolean} params.isWan - true if current interface type is WAN
 * @param {string} params.interfaceId - current interface ID
 * @returns {boolean|string} true if interface type is valid, else error message
 */
function validateInterfaceType(fieldValue, { originalValue, isWan, interfaceId }) {
  if (fieldValue === originalValue) {
    return true;
  }
  const usedMsg = store.getters['Network/checkInterfaceNotUsedByOthers']({
    type: isWan ? NETWORK_INTERFACE_TYPE.LAN : NETWORK_INTERFACE_TYPE.WAN,
    interfaceId,
  });

  if (usedMsg !== true) {
    return usedMsg;
  }

  if (isWan) {
    return store.getters['Network/checkLanInterfaceNotLast'](interfaceId, NETWORK_INTERFACE_ACTION.CHANGE);
  }

  return true;
}

/**
 * Check enabled value of LAN interface
 * @param {boolean} fieldValue - value of field
 * @param {Object} params
 * @param {string} params.interfaceId - current interface ID
 * @returns {boolean|string} true if value is valid, else error message
 */
function validateLanInterfaceEnabled(fieldValue, { interfaceId }) {
  if (!fieldValue) {
    return store.getters['Network/checkLanInterfaceNotLast'](interfaceId, NETWORK_INTERFACE_ACTION.DISABLE);
  }

  return true;
}

function validateIp6AddressInAnySubnets(value, { list }) {
  if (!IPv6.isValid(value)) {
    return false;
  }

  return list.some(({ ip6Address, ip6Prefix }) => ip6AddressInSubnet(value, ip6Address, ip6Prefix));
}

/**
 * Check IP is not between start IP and end IP.
 * @param {string} ip
 * @param {Object} params
 * @param {string} params.value - if given, value of field will be ignored
 * @param {string} params.startIp
 * @param {string} params.endIp
 * @returns {boolean}
 */
function validateIp4AddressNotInRange(fieldValue, { value, startIp, endIp }) {
  if (value !== undefined) {
    fieldValue = value;
  }

  return !ip4AddressInRange(fieldValue, startIp, endIp);
}

/**
 * Check IPv6 IP is not between start IP and end IP.
 * @param {string} ip
 * @param {Object} params
 * @param {string} params.value - if given, value of field will be ignored
 * @param {string} params.startIp
 * @param {string} params.endIp
 * @returns {boolean}
 */
function validateIp6AddressNotInRange(fieldValue, { value, startIp, endIp }) {
  if (value !== undefined) {
    fieldValue = value;
  }

  return !ip6AddressInRange(fieldValue, startIp, endIp);
}

/**
 * Check selected IPTV WAN interface is valid.
 * @param {string} wanIface - selected IPTV WAN interface
 * @param {Object} params
 * @param {Object[]} params.wanConfigItems - WAN config items (WAN / VLAN interface / Bridge)
 * @returns {boolean|string} True if IPTV WAN interface is valid, else error message
 */
function validateIptvBridgeWanIface(wanIface, { wanConfigItems }) {
  const configItem = wanConfigItems.find((item) => item.interfaceId === wanIface);

  if (configItem?.type === NETWORK_INTERFACE.VLAN_IFACE
    && configItem.rootConfig.tags.length + configItem.rootConfig.untags.length === 0) {
    return 'ID_IPTV_BRIDGE_WAN_SELECTED_VLAN_INVALID_MSG';
  }

  return true;
}

/**
 * Check network interface name is valid.
 * @param {string} value - The value of field
 * @returns {boolean|string} True if network interface name is valid, else error message
 */
function validateNetworkInterfaceName(value) {
  if (!(/^[A-Za-z0-9-_\s]+$/.test(value))) {
    return i18n.t('ID_ERROR_MESSAGE_INVALID_CHARACTER', { text: '-_' });
  }

  return true;
}

/**
 * Validate whether the node location is excluded
 * @param {string} location - Location of the node to check
 * @param {Object[]} miroDevices - List of miro devices
 * @param {string} selectedNodeId - Selected ID of the node
 * @returns {boolean} True if the node location is excluded
 */
export function validateNodeLocationExclude(location, miroDevices, selectedNodeId) {
  if (Array.isArray(miroDevices) && typeof selectedNodeId === 'string') {
    return miroDevices
      .filter((device) => Number(selectedNodeId) !== device.id)
      .every((device) => location !== device.location);
  }

  return true;
}

/**
 * Validate whether the MAP-E WAN is allowance
 * @param {string} ip4Type - Type of the IPv4 WAN connection
 * @param {string} ip6Type - Type of the IPv6 WAN connection
 * @returns {boolean} True if the MAP-E WAN is allowance
 */
export function validateMapeAllowance(ip4Type, ip6Type) {
  if (ip4Type === WAN_IP4_TYPE.MAPE) {
    return ip6Type !== WAN_IP6_TYPE.DISABLED;
  }

  return true;
}

/**
 * Check value is valid IPv6 Firewall IP address
 * @param {string} ip6Address - IPv6 address
 * @returns {boolean} True if IPv6 address is valid
 */
export function validateFirewallRuleIp6Address(ip6Address) {
  return validateIpv6Format(ip6Address) || checkIp6Subnet(ip6Address) || isIp6IpRange(ip6Address);
}

/**
 * Validate whether the service port excludes the IPSec service
 * @param {string} protocol - Protocol of the service port
 * @param {string} servicePortRange - Port range of the service port
 * @returns {boolean} True if the service port excludes the IPSec service
 */
export function validateExcludeIpsec(protocol, servicePortRange) {
  const isIpsecProtocol = [
    SERVICE_PORT_PROTOCOL.ALL,
    SERVICE_PORT_PROTOCOL.UDP,
  ].includes(protocol);

  if (isIpsecProtocol) {
    return IPSEC_PORTS.every((ipsecPort) => !validateOverlappingPort(ipsecPort, servicePortRange));
  }

  return true;
}

/* Configure vee-validate */
configure({
  mode: 'passive',
});

extend('required', {
  ...required,
  message: '{message}',
  params: [{ name: 'message', default: 'ID_QUWAN_FIELD_REQUIRED' }],
});

extend('min', {
  validate: min.validate,
  message: '{message}',
  params: [...min.params, { name: 'message', default: '' }], // min.params: ['length']
});

extend('max', {
  validate: max.validate,
  message: '{message}',
  params: [...max.params, { name: 'message', default: '' }], // max.params: ['length']
});

extend('between', {
  validate: between.validate,
  message: '{message}',
  params: [...between.params, 'message'],
});

extend('regex', {
  validate: regex.validate,
  message: '{message}',
  params: [...regex.params, 'message'],
});

/* Custom rules */
extend('description', {
  validate: validateDescription,
  message: () => i18n.t('ID_ERROR_MESSAGE_ALLOWED_SYMBOLS', { symbols: ',.;\'"!?()[] -_' }),
});

extend('dhcpServerRange', {
  validate: (endIp, { startIp }) => validateDhcpServerRange(startIp, endIp),
  message: () => i18n.t('ID_ERROR_MESSAGE_IP_RANGE_INVALID', { number: DHCP_SERVER_RANGE }),
  params: ['startIp'],
});

extend('dsliteAllowance', {
  validate: (ip4Type, { ip6Type }) => validateDsliteAllowance(ip4Type, ip6Type),
  message: 'ID_IPV6_ADVANCED_IPV6_DISABLED_MSG',
  params: ['ip6Type'],
});

extend('username', {
  validate: validateUsername,
  message: 'ID_AC_LOCALUSR_MSG',
});

extend('excluded', {
  validate: validateExcluded,
  message: '{message}',
  params: ['list', 'value', { name: 'message', default: '' }],
});

extend('ipAddress', {
  validate: checkIpValid,
  message: 'ID_ERROR_MESSAGE_INVALID_IP_ADDRESS',
});

extend('macAddress', {
  validate: checkMacAddressValid,
  message: 'ID_NETWORK_INVALID_MAC_ADDRESS_MSG',
});

extend('ipNotInReservedSubnet', {
  validate: validateIpNotInReservedSubnet,
  message: 'ID_ERROR_MESSAGE_IP_OR_SUBNET_IS_RESERVED',
});

extend('subnetNotOverlapReservedSubnets', {
  validate: validateSubnetNotOverlapReservedSubnets,
  message: 'ID_ERROR_MESSAGE_IP_OR_SUBNET_IS_RESERVED',
});

extend('ipGreaterThanCompareIp', {
  validate: validateIpGreaterThanCompareIp,
  message: '{message}',
  params: ['comparedIp', 'equal', 'message'],
});

extend('ipInSubnet', {
  validate: validateIpInSubnet,
  message: '{message}',
  params: ['ip4Address', 'ip4Prefix', 'message'],
});

extend('ip4AddressInAnySubnets', {
  validate: validateIp4AddressInAnySubnets,
  params: ['list', 'message'],
});

extend('leaseTime', {
  validate: validateLeaseTime,
  message: () => null,
  params: ['leaseTime'],
});

extend('ipv6Address', {
  validate: validateIpv6Format,
  message: 'ID_INVALID_IPV6_FORMAT',
});

extend('aftrAddress', {
  validate: validateAftrAddress,
  message: 'ID_IPV6_VLAN_PREVIEW_INVALID',
});

extend('ipv6SubnetId', {
  validate: validateIpv6SubnetId,
  params: ['pdLength', 'otherSubnetIds', 'realSubnetId'],
});

extend('ipv6Suffix', {
  validate: validateIpv6Suffix,
  message: 'ID_INVALID_IPV6_FORMAT',
  params: ['ip6Prefix', 'realSuffix'],
});

extend('ipv6ManuallyPrefix', {
  validate: validatePrefixLength,
  message: 'ID_INVALID_IPV6_FORMAT',
  params: ['ip6Prefix', 'realPrefix'],
});

extend('ipv6SuffixGreaterThanCompareSuffix', {
  validate: validateIpv6SuffixGreaterThanCompareSuffix,
  message: 'ID_IPV6_VLAN_END_SUFFIX_ERR',
  params: ['comparedSuffix', 'equal', 'realSuffix'],
});

extend('subnetNotOverlapSubnets', {
  validate: validateSubnetNotOverlapSubnets,
  params: ['ip4Prefix', 'subnets'],
});

extend('ascii', {
  validate: isASCII,
  message: 'ID_PASSWORD_ASCII_ONLY_ERR',
});

extend('wifiSsid', {
  validate: validateWifiSsid,
});

extend('deviceName', {
  validate: validateDeviceName,
  message: '{message}',
  params: ['value', 'message'],
});

extend('dhcpv6Required', {
  validate: (ip6Type) => validateDhcpv6Required(ip6Type),
  message: 'ID_WAN_PPPOE_IVP6_TYPE_MUST_CHANGE_MSG',
});

extend('equals', {
  validate: validateValuesEqual,
  message: '{message}',
  params: ['value', { name: 'message', default: '' }],
});

extend('allowedRemoteIps', {
  validate: validateAllowedRemoteIps,
  message: '{message}',
  params: ['message'],
});

extend('allowedMixPort', {
  validate: validatePorts,
  message: 'ID_SERVICE_PORT_PORT_TIP_MESSAGE_1',
});

extend('maskMatchNetworkAddress', {
  validate: validateMaskMatchNetworkAddress,
  params: ['ip'],
});

extend('ipv6AddressInGatewaySubnet', {
  validate: (
    ip6Address,
    { ip6Prefix, ip6Gateway },
  ) => validateIpv6InSubnet(ip6Address, ip6Prefix, ip6Gateway),
  message: 'ID_IPV6_WAN_STATIC_IP_AND_GATEWAY_NOT_IN_SAME_SUBNET_ERR',
  params: ['ip6Gateway', 'ip6Prefix'],
});

extend('ipv6GatewayInIpSubnet', {
  validate: (
    ip6Gateway,
    { ip6Address, ip6Prefix },
  ) => validateIpv6InSubnet(ip6Address, ip6Prefix, ip6Gateway),
  message: 'ID_IPV6_WAN_STATIC_IP_AND_GATEWAY_NOT_IN_SAME_SUBNET_ERR',
  params: ['ip6Address', 'ip6Prefix'],
});

extend('minListLength', {
  validate: validateMinListLength,
  message: '{message}',
  params: ['list', { name: 'min', default: 1 }, 'message'],
});

extend('ip6SubnetNotOverlapSubnets', {
  validate: validateIp6SubnetNotOverlapSubnets,
  params: ['ip6Subnets', 'ip6Prefix', 'value'],
});

extend('ipv6AddressNotInReservedSubnet', {
  validate: validateIpv6AddressNotInReservedSubnet,
  message: 'ID_NETWORK_IPV6_ADDRESS_IN_RESERVED_SUBNET_ERR',
  params: ['value'],
  computesRequired: true,
});

extend('ipv6GatewayExcludeReservedSubnet', {
  validate: validateIpv6GatewayExcludeReservedSubnet,
  message: 'ID_NETWORK_IPV6_ADDRESS_IN_RESERVED_SUBNET_ERR',
});

extend('wanServiceProtocol', {
  validate: validateServicePortEspProtocol,
  params: ['espUsed', 'isQuwan', 'isL2tp'],
});

extend('reservedServicePort', {
  validate: (value, params) => validateReservedServicePort({
    port: value,
    ...params,
  }),
  params: ['protocol', 'isL2tp', 'customReservedPorts'],
});

extend('wanServicePort', {
  validate: validateWanServicePort,
  params: ['list', 'protocol'],
});

extend('advanceWanType', {
  validate: validateAdvanceWanType,
  message: '{message}',
  params: ['ip6Enabled', 'message'],
});

extend('notEquals', {
  validate: validateValuesNotEqual,
  message: '{message}',
  params: ['comparedValue', 'value', 'message'],
  computesRequired: true,
});

extend('ip6PrefixMatchNetworkAddress', {
  validate: validateIp6PrefixMatchNetworkAddress,
  params: ['ip6Address'],
});

extend('ip4AddressWithPrefix', {
  validate: (ip4Address, { ip4Prefix }) => validateIp4AddressWithPrefix(ip4Address, ip4Prefix),
  message: 'ID_IP_ADDRESS_RESERVED_FOR_NETWORK_ID_OR_BROADCAST_IP_MSG',
  params: ['ip4Prefix'],
});

extend('ip6AddressWithPrefix', {
  validate: validateIp6AddressWithPrefix,
  params: ['ip6Prefix', 'value'],
});

extend('wireguardPrivateKey', {
  validate: validateWireguardPrivateKey,
  message: 'ID_VPN_WIREGUARD_PRIVATE_KEY_NOT_VALID_ERR',
});

extend('ipv6PrefixNotOverlapReservedSubnet', {
  validate: validateIpv6PrefixNotOverlapReservedSubnet,
  message: 'ID_NETWORK_IPV6_ADDRESS_IN_RESERVED_SUBNET_ERR',
  params: ['ip6Prefix', 'value'],
});

extend('pppoeAllowance', {
  validate: (ip4Type, { ip6Type }) => validatePppoeAllowance(ip4Type, ip6Type),
  message: 'ID_WAN_PPPOE_IVP6_TYPE_MUST_CHANGE_MSG',
  params: ['ip6Type'],
});

extend('pppoeNotInIptv', {
  validate: (
    wanConnectionType,
    { isIptvInUsed },
  ) => validatePppoeNotInIptv(wanConnectionType, isIptvInUsed),
  message: 'ID_IPTV_WAN_CHANGE_TYPE_TO_PPPOE_MSG',
  params: ['isIptvInUsed'],
});

extend('bytesLength', {
  validate: validateBytesLength,
  params: ['max', 'min', 'message'],
});

extend('diagnosticDestination', {
  validate: validateDiagnosticDestination,
  message: 'ID_DIAG_INVALID_DESTINATION_ERR',
});

extend('vapLanMtu', {
  validate: validateVapLanMtu,
  message: '{message}',
  params: ['lanMtu', 'message'],
});

extend('ipNotConflict', {
  validate: validateIpNotConflict,
  params: ['ips', 'value'],
});

extend('vlanIp6Enabled', {
  validate: validateVlanIp6Enabled,
  message: '{message}',
  params: ['configInvalid', 'message'],
});

extend('integer', {
  validate: isInteger,
  message: 'ID_SERVICE_PORT_INVALID_VALUES_MSG',
});

extend('firewallRuleIp', {
  validate: validateFirewallRuleIp,
  message: 'ID_ERROR_MESSAGE_INVALID_IP_ADDRESS',
});

extend('firewallRuleIp6Address', {
  validate: validateFirewallRuleIp6Address,
  message: 'ID_ERROR_MESSAGE_INVALID_IP_ADDRESS',
});

extend('numberOfPorts', {
  validate: validateNumberOfPorts,
  params: ['originalValue', 'wans', 'lans', 'portName', 'isWan'],
});

extend('wanInterfaceIp6Enabled', {
  validate: validateWanIp6Enabled,
  params: ['interfaceId'],
});

extend('wanInterfaceIp6PdEnabled', {
  validate: validateWanIp6PdEnabled,
  params: ['originalValue', 'lanConfigItems', 'interfaceId'],
});

extend('interfaceType', {
  validate: validateInterfaceType,
  params: ['originalValue', 'interfaceId', 'isWan'],
});

extend('lanInterfaceEnabled', {
  validate: validateLanInterfaceEnabled,
  params: ['interfaceId'],
});

extend('ip6AddressInAnySubnets', {
  validate: validateIp6AddressInAnySubnets,
  message: '{message}',
  params: ['list', 'message'],
});

extend('ip4AddressNotInRange', {
  validate: validateIp4AddressNotInRange,
  message: '{message}',
  params: ['value', 'startIp', 'endIp', 'message'],
});

extend('ip6AddressNotInRange', {
  validate: validateIp6AddressNotInRange,
  message: '{message}',
  params: ['value', 'startIp', 'endIp', 'message'],
});

extend('iptvBridgeWanIface', {
  validate: validateIptvBridgeWanIface,
  params: ['wanConfigItems'],
});

extend('networkInterfaceName', {
  validate: validateNetworkInterfaceName,
});

extend('nodeLocationRequired', {
  ...required,
  message: 'ID_MIROS_LOCATION_PLACEHOLD',
});

extend('nodeLocationExclude', {
  validate: (location, { miroDevices, selectedNodeId }) => validateNodeLocationExclude(
    location,
    miroDevices,
    selectedNodeId,
  ),
  message: 'ID_MIROS_LOCATION_ERR_MSG_DUP',
  params: ['miroDevices', 'selectedNodeId'],
});

extend('domainName', {
  validate: validateDomainName,
  message: 'ID_INVALID_DOMAIN_NAME_MSG',
});

extend('mapeAllowance', {
  validate: (ip4Type, { ip6Type }) => validateMapeAllowance(ip4Type, ip6Type),
  message: 'ID_IPV6_ADVANCED_IPV6_DISABLED_MSG',
  params: ['ip6Type'],
});

extend('excludeIpsec', {
  validate: (servicePortRange, { protocol }) => validateExcludeIpsec(
    protocol,
    servicePortRange,
  ),
  message: 'ID_ERROR_QUWAN_RESERVED_UDP_PORT',
  params: ['protocol'],
});
