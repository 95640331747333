/* eslint-disable import/prefer-default-export */

import IPTV_LABEL from '@/enums/IptvLabel';

/**
 * Check if Bridge is used for IPTV.
 * @param {Object} bridge - Bridge config
 * @returns {boolean} True if Bridge is used for IPTV
 */
export function isIptvBridge(bridge) {
  const { labels = [] } = bridge;

  return labels.includes(IPTV_LABEL.IPTV);
}
