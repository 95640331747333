import Vue from 'vue';
import VueRouter from 'vue-router';
import routerGuard from './routerGuard';

Vue.use(VueRouter);

const cfg = {
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import(/* webpackChunkName:"HomeFrame" */ '@/views/HomeFrame.vue'),
      redirect: {
        name: 'Dashboard',
      },
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: () => import(/* webpackChunkName:"DashboardView" */ '@/views/Dashboard/DashboardView.vue'),
          meta: {
            pageTitle: 'ID_MENU_DASHBOARD',
            subTitle: 'ID_DASHBOARD_DESC',
          },
        },
        {
          path: '/ap-wireless',
          name: 'ApWireless',
          component: () => import(/* webpackChunkName:"ApWireless" */ '@/views/ApMode/ApWireless/ApWirelessView.vue'),
          meta: {
            apMode: true,
          },
        },
        {
          path: '/ap-network',
          name: 'ApNetwork',
          component: () => import(/* webpackChunkName:"ApNetworkView" */ '@/views/ApMode/ApNetwork/ApNetworkView.vue'),
          meta: {
            apMode: true,
          },
        },
        {
          path: '/client',
          name: 'Client',
          component: () => import(/* webpackChunkName:"ClientView" */ '@/views/Client/ClientView.vue'),
        },
        {
          path: '/miro',
          name: 'Miro',
          component: () => import(/* webpackChunkName:"MiroView" */ '@/views/Miro/MiroView.vue'),
          meta: {
            pageTitle: 'ID_MENU_MIROS',
            subTitle: 'ID_MIROS_DESC',
            apMode: true,
          },
        },
        {
          path: '/vap',
          name: 'Vap',
          component: () => import(/* webpackChunkName:"WirelessView" */ '@/views/Wireless/WirelessView.vue'),
        },
        {
          path: '/firmware',
          name: 'Firmware',
          component: () => import(/* webpackChunkName:"FirmwareView" */ '@/views/Firmware/FirmwareView.vue'),
          meta: {
            pageTitle: 'ID_MENU_FIRMWARE',
            subTitle: 'ID_FIRMWARE_TITLE_DESC',
            apMode: true,
          },
        },
        {
          path: '/routing',
          name: 'Routing',
          component: () => import(/* webpackChunkName:"RouteView" */ '@/views/Network/Route/RouteView.vue'),
          meta: {
            groupTitle: 'ID_MENU_NETWORK',
            pageTitle: 'ID_MENU_ROUTING',
            subTitle: 'ID_RT_DESC',
          },
        },
        {
          path: '/iptv',
          name: 'Iptv',
          component: () => import(/* webpackChunkName:"IptvView" */ '@/views/Iptv/IptvView.vue'),
          meta: {
            groupTitle: 'ID_MENU_NETWORK',
            pageTitle: 'ID_IPTV_IPTV_LABEL',
          },
        },
        {
          path: '/upnp',
          name: 'Upnp',
          component: () => import(/* webpackChunkName:"UpnpView" */ '@/views/Nat/Upnp/UpnpView.vue'),
        },
        {
          path: '/ddns',
          name: 'Ddns',
          component: () => import(/* webpackChunkName:"DdnsView" */ '@/views/Ddns/DdnsView.vue'),
          meta: {
            pageTitle: 'ID_DDNS_TITLE',
            subTitle: 'ID_DDNS_SUBTITLE',
          },
        },
        {
          path: '/nat',
          name: 'Nat',
          component: () => import(/* webpackChunkName:"NatView" */ '@/views/Nat/NatView.vue'),
        },
        {
          path: '/service-port',
          name: 'ServicePort',
          component: () => import(/* webpackChunkName:"ServicePort" */ '@/views/Services/ServicePort.vue'),
          meta: {
            pageTitle: 'ID_MENU_SERVICE_PORT_MANAGEMENT',
            subTitle: 'ID_SERVICE_PORT_SUBTITLE',
          },
        },
        {
          path: '/vpn',
          name: 'Vpn',
          component: () => import(/* webpackChunkName:"VpnView" */ '@/views/Vpn/VpnView.vue'),
          redirect: {
            name: 'VpnSettings',
          },
          children: [
            {
              path: 'settings',
              name: 'VpnSettings',
              component: () => import(/* webpackChunkName:"VpnSettings" */ '@/views/Vpn/Settings/VpnSettings.vue'),
              meta: {
                groupTitle: 'ID_QVPN',
                pageTitle: 'ID_QVPN_SETTINGS_TITLE',
              },
            },
            {
              path: 'users',
              name: 'VpnUsers',
              component: () => import(/* webpackChunkName:"VpnUsers" */ '@/views/Vpn/Users/VpnUsers.vue'),
              meta: {
                groupTitle: 'ID_QVPN',
                pageTitle: 'ID_VPN_USER_LIST_TITLE',
              },
              props: true,
            },
            {
              path: 'log',
              name: 'VpnLog',
              component: () => import(/* webpackChunkName:"VpnLog" */ '@/views/Vpn/Log/VpnLog.vue'),
              meta: {
                groupTitle: 'ID_QVPN',
                pageTitle: 'ID_VPN_LOG_TITLE',
              },
            },
          ],
        },
        {
          path: '/vpn-client/profile',
          name: 'VpnClientConnectionProfile',
          component: () => import(/* webpackChunkName:"VpnClientConnectionProfile" */ '@/views/VpnClient/ConnectionProfile/ConnectionProfile.vue'),
          meta: {
            groupTitle: 'ID_MENU_VPNCLIENT',
            pageTitle: 'ID_VPNC_PROFILE_TITLE',
            subTitle: 'ID_VPNC_PROFILE_DESC',
          },
        },
        {
          path: '/vpn-client/log',
          name: 'VpnClientConnectionLog',
          component: () => import(/* webpackChunkName:"VpnClientConnectionLog" */ '@/views/VpnClient/ConnectionLog/ConnectionLog.vue'),
          meta: {
            groupTitle: 'ID_MENU_VPNCLIENT',
            pageTitle: 'ID_VPNC_LOG_TITLE',
            subTitle: 'ID_VPNC_LOG_DESC',
          },
        },
        {
          path: '/web-content-filter',
          name: 'WebContentFilter',
          component: () => import(/* webpackChunkName:"ParentalControl" */ '@/views/ParentalControl/ParentalControl.vue'),
        },
        {
          path: '/quwan',
          name: 'Quwan',
          component: () => import(/* webpackChunkName:"QuwanView" */ '@/views/Quwan/QuwanView.vue'),
          meta: {
            groupTitle: 'ID_QUWAN_TITLE',
            pageTitle: 'ID_QUWAN_SETTING_TITLE',
          },
        },
        {
          path: '/quwan-qos',
          name: 'QuwanQos',
          component: () => import(/* webpackChunkName:"QosView" */ '@/views/Quwan/Qos/QosView.vue'),
          meta: {
            groupTitle: 'ID_QUWAN_TITLE',
            pageTitle: 'ID_MENU_QOS',
            subTitle: 'ID_QOS_SUBTITLE',
            horaOnly: true,
          },
        },
        {
          path: '/quwan-qbelt',
          name: 'QuwanQbelt',
          component: () => import(/* webpackChunkName:"QuwanQbeltServerView" */ '@/views/Quwan/Qbelt/QuwanQbeltServerView.vue'),
          meta: {
            groupTitle: 'ID_QUWAN_TITLE',
            pageTitle: 'ID_QUWAN_QBELT_SERVER_TITLE',
            subTitle: 'ID_QUWAN_QBELT_SERVER_SUBTITLE',
          },
        },
        {
          path: '/debug',
          name: 'Debug',
          component: () => import(/* webpackChunkName:"DebugView" */ '@/views/Debug/DebugView.vue'),
          meta: {
            apMode: true,
          },
        },
        {
          path: '/event-log',
          name: 'EventLog',
          component: () => import(/* webpackChunkName:"EventLogView" */ '@/views/System/EventLog/EventLogView.vue'),
          meta: {
            apMode: true,
            groupTitle: 'ID_MENU_TOOLS',
            pageTitle: 'ID_MENU_EVENT_LOG',
            subTitle: 'ID_LOG_DESC',
          },
        },
        {
          path: '/system-setting',
          name: 'SystemSetting',
          component: () => import(/* webpackChunkName:"SystemSettingView" */ '@/views/System/SystemSetting/SystemSettingView.vue'),
          meta: {
            apMode: true,
            groupTitle: 'ID_MENU_TOOLS',
            pageTitle: 'ID_MENU_SYS_SETTING',
            subTitle: 'ID_SYS_SET_MAIN_DESC',
          },
        },
        {
          path: '/access-control',
          name: 'AccessControl',
          component: () => import(/* webpackChunkName:"AccessControlView" */ '@/views/System/AccessControl/AccessControlView.vue'),
          meta: {
            apMode: true,
            groupTitle: 'ID_MENU_TOOLS',
            pageTitle: 'ID_MENU_ACC_CTRL',
            subTitle: 'ID_AC_CTRL_DESC',
          },
        },
        {
          path: '/firewall',
          name: 'Firewall',
          component: () => import(/* webpackChunkName:"FirewallView" */ '@/views/Firewall/FirewallView.vue'),
          meta: {
            horaOnly: true,
          },
        },
        {
          path: '/operation-mode',
          name: 'OperationMode',
          component: () => import(/* webpackChunkName:"OperationModeView" */ '@/views/System/OperationMode/OperationModeView.vue'),
          meta: {
            apMode: true,
            groupTitle: 'ID_MENU_TOOLS',
            pageTitle: 'ID_MENU_OPMODE',
            subTitle: 'ID_OM_DESC',
          },
        },
        {
          path: '/usb',
          name: 'UsbSetting',
          component: () => import(/* webpackChunkName:"UsbSettingView" */ '@/views/System/UsbSetting/UsbSettingView.vue'),
          meta: {
            apMode: true,
            groupTitle: 'ID_MENU_TOOLS',
            pageTitle: 'ID_MENU_USB',
            subTitle: 'ID_USB_SETTINGS_DESC',
          },
        },
        {
          path: '/diagnostic',
          name: 'Diagnostic',
          component: () => import(/* webpackChunkName:"DiagnosticView" */ '@/views/System/Diagnostic/DiagnosticView.vue'),
          meta: {
            groupTitle: 'ID_MENU_TOOLS',
            pageTitle: 'ID_MENU_DIAG',
            subTitle: 'ID_DIAG_SUBTITLE',
          },
        },
        {
          path: '/control-host',
          name: 'ControlHost',
          component: () => import(/* webpackChunkName:"ControlHost" */ '@/views/ControlHost/ControlHost.vue'),
          meta: {
            apMode: true,
            pageTitle: 'ID_MENU_COUNTROL_HOST',
            subTitle: 'ID_CHOST_MAIN_DESC',
          },
        },
        {
          path: '/qnap-devices',
          name: 'QnapDevices',
          component: () => import(/* webpackChunkName:"QnapDevicesView" */ '@/views/QnapDevices/QnapDevicesView.vue'),
          meta: {
            apMode: true,
            pageTitle: 'ID_CONNECTED_QNAP_DEVICES',
            subTitle: 'ID_CONNECTED_QNAP_DEVICES_DESC',
          },
        },
        {
          path: '/physical-interface',
          name: 'PhysicalInterface',
          component: () => import(/* webpackChunkName:"PortView" */ '@/views/Network/Port/PortView.vue'),
          meta: {
            groupTitle: 'ID_MENU_NETWORK',
            pageTitle: 'ID_MENU_PHYSICAL_INTERFACE_SETTINGS',
            horaOnly: true,
          },
        },
        {
          path: '/ports',
          name: 'Ports',
          component: () => import(/* webpackChunkName:"PortView" */ '@/views/Network/Port/PortView.vue'),
          meta: {
            groupTitle: 'ID_MENU_NETWORK',
            pageTitle: 'ID_MENU_WAN_AND_LAN',
            miroOnly: true,
          },
        },
        {
          path: '/vlan-interface',
          name: 'VlanInterface',
          component: () => import(/* webpackChunkName:"VlanIfaceView" */ '@/views/Network/VlanIface/VlanIfaceView.vue'),
          meta: {
            groupTitle: 'ID_MENU_NETWORK',
            pageTitle: 'ID_MENU_VLAN',
            horaOnly: true,
          },
        },
        {
          path: '/bridge',
          name: 'Bridge',
          component: () => import(/* webpackChunkName:"BridgeView" */ '@/views/Network/Bridge/BridgeView.vue'),
          meta: {
            groupTitle: 'ID_MENU_NETWORK',
            pageTitle: 'ID_MENU_BRIDGE',
            horaOnly: true,
          },
        },
        {
          path: '/dhcp-clients',
          name: 'DhcpClients',
          component: () => import(/* webpackChunkName:"DhcpClientView" */ '@/views/Network/DhcpClient/DhcpClientView.vue'),
          meta: {
            groupTitle: 'ID_MENU_NETWORK',
            pageTitle: 'ID_MENU_DHCP_TABLE',
          },
        },
      ],
    },
    {
      path: '/',
      name: 'BasePage',
      redirect: {
        name: 'Login',
      },
      component: () => import(/* webpackChunkName:"BasePage" */ '@/views/BasePage.vue'),
      meta: {
        noAuth: true,
        basePage: true,
      },
      children: [
        {
          path: '/login',
          name: 'Login',
          component: () => import(/* webpackChunkName:"LoginView" */ '@/views/Login/LoginView.vue'),
          meta: {
            noAuth: true,
            basePage: true,
          },
        },
        {
          path: '/debug-login',
          name: 'DebugLogin',
          component: () => import(/* webpackChunkName:"Login" */ '@/views/Login/LoginView.vue'),
          meta: {
            noAuth: true,
            basePage: true,
          },
        },
        {
          path: '/initialize',
          name: 'Initialize',
          component: () => import(/* webpackChunkName:"InitializeView" */ '@/views/Initialize/InitializeView.vue'),
          meta: {
            noAuth: true,
            basePage: true,
          },
        },
      ],
    },
    {
      // Unknown route
      path: '*',
      redirect: {
        name: 'Home',
      },
    },
  ],
};

const router = new VueRouter(cfg);

routerGuard(router);

export default router;
