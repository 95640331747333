import { Network } from '@/services';
import { handleLanRequest } from '@/services/utilities/lan';
import { handleWanRequest } from '@/services/utilities/wan';
import NETWORK_INTERFACE_TYPE from '@/enums/NetworkInterfaceType';
import { i18n } from '@/lang';
import Lan from './Lan';
import OccupiedPort from './OccupiedPort';
import Wan from './Wan';

const storeState = {
  editedState: {
    portType: '',
    originalPortType: '',
  },
  editedConfig: {},

  hoveringPortName: '',
  isPortStatusTimerSuspended: false,
};

const storeGetters = {
  editedOriginalPort(state, getters) {
    if (!state.editedConfig.portName) {
      return null;
    }
    const { portType } = state.editedState;

    return portType === NETWORK_INTERFACE_TYPE.WAN ? getters['Wan/editedOriginalWan'] : getters['Lan/editedOriginalLan'];
  },
  editedPort(state, getters) {
    if (!state.editedConfig.portName) {
      return null;
    }
    const { portType } = state.editedState;

    return portType === NETWORK_INTERFACE_TYPE.WAN ? getters['Wan/editedWan'] : getters['Lan/editedLan'];
  },

  getPortDisplayText: (state, getters) => (portName, includeName = true) => {
    const port = [...state.Wan.wans, ...state.Lan.lans].find((item) => item.portName === portName);

    if (!port) {
      return '';
    }
    const portNameText = getters.getPortNameText(portName);
    const portText = i18n.t('ID_BL_PORT_N', { 0: portNameText });

    return port.name && includeName ? `${port.name} - ${portText}` : portText;
  },

  getPortRateText: (state, getters, rootState, rootGetters) => (portName) => {
    const profile = rootGetters['Network/currentNetworkProfile'];
    const portNameIdx = profile.portNames.indexOf(portName);
    const portRate = profile.portRates[portNameIdx] || '1g';

    return portRate.replace('g', 'GbE');
  },
  networkProfilePortItems(state, getters, rootState, rootGetters) {
    const profile = rootGetters['Network/currentNetworkProfile'];
    const profilePortItems = profile.portNames.map((portName, idx) => {
      let portType = '';

      // portType
      if (profile.availableWanPorts.includes(portName)) {
        portType = 'wan';
      } else if (profile.availableVlanPorts.includes(portName)) {
        portType = 'vlan-port';
      }

      const portItem = {
        portName,
        portRate: profile.portRates[idx],
        portType,
        defaultValues: {},
      };

      if (portType === 'wan') {
        portItem.defaultValues.lineRateRx = profile.defaultValues.wanRxRates[idx];
        portItem.defaultValues.lineRateTx = profile.defaultValues.wanTxRates[idx];
      }

      return portItem;
    });

    profilePortItems.forEach((portItem) => {
      // portRateIndex
      const samePortRateList = profilePortItems
        .filter((item) => item.portRate === portItem.portRate);

      portItem.portRateIndex = samePortRateList
        .findIndex((item) => item.portName === portItem.portName);
    });

    return profilePortItems;
  },
  findNetworkProfilePortItem: (state, getters) => (portName) => {
    const profilePortItem = getters.networkProfilePortItems
      .find((item) => item.portName === portName);

    return profilePortItem || null;
  },
  getNetworkProfilePortDefaultValues: (state, getters) => (portName) => {
    const portItem = getters.findNetworkProfilePortItem(portName);

    return portItem?.defaultValues ?? {
      lineRateRx: 0,
      lineRateTx: 1000000000,
    };
  },
  getPortNameText: (state, getters) => (portName) => {
    const profilePortItems = getters.networkProfilePortItems;
    const firstPortPortRate = profilePortItems[0].portRate;
    const currentPortItem = getters.findNetworkProfilePortItem(portName);

    if (currentPortItem.portRate === firstPortPortRate) {
      return portName;
    }

    return `${currentPortItem.portRate.replace('g', 'G')}-${currentPortItem.portRateIndex + 1}`;
  },
};

const mutations = {
  modifyEditedConfig(state, { key, value, target }) {
    target = target || state.editedConfig;
    target[key] = value;
  },
  initEditedState(state, { originalPortType }) {
    state.editedState.originalPortType = originalPortType;
  },
  resetEditedState(state) {
    state.editedState.portType = '';
    state.editedState.originalPortType = '';
    state.editedConfig = {};
  },
  modifyPortType(state, portType) {
    let config = null;
    const originalPortType = state.editedState.portType;

    state.editedState.portType = portType;

    if (portType === NETWORK_INTERFACE_TYPE.WAN) {
      config = state.Wan.editedConfig;
    } else {
      config = state.Lan.editedConfig;
    }
    const originalConfig = state.editedConfig;

    state.editedConfig = config;

    if (originalPortType) {
      state.editedConfig.enabled = originalConfig.enabled;
    }
  },

  setHoveringPortName(state, portName) {
    state.hoveringPortName = portName;
  },
  clearHoveringPortName(state) {
    state.hoveringPortName = '';
  },

  setPortStatusTimerSuspended(state, isSuspended) {
    state.isPortStatusTimerSuspended = isSuspended;
  },
};

const actions = {
  async getPorts({ commit }) {
    const portConfig = await Network.getPorts();

    commit('Wan/initWans', portConfig.wanPorts);
    commit('Lan/initLans', portConfig.lanPorts);
  },
  async getPortsStatus({ commit }) {
    const portsStatusRes = await Network.getPortsStatus();

    commit('Wan/setWansStatus', portsStatusRes.result.wan);
    commit('Lan/setLansStatus', portsStatusRes.result.lan);
    commit('OccupiedPort/setOccupiedPortsStatus', portsStatusRes.result.occupied || []);
  },

  async putCurrentPorts({ state }) {
    const wanPorts = state.Wan.editedWans.map((wan) => handleWanRequest(wan));
    const lanPorts = state.Lan.editedLans.map((lan) => handleLanRequest(lan, lan.enabled));

    try {
      await Network.putPorts({
        dnsServers: [], // unused for Miro and Hora, give empty array
        wan: wanPorts,
        lan: lanPorts,
      });
    } catch (error) {
      // Ignore "faild to fetch"
      if (error.error_code !== 90000) {
        throw error;
      }
    }
  },

  async refreshPorts({ dispatch }) {
    await Promise.all([
      dispatch('getPorts'),
      dispatch('getPortsStatus'),
    ]);
  },

  startEditing({ state, commit, dispatch }, portName) {
    const wan = state.Wan.editedWans.find((port) => port.portName === portName);
    const portType = wan ? NETWORK_INTERFACE_TYPE.WAN : NETWORK_INTERFACE_TYPE.LAN;

    commit('initEditedState', { originalPortType: portType });
    dispatch('Wan/startEditing', portName);
    dispatch('Lan/startEditing', portName);
    commit('modifyPortType', portType);
  },
  endEditing({ commit, dispatch }) {
    commit('resetEditedState');
    dispatch('Wan/endEditing');
    dispatch('Lan/endEditing');
  },
  resetEditedPorts({ commit }) {
    commit('Wan/resetEditedWans');
    commit('Lan/resetEditedLans');
  },

  applyEditing({ state, commit, dispatch }, editedConfig) {
    const { portType } = state.editedState;

    if (portType === NETWORK_INTERFACE_TYPE.WAN) {
      dispatch('Wan/applyEditing', editedConfig);
      commit('Lan/checkRemoveLan', editedConfig.portName);
    } else if (portType === NETWORK_INTERFACE_TYPE.LAN) {
      dispatch('Lan/applyEditing', editedConfig);
      commit('Wan/checkRemoveWan', editedConfig.portName);
    }
  },
  applyEditingAndPutLater({ state, commit, dispatch }, editedConfig) {
    const { portType } = state.editedState;

    if (portType === NETWORK_INTERFACE_TYPE.WAN) {
      dispatch('Wan/applyEditingAndPutLater', editedConfig);
      commit('Lan/checkRemoveLan', editedConfig.portName);
    } else if (portType === NETWORK_INTERFACE_TYPE.LAN) {
      dispatch('Lan/applyEditingAndPutLater', editedConfig);
      commit('Wan/checkRemoveWan', editedConfig.portName);
    }
  },
};

const modules = {
  Wan,
  Lan,
  OccupiedPort,
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  mutations,
  actions,
  modules,
};
