/* eslint-disable import/prefer-default-export */

import LAN_IP6_TYPE from '@/enums/LanIp6Type';

/**
 * Check enabled/disabled state for LAN interface config.
 * @param {Object} lan - LAN interface config
 * @returns {boolean} True if LAN interface config is enabled
 */
export function isLanIp6Enabled(lan) {
  return !!lan?.ip6Type && lan.ip6Type !== LAN_IP6_TYPE.DISABLED;
}
