const ERROR_CODE = {
  INVALID_PARAMETERS: 20,
  FIRMWARE_OLD_VERSION: 10019,
  LOCAL_ACCOUNT_LOGIN_INVALID: 10030,
  TOKEN_EXPIRED: 10031,
  TOKEN_INVALID: 10032,
  QID_LOGIN_FIRST_BIND: 10035,
  QID_LOGIN_NETWORK_UNREACHABLE: 10036,
  QID_LOGIN_CAN_ONLY_BIND_ONE: 10037,
  MYQNAPCLOUD_CONNECTION_ERROR: 10040,
  QID_LOGIN_NOT_IN_ORG: 10041,
  QID_LOGIN_NO_PERMISSION: 10042,
  MYQNAPCLOUD_ORGANIZATION_MISSING_ERROR: 10043,
  MYQNAPCLOUD_SITE_MISSING_ERROR: 10044,
  MYQNAPCLOUD_API_SERVER_ERROR: 10045,
  MYQNAPCLOUD_API_ORGANIZATION_LIST_ERROR: 10046,
  MYQNAPCLOUD_API_SITE_LIST_ERROR: 10047,
  UNDERLYING_COMMAND_ERROR: 10052, // Failed to execute \"%v\" command,
  NETWORK_CONFLICT: 10058,
  LOCAL_ACCOUNT_DEFAULT_PASSWORD_ERROR: 10062,
  DUPLICATED_SERVICE_PORT: 10073,
  QUNMS_SUBNET_INVALID_ERROR: 10102,
  QUNMS_ADD_VPN_TUNNEL_LIMITATION: 10106,
  QUNMS_JOIN_QUWAN_VPN_TUNNEL_LIMITATION: 10107,
  QUNMS_GET_VPN_GROUP_ERROR: 10110,
  RESTORE_FILE_CORRUPTED_ERROR: 10111,
  RESTORE_DEVICE_VERSION_ERROR: 10112,
  RESTORE_FILE_COPY_ERROR: 10113,
  RESTORE_DEVICE_MODEL_MISMATCH_ERROR: 10114,
  RESTORE_FILE_INCOMPATIBILITY: 10116,
  DDNS_FAILED_TO_GET_DEIVCE_INFO: 10122,
  QUNMS_DEPLOYMENT_FAILED: 10501,
  QUNMS_CONNECT_DOMAIN_FAILED: 10503,
  QUNMS_INTERFACE_IP_CONFLICT: 10702,
  FEATURE_INTERFACE_CONFLICT: 10703,
  LAN_INTERFACE_IS_LAST: 10704,
  SCHEDULE_TIME_CONFLICT_WITH_RESTART_SCHEDULE: 10761,
  SCHEDULE_TIME_CONFLICT_WITH_FIRMWARE_SCHEDULE: 10762,
  SCHEDULE_TIME_CONFLICT_WITH_WIRELESS_SCHEDULE: 10763,
  RMA_CLOUD_INFO_UNREACHABLE: 10771,
  RMA_CLOUD_NOT_SIGN_IN: 10772,
  RMA_FOLDER_CREATION_ERROR: 10773,
  RMA_SAVE_RESTORE_FILE_ERROR: 10774,
  RMA_QUWAN_LOGIN_ERROR: 10775,
  RMA_QUWAN_CONNECTION_ERROR: 10776,
  RMA_QUWAN_DEVICE_STATUS_ERROR: 10777,
  RMA_FW_COMPATIBLE_CHECK_ERROR: 10778,
  RMA_RESTORE_FILE_NOT_EXIST: 10779,
  RMA_RESTORE_FLAG_CREATION_ERROR: 10780,
  RMA_LAUNCH_CLOUD_AGENT_ERROR: 10781,
  RMA_TARGET_FW_FLAG_CREATION_ERROR: 10782,
  RMA_SET_QUWAN_STATUS_ERROR: 10783,
  RMA_DEVICE_BINDING_ERROR: 10784,
  RMA_ORGANIZATION_ID_NOT_EXIST: 10785,
  USB_PACKET_CAPTURE_USB_NOT_PLUGED: 10801,
  USB_PACKET_CAPTURE_USB_SUPPORT: 10802,
  USB_PACKET_CAPTURE_USB_DETECT_REMOVING: 10803,
  USB_PACKET_CAPTURE_USB_NOT_ENOUGH: 10804,
  FIRMWARE_DOWNGRADE_VERSION_TOO_OLD: 10869,
};

export default ERROR_CODE;
