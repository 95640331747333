<template>
  <ButtonBase
    class="q-button-text"
    :type="type"
    :class="cssClass"
    @click="$emit('click', $event)"
  >
    <span :class="labelClass">{{ $t(label) }}</span>
  </ButtonBase>
</template>

<script>
import ButtonBase from './ButtonBase.vue';

export default {
  name: 'ButtonText',
  components: {
    ButtonBase,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'button',
      validator(type) {
        return ['button', 'submit'].includes(type);
      },
    },
    theme: {
      type: String,
      default: 'primary',
      validator(theme) {
        return ['primary', 'secondary', 'danger'].includes(theme);
      },
    },
    ellipse: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: String,
      default: null,
    },
  },
  computed: {
    cssClass() {
      return {
        [`q-button-theme-${this.theme}`]: true,
        'q-button-ellipse': this.ellipse,
      };
    },
  },
};
</script>
