export const SYSTEM_RESTORE_MIN_FIRMWARE_VERSION = '2.3';

/**
 * Convert the schedule update time format for GUI.
 * @param {string} updateTime - The schedule update time of backend API
 * @returns {Object|null} The converted update time informations, null if update time is invalid
 */
export function getDisplayedFirmwareUpdateTime(updateTime) {
  const UPDATE_TIME_FORMAT_LENGTH = 15; // update time format: xxxx-xx-xx-xxxx

  if (typeof updateTime !== 'string' || updateTime.length !== UPDATE_TIME_FORMAT_LENGTH) {
    return null;
  }

  const timeParts = updateTime.split('-');

  if (timeParts.length !== 4) {
    return null;
  }

  const date = timeParts.slice(0, 3).join('/');
  const time = `${timeParts[3].slice(0, 2)}:${timeParts[3].slice(2)}`;

  return { date, time };
}
