export const MOCK_SERVER = 'http://localhost:3000';

function mapPath(version, mapping) {
  const prefix = `${window.location.protocol}//${window.location.host}/miro/api/${version}/`;
  const paths = {};

  Object.entries(mapping).forEach(([key, value]) => {
    if (typeof value === 'function') {
      paths[key] = (...args) => `${prefix}${value(...args)}`;
    } else if (typeof value === 'object') {
      Object.entries(value).forEach(([subKey, subValue]) => {
        paths[subKey] = `${prefix}${key}${subValue ? `/${subValue}` : ''}`;
      });
    } else {
      paths[key] = `${prefix}${value}`;
    }
  });

  return paths;
}

const v2 = mapPath('v2', {
  AccessSetting: 'access_setting',
  airgap: {
    AirGapDevices: 'register',
    AirGapDeviceInfo: 'info/device',
    AirGapPortInfo: 'info/port',
    AirGapDeviceUnbind: 'jobs/unbind',
  },
  blocklist: {
    BlockedClients: '',
    BlockedClientsBlockableInterfaces: 'avlIfaces',
  },
  Clients: 'clients',
  getClientTraffic: (id) => `clients/${id}/traffic`,
  eventlogs: {
    EventLogs: '',
    EventLogsExport: 'export',
  },
  RefreshToken: 'refresh_token',
  helpdesk: {
    RemoteSupportEnable: 'enable',
    RemoteSupportCheck: 'check',
    RemoteSupportUser: 'user',
    RemoteSupportDisable: 'disable',
  },
  nat: {
    Alg: 'alg',
    Dmz: 'dmz',
    PortForwarding: 'port_forwarding',
  },
  network: {
    NetworkProfiles: 'profiles',
    Ports: 'ports',
    PortsStatus: 'ports_status',
    PortsMacAddr: 'ports_mac_addr',
    Wan: 'wan',
    Lan: 'lan',
    ReconnectPppoe: 'reconnect_pppoe',
    Bridge: 'bridge',
    BridgeStatus: 'bridge_status',
    VlanIf: 'vlanif',
    VlanIfStatus: 'vlanif_status',
    RemoveVlanIfsPort: 'remove_vlanifs_port',
    DhcpClient: 'dhcp_client',
    AvailableWanInterfaces: 'available_wan_interfaces',
    AvailableLanInterfaces: 'available_lan_interfaces',
    NetworkSettings: 'settings',

    // Only used in Miro
    LanSpeed: 'change_lan_speed',
  },
  NetworkStatus: 'network_status',
  parental_control: {
    // The key should not duplicated with other keys in v2 object
    ParentalControlClientList: 'clients',
    ParentalControlRole: 'role',
    ParentalControlRoles: 'roles',
    ParentalControlRoleAction: 'enable',
  },
  getParentalControlClient: (roleId, macAddress) => `parental_control/role/${roleId}/client/${macAddress}`,
  quwan: {
    QuwanVersion: 'version',
    QuwanVpnServers: 'qvpn/qvpn_servers',
    QuwanVpnOnlineUsers: 'qvpn/qvpn_clients',
    QuwanNetworkIpSubnetCheck: 'network/ip_subnet_check',
    QuwanNetworkSingleIpSubnetCheck: 'network/single_subnet_check',
    QuwanDevice: 'device',
  },
  routing: {
    Route: '',
    RouteIpv4: 'ipv4',
    RouteIpv6: 'ipv6',
  },
  service_ports: {
    CustomServicePorts: 'custom',
  },
  system: {
    Diagnostic: 'diagnostic',
    DiagnosticPing: 'diagnostic/ping',
    DiagnosticTraceroute: 'diagnostic/traceroute',
    FaultyDeviceReplacementRestore: 'rma/restore',
    FaultyDeviceReplacementUpdateDevice: 'rma/device',
    MachineInfo: 'machine_info',
    OperationSetting: 'operation_setting',
    RegionSetting: 'region',
    SystemBackup: 'backup',
    SystemBuzzer: 'buzzer',
    SystemDeviceName: 'device_name',
    SystemHardware: 'hardware',
    SystemHardwareStatus: 'hardware_status',
    SystemReboot: 'reboot',
    SystemReset: 'reset',
    SystemRestore: 'restore',
    SystemRestoreFile: 'restore/file',
    SystemSchedule: 'schedule',
    SystemScheduleSkip: 'schedule/skip',
    UiSetting: 'ui_setting',
    CountryCode: 'country_code',
    CountryCodeStatus: 'country_code/status',
    UsbPacketCapture: 'sniffer',
    SystemDevice: 'device',
    IdleTime: 'expire_time',
    RestoreBackupInfo: 'backup/info',
    RestoreBackupFile: 'backup/file',
  },
  user_profiles: {
    PasswordDefault: 'default',
    LocalAccount: 'local',
    RemoteAccount: 'remote',
    Qid: 'qid',
  },
  vpn: {
    WireguardServer: 'wireguard',
    WireguardKey: 'wireguard_publickey',
    WireguardUser: 'wireguard_user',
    WireguardUserExport: 'export_wireguard_user',
  },
  WebsiteInfo: 'website_info',
  wireless: {
    WirelessProfile: 'profile',
    WirelessStatus: 'status',
    WirelessBand: 'band/setting',
    WirelessBandStatus: 'band/status',
    WirelessChannelList: 'channel_list',
    WirelessRestart: 'restart',
    Vap: 'vap/setting',
    VapStatus: 'vap/status',
    Wps: 'wps/setting',
    WpsTrigger: 'wps/trigger',
    WpsStatus: 'wps/status',
    CacState: 'cac_state',
  },
  FinishInitial: 'finish_initial',
  firmware: {
    Firmware: '',
    FirmwareInitUpgradeStatus: 'init_upgrade_status',
    FirmwareRemoteFwInfo: 'remote_fw_info',
    FirmwareSchedule: 'schedule',
    FirmwareUpgradeCancel: 'upgrade_cancel',
    FirmwareUpgradeStatus: 'upgrade_status',
    FirmwareWebFirmware: 'web_firmware',
    FirmwareBeta: 'beta',
    FirmwareQuwanSettings: 'quwan/settings',
    FirmwareDowngrade: 'downgrade',
    FirmwareBackup: 'backup',
  },
  qnap: {
    QnapDevices: 'devices',
  },
  qfirewall: {
    FirewallInterfaces: 'interfaces',
    FirewallIpv6: 'ipv6',
  },
  ext_vpn: {
    ExternalVpnInterface: 'interface',
  },
  debugmode: {
    QuwanWmdParams: 'quwanwmd_parameters',
    DpiEnabled: 'dpi/enabled',
    DpiVersion: 'dpi/version',
  },
  IptvSetting: 'iptv_setting',
  ReservedSetting: 'reserved_setting',
});

export default {
  v2,
  ...mapPath('v1', {
    // Login
    init: 'init',
    login: 'login',
    qidLogin: 'qid_login',
    logout: 'logout',

    // Token
    token: 'token',

    // Miro
    MachineInfo: 'machine_info',
    RemoteFirmware: 'remote_fw_info',

    // Speed test
    SpeedTestHistory: 'speed_test_history',
    SpeedTestStatus: 'speed_test_status',
    SpeedTestTrigger: 'speed_test_trigger',

    // Firewall
    Firewall: 'qfirewall',
    PortForwardFeaturesStatus: 'qfirewall/features/status',

    // QoS
    QosUserRules: 'qos/user_rules',
    QosSegmentRules: 'qos/segment_rules',
    QosPriority: 'qos/priority',

    WirelessLocation: 'wireless/location',

    // QuWAN
    ConnectionStatus: 'connection_status',
    CloudService: 'cloud_service',
    DeploymentProgress: 'quwan/deployment_progress',
    GetSites: (organizationId) => `organizations/${organizationId}/sites`,
    GetRegions: (organizationId) => `organizations/${organizationId}/vpn_groups`,
    Organizations: 'organizations',
    QuwanSettings: 'quwan/settings',
    QuwanStatus: 'quwan/status',
    QuwanType: 'quwan/vpn_attr',
    QunmsDevice: 'qunms/device',
    VpnTunnelCheck: 'qunms/vpn_tunnel_check',

    // VPN Server
    connectionLog: 'vpn/connection_log',
    l2tpServer: 'vpn/l2tp_server',
    openvpnServer: 'vpn/openvpn_server',
    openvpnProfile: 'vpn/openvpn_profile',
    qbeltServer: 'vpn/qbelt_server',
    vpnUsers: 'vpn/user',
    vpnOnlineUsers: 'vpn/online_user',

    // VPN Client
    VpnClient: 'vpn/client',
    VpnClientOpenvpn: 'vpn/client/openvpn',
    VpnClientOpenvpnCheck: 'vpn/client/openvpn/check',
    VpnClientConnectionLog: 'vpn/client/connection_log',

    // Debug
    BasicInfo: 'debugmode/information',
    ReverseSSH: 'debugmode/reversessh',
    SiteInfo: 'debugmode/site',
    SshCfg: 'debugmode/ssh',
    DumpLog: 'debugmode/dumplog',
    Ncsid: 'debugmode/ncsid_parameters',
    SessionLimitation: 'debugmode/limit_session',

    // AP Mode - Network
    ApModeNetworkInfo: 'ap_mode/network_info',

    // USB
    UsbInfo: 'usb_info',
    FtpServer: 'ftp_serv',
    FtpUser: 'ftp_user',
    FtpDb: 'ftp_db',

    // Control Host
    ControlhostQts: 'controlhost/qts',

    // QMiroPlus redirect
    QtsRedirect: 'qts/redirect',

    // Compare qpkg, firmware version
    FirmwareVersion: 'qts/firmware_version',

    // Upnp
    Upnp: 'upnp',
    UpnpService: 'upnp_service',
    UpnpWan: 'upnp_wan',

    // Ddns
    Ddns: 'ddns',
    DdnsInfo: 'ddns_info',
    DdnsWan: 'ddns_wan',

    // QuNMS
    QunmsDpiApp: 'qunms/dpi_app',
    QunmsDpiClientOs: 'qunms/dpi_client_os',

    // ServicePort
    ServicePortCustom: 'serv_port_mgnt_custom',
    ServicePortSystem: 'serv_port_mgnt_system',

    // miro
    MiroList: 'miros',
    Satellite: 'satellite',

    // lan - only use to handle qpkg issue
    LanInfo: 'laninfo',
  }),
};
