import BRIDGE_TYPE from '@/enums/BridgeType';
import LAN_DEFAULT_GATEWAY from '@/enums/LanDefaultGateway';
import VLAN_IFACE_TYPE from '@/enums/VlanIfaceType';
import apiPath from '../apiPath';
import { qFetch } from '../utilities';

/**
 * Get the network configuration of the LAN interface the complete configuration
 * @param {Object} lan - LAN interface contains the complete configuration
 * @returns {Object} The network configuration of the LAN interface
 */
function getLanNetworkConfig(lan) {
  const lanDhcpService = lan.dhcpService;

  return {
    dhcpService: {
      dnsServers: lanDhcpService.dnsServers,
      endIp: lanDhcpService.endIp,
      leaseTime: lanDhcpService.leaseTime,
      relayServers: lanDhcpService.relayServers,
      reservedIps: lanDhcpService.reservedIps,
      serviceType: lanDhcpService.serviceType,
      startIp: lanDhcpService.startIp,
    },
    enableStp: lan.enableStp,
    ip4Address: lan.ip4Address,
    ip4Prefix: lan.ip4Prefix,
    ip4Type: lan.ip4Type.toUpperCase(),
    portName: lan.portName,
  };
}

/**
 * Get the network configuration of the bridge interface the complete configuration
 * @param {Object} bridge - Bridge interface contains the complete configuration
 * @returns {Object} The network configuration of the bridge interface
 */
function getBridgeNetworkConfig(bridge) {
  return {
    bridgeId: bridge.bridgeId,
    type: bridge.type,
    lan: getLanNetworkConfig(bridge.lan),
  };
}

/**
 * Get the network configuration of the VLAN interface the complete configuration
 * @param {Object} vlan - VLAN interface contains the complete configuration
 * @returns {Object} The network configuration of the VLAN interface
 */
function getVlanNetworkConfig(vlan) {
  return {
    vlanIfId: vlan.vlanIfId,
    type: vlan.type,
    lan: getLanNetworkConfig(vlan.lan),
  };
}

/**
 * Handle the LAN interface format form API for GUI
 * @param {Object} lan - LAN interface from API
 * @returns {Object|null} Formatted LAN interface for GUI
 */
function handlerLanResponse(lan) {
  if (!lan) {
    return null;
  }

  return {
    ...lan,
    dhcpService: {
      serviceType: lan.dhcpService.serviceType,
      startIp: lan.dhcpService.startIp,
      endIp: lan.dhcpService.endIp,
      leaseTime: lan.dhcpService.leaseTime,
      dnsServers: lan.dhcpService.dnsServers,
      reservedIps: lan.dhcpService.reservedIps,
      relayServers: lan.dhcpService.relayServers,

      // The naming "routes" given by the backend follows the RFC spec
      // In current spec, we only need one route for the DHCP server
      // Extract the first router of the array to be the default router
      defaultGatewayType: lan.dhcpService.routers?.length === 0
        ? LAN_DEFAULT_GATEWAY.AUTO
        : LAN_DEFAULT_GATEWAY.MANUAL,
      defaultGatewayIp: (lan.dhcpService.routers && lan.dhcpService.routers[0]) || '',
    },
  };
}

export default {
  /**
   * Get network profile options of current Model.
   * @returns {Promise<Object>} The network profile options of current Model
   */
  async getProfiles() {
    const { result } = await qFetch.get(apiPath.v2.NetworkProfiles);

    return result;
  },
  /**
   * Get all ports config, including WAN, LAN, VLAN Port and current profile.
   * note: QHora and QMiro do not have LAN, LAN is actual used by VM.
   * @returns {Promise<Object>} Port configuration includes WAN/LAN interfaces
   */
  async getPorts() {
    const { result } = await qFetch.get(apiPath.v2.Ports);

    return {
      ...result,
      wanPorts: result.wan,
      lanPorts: result.lan?.map((lan) => handlerLanResponse(lan)) || [],
    };
  },
  /**
   * Put all ports config. including WAN, VLAN Port and current profile.
   * @param portsConfig - all ports config
   * @returns {Promise<Object>}
   */
  async putPorts(portsConfig) {
    return qFetch.put(apiPath.v2.Ports, { data: portsConfig });
  },
  /**
   * Get all ports status, including current IP address and network status.
   * @returns {Promise<Object>}
   */
  async getPortsStatus() {
    return qFetch.get(apiPath.v2.PortsStatus);
  },
  /**
   * Put WAN config with the specified port ID.
   * @param {string} portName - port ID
   * @param {Object} wanConfig - WAN config
   * @returns {Promise<Object>}
   */
  async putWan(portName, wanConfig) {
    return qFetch.put(`${apiPath.v2.Wan}/${portName}`, { data: wanConfig });
  },
  /**
   * Update the specific LAN port
   * @param {Object} lanConfig - LAN config to update
   * @returns {Promise<void>}
   */
  async putLan(lanConfig) {
    await qFetch.put(
      `${apiPath.v2.Lan}/${lanConfig.portName}`,
      {
        data: lanConfig,
      },
    );
  },
  /**
   * Change the speed of the LAN port
   * @param {string} speed - New speed to update the LAN port
   * @returns {Promise<void>}
   */
  async changeLanPortSpeed(speed) {
    await qFetch.post(`${apiPath.v2.LanSpeed}`, { data: { speed } });
  },
  /**
   * Get network connection status
   * @returns {Promise<Object>}
   */
  async getNetworkStatus() {
    return qFetch.get(apiPath.v2.NetworkStatus);
  },
  /**
   * Get mac address info of all ports
   * @returns {Promise<Object>}
   */
  async getPortsMacAddr() {
    return qFetch.get(apiPath.v2.PortsMacAddr);
  },
  /**
   * Try to reconnect PPPoE
   * @param {string} interfaceId - WAN interface ID
   * @returns {Promise<Object>}
   */
  async postReconnectPppoe(interfaceId) {
    return qFetch.post(`${apiPath.v2.ReconnectPppoe}/${interfaceId}`);
  },
  /**
   * Get a list of VLAN interface
   * @returns {Promise<Object[]>} List of VLAN interface
   */
  async getVlanIfaces() {
    const { result } = await qFetch.get(apiPath.v2.VlanIf);

    return result.map((vlan) => {
      if (vlan.type === VLAN_IFACE_TYPE.LAN) {
        return {
          ...vlan,
          lan: handlerLanResponse(vlan.lan),

        };
      }

      return vlan;
    });
  },
  /**
   * Get all VLAN interfaces status
   * @returns {Promise<Object>}
   */
  async getVlanIfacesStatus() {
    return qFetch.get(apiPath.v2.VlanIfStatus);
  },
  /**
   * Modify config of VLAN interface
   * @param {string} vlanIfId - VLAN interface ID
   * @param {Object} vlanIfaceConfig - VLAN interface config
   * @returns {Promise<Object>}
   */
  async putVlanIface(vlanIfId, vlanIfaceConfig) {
    return qFetch.put(`${apiPath.v2.VlanIf}/${vlanIfId}`, { data: vlanIfaceConfig });
  },
  /**
   * Add new VLAN interface
   * @param {Object} vlanIfaceConfig - VLAN interface config
   * @returns {Promise<Object>}
   */
  async postVlanIface(vlanIfaceConfig) {
    return qFetch.post(apiPath.v2.VlanIf, { data: vlanIfaceConfig });
  },
  /**
   * Delete VLAN interface config with specified ID
   * @param {string} vlanIfId - VLAN interface ID
   * @returns {Promise<Object>}
   */
  async deleteVlanIface(vlanIfId) {
    return qFetch.delete(`${apiPath.v2.VlanIf}/${vlanIfId}`);
  },
  /**
   * Delete VLAN interface config with specified ID
   * @param {string} portName - port ID
   * @param {string} vlanIfIds - list of VLAN interface ID
   * @returns {Promise<Object>}
   */
  async postRemoveVlanIfacesPort(portName, vlanIfIds) {
    return qFetch.post(apiPath.v2.RemoveVlanIfsPort, {
      data: {
        portName,
        vlanIfIds,
      },
    });
  },

  /**
   * Get the list of the bridges
   * @returns {Promise<Object[]>} List for the bridges
   */
  async getBridges() {
    const { result } = await qFetch.get(apiPath.v2.Bridge);

    return result.map((bridge) => {
      if (bridge.type === BRIDGE_TYPE.LAN) {
        return {
          ...bridge,
          lan: handlerLanResponse(bridge.lan),

        };
      }

      return bridge;
    });
  },
  /**
   * Get all Bridges status
   * @returns {Promise<Object>}
   */
  async getBridgesStatus() {
    return qFetch.get(apiPath.v2.BridgeStatus);
  },
  /**
   * Modify config of Bridge
   * @param {string} bridgeId - Bridge ID
   * @param {Object} bridgeConfig - Bridge config
   * @returns {Promise<Object>}
   */
  async putBridge(bridgeId, bridgeConfig) {
    return qFetch.put(`${apiPath.v2.Bridge}/${bridgeId}`, { data: bridgeConfig });
  },
  /**
   * Add new Bridge
   * @param {Object} bridgeConfig - Bridge config
   * @returns {Promise<Object>}
   */
  async postBridge(bridgeConfig) {
    return qFetch.post(apiPath.v2.Bridge, { data: bridgeConfig });
  },
  /**
   * Delete Bridge config with specified ID
   * @param {string} bridgeId - Bridge ID
   * @returns {Promise<Object>}
   */
  async deleteBridge(bridgeId) {
    return qFetch.delete(`${apiPath.v2.Bridge}/${bridgeId}`);
  },
  /**
   * Get DHCP clients info
   * @returns {Promise<Object>}
   */
  async getDhcpClients() {
    return qFetch.get(apiPath.v2.DhcpClient);
  },
  /**
   * Manage DHCP clients
   * @param {string} method - method type
   * @param {Object} data - required datas
   * @returns {Promise<Object>}
   */
  async postDhcpClient(method, data) {
    return qFetch.post(apiPath.v2.DhcpClient, {
      method,
      data,
    });
  },
  /**
   * Get available WAN interfaces
   * @returns {Promise<Object>} All current available WAN interfaces with ID
   */
  async getAvailableWanInterfaces() {
    const { result } = await qFetch.get(apiPath.v2.AvailableWanInterfaces);

    return result;
  },
  /**
   * Get available LAN interfaces
   * @returns {Promise<Object>} All current available LAN interfaces
   */
  async getAvailableLanInterfaces() {
    const { result } = await qFetch.get(apiPath.v2.AvailableLanInterfaces);

    return result;
  },
  /**
   * Get the interface IDs used by external VPN.
   * @returns {Promise<Object>} All interface IDs used by external VPN
   */
  async getExternalVpnInterface() {
    const { result } = await qFetch.get(apiPath.v2.ExternalVpnInterface);

    return {
      bridgeIds: result.bridgeId ?? [],
      portNames: result.portName ?? [],
      vlanIfIds: result.vlanIfId ?? [],
    };
  },

  /**
   * Update the network configuration of the server
   * @param {Object} networkConfig - Configuration of the network for update
   * @returns {Promise<void>}
   */
  async updateNetworkSettingConfig(networkConfig) {
    const lanConfigs = networkConfig.lans?.map((lan) => getLanNetworkConfig(lan));
    const bridgeConfigs = networkConfig.bridges?.map((bridge) => getBridgeNetworkConfig(bridge));
    const vlanConfigs = networkConfig.vlans?.map((vlan) => getVlanNetworkConfig(vlan));

    await qFetch.put(apiPath.v2.NetworkSettings, {
      data: {
        lan: lanConfigs || [],
        bridge: bridgeConfigs || [],
        vlan: vlanConfigs || [],
      },
    });
  },
};
