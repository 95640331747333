import apiPath from '../apiPath';
import { qFetch } from '../utilities';
import { handleRestoreFormData } from './utilities/fileUpload';

export default {
  /**
   * Get firmware info. (include local and remote)
   * @returns {Promise<Object>}
   */
  async getFirmware() {
    return qFetch.get(apiPath.v2.Firmware);
  },
  /**
   * Uploading firmware file to update firmware
   * @param payload - form data contains firmware file
   * @returns {Promise<Object>}
   */
  async postFirmware(payload) {
    return qFetch.upload(apiPath.v2.Firmware, payload);
  },
  /**
   * Reset upgrade status of firmware
   * @returns {Promise<Object>}
   */
  async postInitUpgradeStatus() {
    return qFetch.post(apiPath.v2.FirmwareInitUpgradeStatus);
  },
  /**
   * Update remote firmware info in local device from remote url
   * @returns {Promise<Object>}
   */
  async postRemoteFwInfo() {
    return qFetch.post(apiPath.v2.FirmwareRemoteFwInfo);
  },
  /**
   * Get update schedule of fimrware.
   * @returns {Promise<Object>}
   */
  async getSchedule() {
    const { result } = await qFetch.get(apiPath.v2.FirmwareSchedule);

    return {
      updateTime: result.onlineUpdate,
      updateType: result.updateFWType,
    };
  },
  /**
   * Clear update schedule of fimrware.
   * @returns {Promise<Object>}
   */
  async deleteSchedule() {
    return qFetch.delete(apiPath.v2.FirmwareSchedule);
  },
  /**
   * Cancel the process of downloading firmware
   * @returns {Promise<Object>}
   */
  async postUpgradeCancel() {
    return qFetch.post(apiPath.v2.FirmwareUpgradeCancel);
  },
  /**
   * Get the current status of upgrading firmware
   * @returns {Promise<Object>}
   */
  async getUpgradeStatus() {
    return qFetch.get(apiPath.v2.FirmwareUpgradeStatus);
  },
  /**
   * Adjust firmware update scheduler config
   * @param {Object} config - scheduler config
   * @returns {Promise<Object>}
   */
  async putWebFirmware(config) {
    return qFetch.put(apiPath.v2.FirmwareWebFirmware, { data: config });
  },
  /**
   * Get the config about whether the device has joined beta program.
   * @returns {Promise<Object>} The beta firmware config
   */
  async getBetaConfig() {
    const { result } = await qFetch.get(apiPath.v2.FirmwareBeta);

    return result;
  },
  /**
   * Update the config about whether the device has joined beta program.
   * @param {Object} config - The new config to update
   * @returns {Promise<Object>} The updated config
   */
  async updateBetaConfig(config) {
    const { result } = await qFetch.put(apiPath.v2.FirmwareBeta, { data: config });

    return result;
  },
  /**
   * Get the QuWAN firmware settings of the device.
   * @returns {Promise<Object>} The QuWAN firmware settings
   */
  async getQuwanSettings() {
    const { result } = await qFetch.get(apiPath.v2.FirmwareQuwanSettings);

    return {
      isBetaJoined: result.enableBetaChannel,
      schedule: {
        enabled: result.schedule.upgradeEnabled,
        day: result.schedule.upgradeDay,
        time: result.schedule.upgradeTime,
      },
    };
  },
  /**
   * Downgrade firmware and restore system with the backup file from device.
   * @param {Object} params
   * @param {string} params.version - The firmware version
   * @param {string} params.firmwareMd5 - The MD5 of the firmware image
   * @param {string} params.fileName - The name of file to restore
   * @returns {Promise<void>}
   */
  async downgradeWithDeviceRestoreFile({ version, firmwareMd5, fileName }) {
    await qFetch.post(`${apiPath.v2.FirmwareDowngrade}?mode=restore_file_device&fwImgMd5=${firmwareMd5}&fwVersion=${version}`, {
      data: {
        backupFileName: fileName,
      },
    });
  },
  /**
   * Downgrade firmware and restore system with the backup file from upload.
   * @param {Object} params
   * @param {string} params.version - The firmware version
   * @param {string} params.firmwareMd5 - The MD5 of the firmware image
   * @param {File} params.file - The file for restore
   * @returns {Promise<void>}
   */
  async downgradeWithLocalRestoreFile({ version, firmwareMd5, file }) {
    const payload = handleRestoreFormData(file);

    await qFetch.upload(`${apiPath.v2.FirmwareDowngrade}?mode=restore_file_upload&fwImgMd5=${firmwareMd5}&fwVersion=${version}`, payload);
  },
  /**
   * Downgrade firmware and reset system.
   * @param {Object} params
   * @param {string} params.version - The firmware version
   * @param {string} params.firmwareMd5 - The MD5 of the firmware image
   * @returns {Promise<void>}
   */
  async downgradeWithReset({ version, firmwareMd5 }) {
    await qFetch.post(`${apiPath.v2.FirmwareDowngrade}?mode=reset&fwImgMd5=${firmwareMd5}&fwVersion=${version}`);
  },
  /**
   * Continue the firmware upgrade.
   * note: this API is used when the backup step failed.
   * @param {Object} payload
   * @param {string} payload.upgradeType - The firmware upgrade type
   * @param {string} payload.fwVersion - The firmware version
   * @param {string} payload.fwImgMd5 - The MD5 of the firmware image
   * @param {boolean} payload.skip - If true, skip the backup step for the firmware upgrade
   * @returns {Promise<void>}
   */
  async continueUpgrade(payload) {
    await qFetch.post(apiPath.v2.FirmwareBackup, { data: payload });
  },
};
