import { cloneDeep } from 'lodash';
import SERVICES from '@/services';
import * as types from '@/store/mutation-types';
import DEVICE_ROLE from '@/enums/DeviceRole';

const state = {
  quwanUri: null,
  accountCenterUri: null,
  myQnapCloudUri: null,
  organizations: null,
  regions: [],
  sites: [],
  connectionStatus: {
    cloudStatus: false,
    vpnStatus: {
      hubs: [],
      edges: [],
    },
  },
  settings: {
    organizationId: null,
    organizationName: '',
    regionId: null,
    regionName: '',
    siteId: null,
    siteName: '',
    name: null,
    role: DEVICE_ROLE.HUB,
    qunmsDeviceId: '',
  },
  editingSettings: {
    organizationId: null,
    organizationName: '',
    regionId: null,
    regionName: '',
    siteId: null,
    siteName: '',
    name: null,
    role: '',
    qunmsDeviceId: '',
  },
  isDeviceOnQunms: false,
  vpnType: 0,
  errorMessages: [],
  isConnectionStatusTimerSuspended: false,
  isCloudDpiDisabled: false,
};

const mutations = {
  [types.SET_CLOUD_SERVICE](state, service) {
    const isAlpha = service.cloudCore.includes('alpha');
    const isChina = service.cloudCore.endsWith('.cn');
    const domainNamePrefix = isAlpha ? 'alpha-' : '';

    if (isChina) {
      state.quwanUri = `https://${domainNamePrefix}quwan.qnap.com.cn`;
      state.accountCenterUri = `https://${domainNamePrefix}account.qnap.com.cn`;
      state.myQnapCloudUri = `https://${domainNamePrefix}myqnapcloud.cn`;
    } else {
      state.quwanUri = `https://${domainNamePrefix}quwan.qnap.com`;
      state.accountCenterUri = `https://${domainNamePrefix}account.qnap.com`;
      state.myQnapCloudUri = `https://${domainNamePrefix}myqnapcloud.com`;
    }
  },
  [types.SET_REGIONS](state, regions) {
    state.regions = regions;
  },
  [types.SET_ORGANIZATIONS](state, organizations) {
    state.organizations = organizations;
  },
  [types.SET_SITES](state, sites) {
    state.sites = sites;
  },
  [types.SET_SETTINGS](state, settings) {
    state.settings = settings;
    state.editingSettings = cloneDeep(settings);
  },
  [types.SET_CONNECTION_STATUS](state, connectionStatus) {
    state.connectionStatus = connectionStatus;
  },
  [types.SET_DEVICE_ON_QUNMS](state, isDeviceOnQunms) {
    state.isDeviceOnQunms = isDeviceOnQunms;
  },
  updateVpnType(state, vpnType) {
    state.vpnType = parseInt(vpnType, 10);
  },
  updateErrorMessages(state, messages) {
    if (typeof messages === 'string') {
      state.errorMessages = [messages];
    } else {
      state.errorMessages = messages;
    }
  },
  modifyEditingSettings(state, { key, value }) {
    state.editingSettings[key] = value;
  },
  setConnectionStatusTimerSuspended(state, isSuspended) {
    state.isConnectionStatusTimerSuspended = isSuspended;
  },
  setIsCloudDpiDisabled(state, isCloudDpiDisabled) {
    state.isCloudDpiDisabled = isCloudDpiDisabled;
  },
};

const actions = {
  async fetchCloudService({ commit }) {
    const service = await SERVICES.QUWAN.getService();

    commit(types.SET_CLOUD_SERVICE, service);
  },
  async createQunmsDevice({ commit, state }) {
    await SERVICES.QUWAN.createQunmsDevice(state.editingSettings);
  },
  async createRegion({ commit, state }, { organizationId, regionSetting }) {
    const region = await SERVICES.QUWAN.createRegion(organizationId, regionSetting);

    commit(types.SET_REGIONS, [...state.regions, region]);
  },
  async fetchConnectionStatus({ commit, state, rootGetters }) {
    try {
      if (!rootGetters['Dashboard/isInternetConnected']) {
        commit(types.SET_CONNECTION_STATUS, {
          ...state.connectionStatus,
          cloudStatus: false,
        });

        return;
      }
      const connectionStatus = await SERVICES.QUWAN.getConnectionStatus();

      commit(types.SET_CONNECTION_STATUS, connectionStatus);
    } catch (error) {
      if (error.result) {
        commit(types.SET_CONNECTION_STATUS, error.result);
      } else {
        commit(types.SET_CONNECTION_STATUS, {
          ...state.connectionStatus,
          cloudStatus: false,
        });
      }
    }
  },
  async fetchOrganizations({ commit, rootGetters }) {
    if (!rootGetters['Dashboard/isInternetConnected']) {
      commit(types.SET_ORGANIZATIONS, []);

      return;
    }

    const organizations = await SERVICES.QUWAN.getOrganizations();

    commit(types.SET_ORGANIZATIONS, organizations);
  },
  async fetchSites({ commit, state }, organizationId) {
    if (organizationId) {
      const sites = await SERVICES.QUWAN.getSites(organizationId);

      commit(types.SET_SITES, sites);
    } else {
      commit(types.SET_SITES, []);
    }
  },
  async fetchQuwanSettings({ commit }) {
    const settings = await SERVICES.QUWAN.getQuwanSettings();

    commit(types.SET_SETTINGS, settings);
  },
  async fetchRegions({ commit, rootGetters }, organizationId) {
    if (organizationId && rootGetters['Dashboard/isInternetConnected']) {
      const regions = await SERVICES.QUWAN.getRegions(organizationId);

      commit(types.SET_REGIONS, regions);
    } else {
      commit(types.SET_REGIONS, []);
    }
  },
  async checkDeviceOnQunms({ commit }) {
    const isDeviceOnQunms = await SERVICES.QUWAN.checkDeviceOnQunms();

    commit(types.SET_DEVICE_ON_QUNMS, isDeviceOnQunms);
  },
  async deleteQunmsDevice({ commit }) {
    await SERVICES.QUWAN.deleteQunmsDevice();
  },
  async fetchDeviceType({ commit, rootGetters }) {
    if (!rootGetters['Dashboard/isInternetConnected']) {
      return;
    }
    const res = await SERVICES.QUWAN.getDeviceType();

    commit('updateVpnType', res.result.vpnType);
  },
  async checkVpnTunnelLimitation({ commit }, device) {
    await SERVICES.QUWAN.checkVpnTunnelLimitation(device);
  },
  async postQuwanNetworkIpSubnetCheck({ commit, rootGetters }, organizationId) {
    if (!rootGetters['Dashboard/isInternetConnected']) {
      return;
    }
    await SERVICES.QUWAN.postQuwanNetworkIpSubnetCheck(organizationId);
  },
  async getCloudDpiDisabledSetting({ commit, rootGetters }) {
    if (!rootGetters['Profile/supportDpiModule']) {
      return;
    }

    const dpiEnabledSetting = await SERVICES.DEBUGMODE.getDpiEnabledSetting();

    commit('setIsCloudDpiDisabled', !dpiEnabledSetting.cloudEnabled || !dpiEnabledSetting.localEnabled);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
