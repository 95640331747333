import { cloneDeep } from 'lodash';
import { Network } from '@/services';
import { isIptvBridge } from '@/common/bridge';
import { IPTV_INTERFACE_NAME_DISPLAY } from '@/common/consts';
import { isLanIp6Enabled } from '@/common/lan';
import { getIpv6PdLength, DEFAULT_DISPLAYED_VALUE } from '@/common/network';
import { isWanIp6Enabled } from '@/common/wan';
import BLOCKED_INTERFACE from '@/enums/BlockedInterface';
import BRIDGE_TYPE from '@/enums/BridgeType';
import LAN_IP6_TYPE from '@/enums/LanIp6Type';
import NCSI_STAT from '@/enums/NcsiStat';
import NETWORK_INTERFACE from '@/enums/NetworkInterface';
import NETWORK_INTERFACE_ACTION from '@/enums/NetworkInterfaceAction';
import NETWORK_INTERFACE_TYPE from '@/enums/NetworkInterfaceType';
import PORT_LINK_STATUS from '@/enums/PortLinkStatus';
import VLAN_IFACE_TYPE from '@/enums/VlanIfaceType';
import WAN_IP4_TYPE from '@/enums/WanIp4Type';
import WAN_IP6_TYPE from '@/enums/WanIp6Type';
import { i18n } from '@/lang';
import Bridge from './Bridge';
import LanConfig from './LanConfig';
import Ports from './Ports';
import Route from './Route';
import VlanIface from './VlanIface';
import WanConfig from './WanConfig';

const INTERFACE_USED_BY_OTHERS_MSG_MAPPING = {
  [NETWORK_INTERFACE_ACTION.DELETE]: 'ID_WAN_LAN_INTERFACE_DELETE_USED_MSG',
  [NETWORK_INTERFACE_ACTION.CHANGE]: 'ID_WAN_LAN_INTERFACE_CHANGE_USED_MSG',
  [NETWORK_INTERFACE_ACTION.DISABLE]: 'ID_WAN_LAN_INTERFACE_DISABLE_USED_MSG',
};

const WAB_USED_AS_LAN_UPLINK_MSG_MAPPING = {
  [NETWORK_INTERFACE_ACTION.DELETE]: 'ID_PORT_WAN_DELETE_LAN_IPV6_UPLINK_USED_MSG',
  [NETWORK_INTERFACE_ACTION.CHANGE]: 'ID_PORT_WAN_CHANGE_LAN_IPV6_UPLINK_USED_MSG',
  [NETWORK_INTERFACE_ACTION.DISABLE]: 'ID_PORT_WAN_DISABLE_LAN_IPV6_UPLINK_USED_MSG',
};

const storeState = {
  availableWanInterfaces: [],
  availableLanInterface: {
    lan: [],
    vlan: [],
    bridge: [],
  },
  networkProfileName: '',
  networkProfileOptions: [],
  externalVpnInterface: {
    bridgeIds: [],
    portNames: [],
    vlanIfIds: [],
  },
};

const storeGetters = {
  currentNetworkProfile(state, getters) {
    const resultProfile = state.networkProfileOptions
      .find((profile) => profile.name === state.networkProfileName);

    return resultProfile || null;
  },
  isOccupiedWanExist(state, getters) {
    if (getters['VlanIface/validWanVlanIfaces'].length > 0) {
      return true;
    }

    return state.Bridge.bridges
      .some((bridge) => bridge.type === BRIDGE_TYPE.WAN && isIptvBridge(bridge));
  },

  getInterfaceOriginalName: (state) => (name) => {
    Object.values(IPTV_INTERFACE_NAME_DISPLAY).forEach((item) => {
      if (name.endsWith(item)) {
        name = name.replace(item, '');
      }
    });

    if (name === 'Guest Wireless Network') {
      return state.VlanIface.guestWirelessVlanIfaceInterfaceName;
    }

    return name;
  },
  allInterfaceNameItems(state, getters) {
    const generateGetNameCallback = (interfaceIdKey) => (item) => ({
      interfaceId: item[interfaceIdKey],
      name: getters.getInterfaceOriginalName(item.name),
    });

    return [
      ...state.Ports.Wan.wans.map(generateGetNameCallback('portName')),
      ...state.Ports.Lan.lans.map(generateGetNameCallback('portName')),
      ...state.VlanIface.vlanIfaces.map(generateGetNameCallback('vlanIfId')),
      ...state.Bridge.bridges.map(generateGetNameCallback('bridgeId')),
    ].filter((item) => item.name);
  },
  getInterfaceDisplayText: (state, getters) => (interfaceId, defaultValue) => {
    const displayText = getters['Ports/getPortDisplayText'](interfaceId)
      || getters['Bridge/getBridgeDisplayText'](interfaceId)
      || getters['VlanIface/getVlanIfaceDisplayText'](interfaceId)
      || (defaultValue ?? interfaceId);

    return displayText;
  },
  isWanInternetConnected: (state, getters) => (interfaceId) => {
    const wanStatus = getters.allWanStatusItems
      .find((item) => item.interfaceId === interfaceId)?.status;
    const basicStatus = getters.getInterfaceBasicStatusItem(interfaceId).status;

    return wanStatus && basicStatus.status === PORT_LINK_STATUS.UP
      && wanStatus.ncsiStat === NCSI_STAT.SUCCESS;
  },

  getWanInterfaceDisplayData: (state, getters) => (interfaceId, wanStatus) => {
    const { status: basicStatus } = getters.getInterfaceBasicStatusItem(interfaceId);
    const data = {
      ...basicStatus,
      ip6Enabled: false,
      ip4Address: wanStatus.ip4Address ? `${wanStatus.ip4Address}/${wanStatus.ip4Prefix}` : '',
      ip6Address: wanStatus.ip6Address ? `${wanStatus.ip6Address}/${wanStatus.ip6Prefix}` : '',
      ip4Gateway: wanStatus.ip4Gateway ?? '',
      ip4DnsServers: wanStatus.ip4DnsServers ?? [],
      ip6DnsServers: wanStatus.ip6DnsServers ?? [],
      ip6Gateway: wanStatus.ip6Gateway ?? '',
      linkRate: wanStatus.linkRate ?? 0,
    };

    if (basicStatus.status !== PORT_LINK_STATUS.UP) {
      if (wanStatus.ip4Type !== WAN_IP4_TYPE.STATIC) {
        data.ip4Address = '';
        data.ip4Gateway = '';
      }

      if (wanStatus.ip6Type !== WAN_IP6_TYPE.STATIC) {
        data.ip6Address = '';
        data.ip6Gateway = '';
      }
    }
    data.ip6Enabled = isWanIp6Enabled(wanStatus);

    if (!data.ip6Enabled && data.ip6Address) {
      data.ip6Address = '';
      data.ip6Gateway = '';
    }

    // default values
    data.ip4Address = data.ip4Address || DEFAULT_DISPLAYED_VALUE;
    data.ip4Gateway = data.ip4Gateway || DEFAULT_DISPLAYED_VALUE;
    data.ip6Address = data.ip6Address || DEFAULT_DISPLAYED_VALUE;
    data.ip6Gateway = data.ip6Gateway || DEFAULT_DISPLAYED_VALUE;
    data.ip6LinkLocalAddress = data.ip6LinkLocalAddress || DEFAULT_DISPLAYED_VALUE;

    return data;
  },
  getLanInterfaceDisplayData: (state, getters) => (interfaceId, lanConfig) => {
    const { status: basicStatus } = getters.getInterfaceBasicStatusItem(interfaceId);
    const data = {
      ...basicStatus,
      ip6Enabled: false,
      ip4Address: lanConfig.ip4Address ? `${lanConfig.ip4Address}/${lanConfig.ip4Prefix}` : '',
      ip6Address: lanConfig.ip6Address ? `${lanConfig.ip6Address}/${lanConfig.ip6Prefix}` : '',
    };

    data.ip6Enabled = isLanIp6Enabled(lanConfig);

    if (lanConfig.ip6Type === LAN_IP6_TYPE.RELAY) {
      delete data.ip6Address;
      delete data.ip6LinkLocalAddress;
    }

    if (!data.ip6Enabled && data.ip6Address) {
      data.ip6Address = '';
    }

    return data;
  },

  allWanConfigItems(state, getters) {
    const wanConfigItems = getters['Ports/Wan/enabledWans']
      .map((config) => ({
        title: getters['Ports/getPortDisplayText'](config.portName),
        type: NETWORK_INTERFACE.WAN,
        interfaceId: config.portName,
        config,
        rootConfig: config,
      }));
    const vlanIfaceWanConfigItems = getters['VlanIface/enabledVlanIfaces']
      .filter((config) => config.type === VLAN_IFACE_TYPE.WAN
        && config.wan.ip4Type !== WAN_IP4_TYPE.DISABLED)
      .map((config) => ({
        title: getters['VlanIface/getVlanIfaceDisplayText'](config.vlanIfId),
        type: NETWORK_INTERFACE.VLAN_IFACE,
        interfaceId: config.vlanIfId,
        config: config.wan,
        rootConfig: config,
      }));
    const bridgeWanConfigItems = getters['Bridge/enabledBridges']
      .filter((config) => config.type === BRIDGE_TYPE.WAN)
      .map((config) => ({
        title: getters['Bridge/getBridgeDisplayText'](config.bridgeId),
        type: NETWORK_INTERFACE.BRIDGE,
        interfaceId: config.bridgeId,
        config: config.wan,
        rootConfig: config,
      }));

    return [...wanConfigItems, ...vlanIfaceWanConfigItems, ...bridgeWanConfigItems];
  },
  handleWanStatusIp: (state) => (status, config) => {
    status = cloneDeep(status);

    if (config) {
      if (config.ip4Type === WAN_IP4_TYPE.STATIC && !status.ip4Address) {
        status.ip4Address = config.ip4Address;
        status.ip4Prefix = config.ip4Prefix;
      }

      if (config.ip6Type === WAN_IP6_TYPE.STATIC && !status.ip6Address) {
        status.ip6Address = config.ip6Address;
        status.ip6Prefix = config.ip6Prefix;
      }
    }

    return status;
  },
  allWanStatusItems(state, getters) {
    const wanStatusItems = getters['Ports/Wan/enabledWansStatus']
      .map((wanStatus) => {
        const config = state.Ports.Wan.wans.find((item) => item.portName === wanStatus.portName);

        return {
          title: getters['Ports/getPortDisplayText'](wanStatus.portName),
          name: config?.name || '',
          type: NETWORK_INTERFACE.WAN,
          interfaceId: wanStatus.portName,
          status: getters.handleWanStatusIp(wanStatus, config),
        };
      });
    const vlanIfaceWanStatusItems = getters['VlanIface/enabledVlanIfacesStatus']
      .filter((vlanIfaceStatus) => vlanIfaceStatus.type === VLAN_IFACE_TYPE.WAN
        && vlanIfaceStatus.wan
        && vlanIfaceStatus.wan.ip4Type !== WAN_IP4_TYPE.DISABLED)
      .map((vlanIfaceStatus) => {
        const vlanIfaceConfig = state.VlanIface.vlanIfaces
          .find((item) => item.vlanIfId === vlanIfaceStatus.vlanIfId);
        const config = vlanIfaceConfig?.wan;
        const status = getters.handleWanStatusIp(vlanIfaceStatus.wan, config);

        return {
          title: getters['VlanIface/getVlanIfaceDisplayText'](vlanIfaceStatus.vlanIfId),
          name: config?.name || '',
          type: NETWORK_INTERFACE.VLAN_IFACE,
          interfaceId: vlanIfaceStatus.vlanIfId,
          status,
        };
      });
    const bridgeWanStatusItems = getters['Bridge/enabledBridgesStatus']
      .filter((bridgeStatus) => bridgeStatus.type === BRIDGE_TYPE.WAN)
      .map((bridgeStatus) => {
        const bridgeConfig = state.Bridge.bridges
          .find((item) => item.bridgeId === bridgeStatus.bridgeId);
        const config = bridgeConfig?.wan;
        const status = getters.handleWanStatusIp(bridgeStatus.wan, config);

        return {
          title: getters['Bridge/getBridgeDisplayText'](bridgeStatus.bridgeId),
          name: config?.name || '',
          type: NETWORK_INTERFACE.BRIDGE,
          interfaceId: bridgeStatus.bridgeId,
          status,
        };
      });

    return [...wanStatusItems, ...vlanIfaceWanStatusItems, ...bridgeWanStatusItems];
  },
  allIp6WanStatusItems(state, getters) {
    return getters.allWanStatusItems.filter((item) => isWanIp6Enabled(item.status));
  },
  allIp4WanSubnets(state, getters) {
    return getters.allWanStatusItems
      .map((item) => ({
        title: item.title,
        ip4Address: item.status.ip4Address,
        ip4Prefix: item.status.ip4Prefix,
        type: item.type,
        interfaceId: item.interfaceId,
      }))
      .filter((item) => item.ip4Address && item.ip4Prefix);
  },
  allIp6WanSubnets(state, getters) {
    return getters.allIp6WanStatusItems
      .map((item) => ({
        title: item.title,
        ip6Address: item.status.ip6Address,
        ip6Prefix: item.status.ip6Prefix,
        type: item.type,
        interfaceId: item.interfaceId,
      }))
      .filter((item) => item.ip6Address && item.ip6Prefix);
  },
  allLanConfigItems(state, getters, rootState, rootGetters) {
    const lanPortConfigs = getters['Ports/Lan/enabledLans']
      .map((lan) => ({
        title: getters['Ports/getPortDisplayText'](lan.portName),
        type: NETWORK_INTERFACE.LAN,
        interfaceId: lan.portName,
        config: lan,
      }));
    const vlanIfaceLanConfigs = getters['VlanIface/enabledVlanIfaces']
      .filter((vlanIface) => vlanIface.type === VLAN_IFACE_TYPE.LAN)
      .map((vlanIface) => ({
        title: getters['VlanIface/getVlanIfaceDisplayText'](vlanIface.vlanIfId),
        type: NETWORK_INTERFACE.VLAN_IFACE,
        interfaceId: vlanIface.vlanIfId,
        config: vlanIface.lan,
        rootConfig: vlanIface,
      }));
    const bridgeConfigs = getters['Bridge/enabledBridges']
      .filter((bridge) => bridge.type === BRIDGE_TYPE.LAN)
      .map((bridge) => ({
        title: getters['Bridge/getBridgeDisplayText'](bridge.bridgeId),
        type: NETWORK_INTERFACE.BRIDGE,
        interfaceId: bridge.bridgeId,
        config: bridge.lan,
        rootConfig: bridge,
      }));

    return [...lanPortConfigs, ...vlanIfaceLanConfigs, ...bridgeConfigs];
  },
  allIp6LanConfigItems(state, getters) {
    return getters.allLanConfigItems.filter((item) => isLanIp6Enabled(item.config));
  },
  allIp4LanSubnets(state, getters) {
    return getters.allLanConfigItems
      .map((item) => ({
        title: item.title,
        type: item.type,
        interfaceId: item.interfaceId,
        ip4Address: item.config.ip4Address,
        ip4Prefix: item.config.ip4Prefix,
      }))
      .filter((item) => item.ip4Address && item.ip4Prefix);
  },
  allIp6LanSubnets(state, getters) {
    return getters.allIp6LanConfigItems
      .map((item) => ({
        title: item.title,
        type: item.type,
        interfaceId: item.interfaceId,
        ip6Address: item.config.ip6Address,
        ip6Prefix: item.config.ip6Prefix,
      }))
      .filter((item) => item.ip6Address && item.ip6Prefix);
  },
  allInterfaceBasicStatusItems(state, getters) {
    const handleStatus = (statusData) => ({
      throughputRx: statusData.throughputRx,
      throughputTx: statusData.throughputTx,
      throughputPktRx: statusData.throughputPktRx,
      throughputPktTx: statusData.throughputPktTx,
      mac: statusData.mac ? statusData.mac.toUpperCase() : '--',
      ip6LinkLocalAddress: statusData.ip6LinkLocalAddress,
      status: statusData.status || PORT_LINK_STATUS.DOWN,
    });
    const wanPortsStatus = state.Ports.Wan.wansStatus
      .map((item) => ({
        title: getters['Ports/getPortDisplayText'](item.portName),
        type: NETWORK_INTERFACE.WAN,
        interfaceId: item.portName,
        status: handleStatus(item),
      }));
    const lanPortsStatus = state.Ports.Lan.lansStatus
      .map((item) => ({
        title: getters['Ports/getPortDisplayText'](item.portName),
        type: NETWORK_INTERFACE.LAN,
        interfaceId: item.portName,
        status: handleStatus(item),
      }));
    const vlanIfacesStatus = state.VlanIface.vlanIfacesStatus
      .map((item) => {
        const statusItem = {
          title: getters['VlanIface/getVlanIfaceDisplayText'](item.vlanIfId),
          type: NETWORK_INTERFACE.VLAN_IFACE,
          interfaceId: item.vlanIfId,
          status: handleStatus(item),
        };
        const vlanIfaceConfig = state.VlanIface.vlanIfaces
          .find((iface) => iface.vlanIfId === item.vlanIfId);

        if (vlanIfaceConfig) {
          const linked = [...vlanIfaceConfig.tags, ...vlanIfaceConfig.untags]
            .some((iface) => state.Ports.OccupiedPort.occupiedPortsStatus
              .some((portStatus) => portStatus.portName === iface
                && portStatus.status === PORT_LINK_STATUS.UP));

          statusItem.status.status = linked ? PORT_LINK_STATUS.UP : PORT_LINK_STATUS.DOWN;
        }

        return statusItem;
      });
    const bridgesStatus = state.Bridge.bridgesStatus
      .map((item) => {
        const statusItem = {
          title: getters['Bridge/getBridgeDisplayText'](item.bridgeId),
          type: NETWORK_INTERFACE.BRIDGE,
          interfaceId: item.bridgeId,
          status: handleStatus(item),
        };
        const bridgeConfig = state.Bridge.bridges
          .find((bridge) => bridge.bridgeId === item.bridgeId);

        if (bridgeConfig) {
          const linked = bridgeConfig.interfaces
            .some((iface) => state.Ports.OccupiedPort.occupiedPortsStatus
              .some((portStatus) => portStatus.portName === iface
                && portStatus.status === PORT_LINK_STATUS.UP));

          statusItem.status.status = linked ? PORT_LINK_STATUS.UP : PORT_LINK_STATUS.DOWN;
        }

        return statusItem;
      });

    return [
      ...wanPortsStatus,
      ...lanPortsStatus,
      ...vlanIfacesStatus,
      ...bridgesStatus,
    ];
  },
  getInterfaceBasicStatusItem: (state, getters) => (interfaceId) => {
    const statusItem = getters.allInterfaceBasicStatusItems
      .find((item) => item.interfaceId === interfaceId);

    // default item with empty type
    return statusItem ?? {
      title: '',
      type: '',
      interfaceId,
      status: {
        status: PORT_LINK_STATUS.DOWN,
        mac: '--',
        throughputRx: 0,
        throughputTx: 0,
        throughputPktRx: 0,
        throughputPktTx: 0,
        ip6LinkLocalAddress: '',
      },
    };
  },
  allIp6PdItems(state, getters) {
    return getters['Ports/Wan/enabledWansStatus']
      .filter((wanStatus) => isWanIp6Enabled(wanStatus) && wanStatus.ip6PdEnabled)
      .map((wanStatus) => ({
        title: getters['Ports/getPortDisplayText'](wanStatus.portName),
        ip6PdInfos: wanStatus.ip6PdInfo,
        type: NETWORK_INTERFACE.WAN,
        ip6UplinkId: wanStatus.portName,
        interfaceId: wanStatus.portName,
      }));
  },
  allIp6PdChangedLanConfigItems(state, getters) {
    const { allIp6PdItems } = getters;

    return getters.allIp6LanConfigItems.filter((lanConfigItem) => {
      if (!lanConfigItem.ip6PdPrefix) {
        return false;
      }
      const pdItem = allIp6PdItems.find((item) => item.ip6UplinkId === lanConfigItem.ip6Uplink);
      const pdInfo = pdItem?.ip6PdInfos[0];

      if (pdInfo && pdInfo.prefix && lanConfigItem.ip6PdPrefix) {
        return getIpv6PdLength(lanConfigItem.ip6PdPrefix) !== getIpv6PdLength(pdInfo.prefix);
      }

      return false;
    });
  },
  getInterfaceDisplayTextByName: (state, getters) => (interfaceName) => {
    const findHandler = (item) => getters.getInterfaceOriginalName(item.name) === interfaceName;
    const port = [...state.Ports.Wan.wans, ...state.Ports.Lan.lans].find(findHandler);

    if (port) {
      return getters['Ports/getPortDisplayText'](port.portName);
    }
    const vlanIface = state.VlanIface.vlanIfaces.find(findHandler);

    if (vlanIface) {
      return getters['VlanIface/getVlanIfaceDisplayText'](vlanIface.vlanIfId);
    }
    const bridge = state.Bridge.bridges.find(findHandler);

    if (bridge) {
      return getters['Bridge/getBridgeDisplayText'](bridge.bridgeId);
    }

    return interfaceName;
  },

  checkWanUsedAsLanIp6Uplink: (state, getters) => (interfaceId, action = 'disable') => {
    const useWanIp6UplinkLanTitles = getters.allIp6LanConfigItems
      .filter((item) => item.config.ip6Uplink === interfaceId)
      .map((item) => item.title);

    if (useWanIp6UplinkLanTitles.length > 0) {
      const message = WAB_USED_AS_LAN_UPLINK_MSG_MAPPING[action];

      return i18n.t(message, {
        interfaces: useWanIp6UplinkLanTitles.join(', '),
      });
    }

    return true;
  },

  getInterfaceUsedVapItems: (state, getters, rootState) => (interfaceId) => {
    const vapItems = [];

    rootState.Wireless.Vap.vapGroups
      .forEach((vapGroup) => {
        vapGroup.vaps.forEach((vap) => {
          if (vap.interfaceId === interfaceId && vap.enabled) {
            vapItems.push({
              id: `${vapGroup.vapGroupIdx}_${vap.band}`,
              config: vap,
              group: vapGroup,
            });
          }
        });
      });

    return vapItems;
  },
  checkInterfaceUsedByVap: (state, getters) => (interfaceId) => {
    const usedByVap = getters.getInterfaceUsedVapItems(interfaceId).length > 0;

    return usedByVap;
  },
  checkInterfaceNotUsedByOthers: (state, getters, rootState, rootGetters) => ({
    type,
    interfaceId,
    action = NETWORK_INTERFACE_ACTION.CHANGE,
  }) => {
    if (!interfaceId) {
      return true;
    }

    if (type === NETWORK_INTERFACE_TYPE.WAN) {
      const used = getters.checkWanUsedAsLanIp6Uplink(interfaceId, action);

      if (used !== true) {
        return used;
      }
    }

    const isPort = getters.currentNetworkProfile.portNames.includes(interfaceId);
    // TODO: use backend API to handle port internal ID
    const internalId = isPort ? `swdev${interfaceId}` : interfaceId;
    const titles = [];
    const check = (value, message) => {
      if (value) {
        titles.push(message);
      }
    };

    check(
      rootGetters['Network/Route/ip4StaticRoutes']
        .some((route) => [route.nextHopPortName, route.nextHopLan].includes(interfaceId)),
      `${i18n.t('ID_NETWORK_TAG_IPV4')} ${i18n.t('ID_SR_STATIC_ROUTE')}`,
    );
    check(
      rootGetters['Network/Route/ip6StaticRoutes']
        .some((route) => [route.nextHopPortName, route.nextHopLan].includes(interfaceId)),
      `${i18n.t('ID_NETWORK_TAG_IPV6')} ${i18n.t('ID_SR_STATIC_ROUTE')}`,
    );
    check(
      rootState.Firewall.rules
        .some((rule) => rule.src_type === 'interface' && rule.src_metadata.hw_interface === internalId),
      i18n.t('ID_MENU_FIREWALL'),
    );
    check(
      rootState.BlockedClient.blockedClients
        .filter((client) => client.blockIface !== BLOCKED_INTERFACE.ALL)
        .some((client) => client.blockIface.split(',').includes(internalId)),
      i18n.t('ID_CLIENT_BL'),
    );
    check(
      getters.checkInterfaceUsedByVap(interfaceId),
      i18n.t('ID_MENU_WIRELESS'),
    );
    const { iptvSetting } = rootState.Iptv;
    const { iptvEnums } = rootState.Iptv;

    check(
      iptvSetting.mode === iptvEnums.mode.igmpProxy
      && (iptvSetting.wanIface === interfaceId || iptvSetting.igmpDownstreamIface === internalId),
      `${i18n.t('ID_IPTV_IPTV_LABEL')} - ${i18n.t('ID_IPTV_MODE_IGMP_PROXY')}`,
    );
    check(
      iptvSetting.mode === iptvEnums.mode.ispProfile
      && iptvSetting.ispProfileName === iptvEnums.ispProfileName.movistarIgmp
      && (iptvSetting.wanIface === interfaceId || iptvSetting.igmpDownstreamIface === internalId),
      `${i18n.t('ID_IPTV_IPTV_LABEL')} - ${i18n.t('ID_IPTV_MODE_ISP_PROFILE_MOVISTAR')}`,
    );

    if (titles.length > 0) {
      const typeText = {
        [NETWORK_INTERFACE_TYPE.WAN]: 'ID_MENU_WAN',
        [NETWORK_INTERFACE_TYPE.LAN]: 'ID_MENU_LAN',
      }[type] ?? '';

      const message = INTERFACE_USED_BY_OTHERS_MSG_MAPPING[action];

      return i18n.t(message, {
        type: i18n.t(typeText),
        name: getters.getInterfaceDisplayText(interfaceId),
        features: titles.join(', '),
      });
    }

    return true;
  },

  checkLanInterfaceNotLast: (state, getters) => (
    interfaceId,
    action = NETWORK_INTERFACE_ACTION.CHANGE,
  ) => {
    const containsLanPortConfigItems = getters.allLanConfigItems.filter(({ type, rootConfig }) => {
      if (type === NETWORK_INTERFACE.BRIDGE) {
        return rootConfig.interfaces.length > 0;
      }

      if (type === NETWORK_INTERFACE.VLAN_IFACE) {
        return rootConfig.tags.length + rootConfig.untags.length > 0;
      }

      return true;
    });

    if (containsLanPortConfigItems.length > 1) {
      return true;
    }

    if (containsLanPortConfigItems.length === 0) {
      return false;
    }
    const lanConfigItem = containsLanPortConfigItems[0];

    if (lanConfigItem.interfaceId === interfaceId) {
      if (action === NETWORK_INTERFACE_ACTION.DELETE
          && (lanConfigItem.type === NETWORK_INTERFACE.BRIDGE
            || lanConfigItem.type === NETWORK_INTERFACE.VLAN_IFACE)) {
        const members = lanConfigItem.type === NETWORK_INTERFACE.BRIDGE
          ? lanConfigItem.rootConfig.interfaces.slice()
          : [...lanConfigItem.rootConfig.tags, ...lanConfigItem.rootConfig.untags];

        members.sort((item1, item2) => parseInt(item1, 10) - parseInt(item2, 10));
        const firstLanPortName = members[0];

        return i18n.t('ID_LAN_INTERFACE_IS_LAST_LAN_PORT_AUTO_ENABLE_MSG', {
          port: getters['Ports/getPortNameText'](firstLanPortName),
          ip: `${lanConfigItem.config.ip4Address}/${lanConfigItem.config.ip4Prefix}`,
        });
      }

      return i18n.t('ID_LAN_INTERFACE_AT_LEAST_ONE_MSG');
    }

    return true;
  },
  isInterfaceUsedByExternalVpn: (state) => (interfaceId) => {
    const { portNames, bridgeIds, vlanIfIds } = state.externalVpnInterface;

    return portNames.includes(interfaceId)
      || bridgeIds.includes(interfaceId)
      || vlanIfIds.includes(interfaceId);
  },
};

const actions = {
  async getAllInterfaceConfigs({ dispatch }) {
    await Promise.all([
      dispatch('Ports/getPorts'),
      dispatch('VlanIface/getVlanIfaces'),
      dispatch('Bridge/getBridges'),
    ]);
  },
  async getAllInterfaceStatus({ dispatch }) {
    await Promise.all([
      dispatch('Ports/getPortsStatus'),
      dispatch('VlanIface/getVlanIfacesStatus'),
      dispatch('Bridge/getBridgesStatus'),
    ]);
  },
  async postReconnectPppoe({ state }, wanInterfaceId) {
    await Network.postReconnectPppoe(wanInterfaceId);
  },

  async fetchOtherFeatures({ dispatch }) {
    await Promise.all([
      dispatch('VPN/fetchL2tpConfig', undefined, { root: true }),
      dispatch('VPN/fetchOpenvpnConfig', undefined, { root: true }),
      dispatch('VPN/fetchQbeltConfig', undefined, { root: true }),

      dispatch('BlockedClient/getBlockClients', undefined, { root: true }),
      dispatch('Firewall/getFirewall', undefined, { root: true }),
      dispatch('Network/Route/getRoute', undefined, { root: true }),
      dispatch('Wireless/Vap/getVaps', undefined, { root: true }),
      dispatch('Iptv/getIptvSetting', undefined, { root: true }),
    ]);
  },
  async getAvailableWanInterfaces({ commit }) {
    const availableWanInterfaces = await Network.getAvailableWanInterfaces();

    commit('setAvailableWanInterfaces', availableWanInterfaces);
  },
  async getAvailableLanInterfaces({ commit }) {
    const availableLanInterface = await Network.getAvailableLanInterfaces();

    commit('setAvailableLanInterface', availableLanInterface);
  },
  async getExternalVpnInterface({ commit }) {
    const externalVpnInterface = await Network.getExternalVpnInterface();

    commit('setExternalVpnInterface', externalVpnInterface);
  },

  async getNetworkProfiles({ commit }) {
    const networkProfiles = await Network.getProfiles();

    commit('initNetworkProfile', networkProfiles);
  },
};

const mutations = {
  setAvailableWanInterfaces(state, data) {
    state.availableWanInterfaces = data.port.concat(data.vlan, data.bridge);
  },
  setAvailableLanInterface(state, data) {
    state.availableLanInterface = {
      lan: data.lan ?? [],
      vlan: data.vlan ?? [],
      bridge: data.bridge ?? [],
    };
  },
  setExternalVpnInterface(state, externalVpnInterface) {
    state.externalVpnInterface = externalVpnInterface;
  },

  initNetworkProfile(state, profile) {
    state.networkProfileOptions = profile.profiles;
    state.networkProfileName = profile.current;
  },
};

const modules = {
  Ports,
  WanConfig,
  LanConfig,
  VlanIface,
  Bridge,
  Route,
};

export default {
  namespaced: true,
  state: storeState,
  getters: storeGetters,
  actions,
  mutations,
  modules,
};
