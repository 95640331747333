<template>
  <div
    class="df ai-start"
    :class="{
      'fz-14': size === 'sm',
      'tip-message-backdrop': backdrop,
      [`tip-message-backdrop-${type}`]: backdrop,
    }"
  >
    <div
      class="flex-shrink-0 mr-10-px"
      :class="icon"
    />
    <div
      class="ws-pl flex-fluid"
      :class="textColor"
    >
      <slot>{{ innerText }}</slot>
    </div>
  </div>
</template>

<script>
import { convertLinebreak } from '@/common/utilities';

const TEXT_COLOR_CLASS_MAPPING = {
  remind: 'c-secondary',
  warning: 'c-warning',
  error: 'c-error',
};

const ICON_CLASS_MAPPING = {
  remind: 'ic_remind',
  warning: 'ic-sys-warning',
  error: 'ic-sys-error',
};

export default {
  name: 'TipMessage',
  props: {
    type: {
      type: String,
      default: 'remind',
      validator(type) {
        return ['remind', 'warning', 'error'].includes(type);
      },
    },
    text: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['md', 'sm'].includes(value);
      },
    },
    backdrop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return ICON_CLASS_MAPPING[this.type];
    },
    textColor() {
      return TEXT_COLOR_CLASS_MAPPING[this.type];
    },
    innerText() {
      return convertLinebreak(this.$t(this.text));
    },
  },
};
</script>

<style lang="scss" scoped>

.tip-message-backdrop {
  padding: rem(10px 10px);
  border-radius: rem(8px);

  &.tip-message-backdrop-error {
    background-color: theme-color('error', 0.07);
  }
  &.tip-message-backdrop-warning {
    background-color: theme-color('warning-light');
  }
}
</style>
