<template>
  <ModalBase
    :id="id"
    :visible="visible"
    :title="title"
    :force="force"
    size="alert"
    header-theme="white"
    @hide="$emit('hide')"
    @cancel="$emit('cancel')"
    @after-hide="$emit('after-hide')"
  >
    <div class="pl-15-px pr-10-px pt-10-px">
      <slot>
        <div
          v-if="message || $slots.message"
          class="df pr-25-px"
        >
          <div
            :class="imageClass"
            class="w-32-px h-32-px mr-20-px flex-shrink-0"
          />
          <div>
            <slot
              name="message"
              :message="innerMessage"
            >
              <div
                v-if="Array.isArray(innerMessage)"
                class="space-y-15-px"
              >
                <div
                  v-for="item in innerMessage"
                  :key="item"
                  class="ws-pl"
                >
                  {{ item }}
                </div>
              </div>
              <span
                v-else
                class="ws-pl"
              >
                {{ innerMessage }}
              </span>
            </slot>
            <div
              v-if="$slots['message-extra']"
              class="pt-25-px"
            >
              <slot name="message-extra" />
            </div>
          </div>
        </div>
      </slot>
    </div>

    <template #modal-footer-wrapper="{ cancel }">
      <div class="df ai-center jc-end ptb-20-px plr-25-px">
        <slot
          name="modal-footer"
          :cancel="cancel"
        >
          <ButtonText
            :label="confirmLabel"
            :disabled="confirmDisabled"
            class="mr-10-px"
            theme="secondary"
            @click="confirm"
          />
          <ButtonText
            v-if="showCancelButton"
            :label="cancelLabel"
            theme="secondary"
            @click="cancel"
          />
        </slot>
      </div>
    </template>
  </ModalBase>
</template>

<script>
import NOTIFY_LEVEL from '@/enums/NotifyLevel';
import ButtonText from '@/components/Button/ButtonText.vue';
import ModalBase from './ModalBase.vue';

const IMAGE_CLASS_MAPPING = {
  [NOTIFY_LEVEL.ERROR]: 'ic-sys-error',
  [NOTIFY_LEVEL.WARN]: 'ic-sys-warning',
  [NOTIFY_LEVEL.INFO]: 'ic-sys-info',
  [NOTIFY_LEVEL.HELP]: 'ic-sys-help',
  [NOTIFY_LEVEL.SUCCESS]: 'ic_ok',
};

export default {
  name: 'ModalNotifyBase',
  components: {
    ButtonText,
    ModalBase,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    force: {
      type: Boolean,
      default: false,
    },
    message: {
      type: [String, Array],
      default: '',
      validator(value) {
        return typeof value === 'string' || (Array.isArray(value) && value.every((item) => typeof item === 'string'));
      },
    },
    type: {
      type: String,
      default: NOTIFY_LEVEL.INFO,
      validator(type) {
        return [
          NOTIFY_LEVEL.INFO,
          NOTIFY_LEVEL.HELP,
          NOTIFY_LEVEL.WARN,
          NOTIFY_LEVEL.ERROR,
          NOTIFY_LEVEL.SUCCESS,
        ].includes(type);
      },
    },
    showCancelButton: {
      type: Boolean,
      default: false,
    },
    confirmLabel: {
      type: String,
      default: 'ID_OK',
    },
    cancelLabel: {
      type: String,
      default: 'ID_CANCEL',
    },
    confirmDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    imageClass() {
      return IMAGE_CLASS_MAPPING[this.type];
    },
    innerMessage() {
      if (Array.isArray(this.message)) {
        const msgs = this.message.map((item) => this.$t(item, { model: this.$profile.name }));

        return [...new Set(msgs)]; // filter out duplicate messages
      }

      return this.$t(this.message, { model: this.$profile.name });
    },
  },
  methods: {
    closeModal() {
      if (typeof this.id === 'string') {
        this.$modal.hide(this.id);
      }
      this.$emit('cancel');
    },
    confirm() {
      this.$emit('confirm');

      if (!this.showCancelButton) {
        this.closeModal();
      }
    },
  },
};
</script>
