
<ModalNotifyBase
  :id="id"
  :visible="visible"
  :title="title"
  :type="type"
  :message="message"
  :confirm-label="confirmLabel"
  :cancel-label="cancelLabel"
  :confirm-disabled="confirmDisabled"
  :force="force"
  show-cancel-button
  @confirm="$emit('confirm')"
  @cancel="$emit('cancel')"
  @hide="$emit('hide')"
  @after-hide="$emit('after-hide')"
>
  <template #default>
    <slot />
  </template>
  <template #message>
    <slot name="message" />
  </template>
  <template #message-extra>
    <slot name="message-extra" />
  </template>
  <template #modal-footer="{ cancel }">
    <slot
      name="modal-footer"
      :cancel="cancel"
    />
  </template>
</ModalNotifyBase>
