import { sortBy } from 'lodash';
import SERVICES, { Initialize, System, Client } from '@/services';
import ERROR_CODE from '@/services/utilities/errorCode';
import { clearAuthorization } from '@/common/utilities';
import { LANGUAGE_CODE } from '@/lang';
import {
  EDIT_INITIAL_SETTING,
  SET_ACCOUNT,
  SET_DISPLAY_LANGUAGE,
  SET_INIT_MIRO_LIST,
  SET_INIT_PATH_NAME,
  SET_MACHINE_INFO,
  SET_IS_DEVICE_REBOOTING,
  SET_REDIRECTING,
  SET_REGION,
  SET_SATELLITE_LIST,
  SET_STEP,
} from '../mutation-types';

// initial state
const state = {
  isQid: false,
  IsLocalAccountDefaultCred: false,
  IsFirstLoginQID: false,
  accountInfo: {
    username: '',
  },
  userCountryCode: '',
  displayLanguage: LANGUAGE_CODE.ENG,
  machineInfo: {
    language: LANGUAGE_CODE.ENG,
    is_initialized: true,
    isQidBind: null,
    country_code: 'US',
    region: '',
    device_name: '',
    hostname: '',
    model: '',
    is_wan_first_set: null,
    is_qpkg: null,
  },
  initPathName: '', // store the path name of router when model name of profile has not been initialized

  // miro
  satelliteList: [],
  selectedNodeSsid: '',
  miroList: [],

  // QuRouter
  currentStepId: 0,
  initialConsts: {
    stepIds: {
      startPanel: 0,
      quwanPanel: 1,
      localAccount: 2,
      domain: 3,
      wan: 4,
      countryCode: 5,
      location: 6,
      wireless: 7,
      nodePanel: 8,
      nodeList: 9,
      nodeLoaction: 10,
      firmware: 11,
    },
    invalidTokenErrorCodes: [
      ERROR_CODE.LOCAL_ACCOUNT_LOGIN_INVALID,
      ERROR_CODE.TOKEN_EXPIRED,
      ERROR_CODE.TOKEN_INVALID,
    ],
  },
  isDeviceRebooting: false,
  redirecting: false,
};

function syncFontLanguage(oldLang, lang) {
  document.body.classList.remove(`app-lang--${oldLang}`);
  document.body.classList.add(`app-lang--${lang}`);
}

// mutations
const mutations = {
  [EDIT_INITIAL_SETTING](state, payload) {
    Object.keys(payload).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(state, key)) {
        state[key] = payload[key];
      }
    });
  },
  [SET_MACHINE_INFO](state, payload) {
    state.machineInfo = {
      language: payload.language,
      is_initialized: payload.isInitialized,
      isQidBind: payload.isQidBind,
      country_code: payload.countryCode,
      region: payload.region,
      device_name: payload.deviceName,
      hostname: payload.hostname,
      model: payload.model,
      is_wan_first_set: payload.isWanFirstSet,
      is_qpkg: payload.isQPKG,
    };
    syncFontLanguage(state.displayLanguage, payload.language);
    state.displayLanguage = payload.language;
  },
  [SET_REGION](state, region) {
    state.machineInfo.region = region;
  },
  [SET_ACCOUNT](state, account) {
    state.IsLocalAccountDefaultCred = account.IsLocalAccountDefaultCred;
    state.isQid = account.isQid;
    state.accountInfo.username = account.username;
  },
  [SET_DISPLAY_LANGUAGE](state, payload) {
    syncFontLanguage(state.displayLanguage, payload.lang);
    state.displayLanguage = payload.lang;
  },
  [SET_INIT_PATH_NAME](state, pathName) {
    state.initPathName = pathName;
  },

  // miro
  [SET_SATELLITE_LIST](state, payload) {
    if (payload) {
      state.satelliteList = sortBy(payload, [(node) => (node.role !== 'init')]);
    }
  },
  [SET_INIT_MIRO_LIST](state, payload) {
    if (payload) {
      state.miroList = payload;
    }
  },

  // QuRouter
  [SET_STEP](state, stepId) {
    state.currentStepId = stepId;
  },
  [SET_IS_DEVICE_REBOOTING](state, value) {
    state.isDeviceRebooting = value;
  },
  [SET_REDIRECTING](state, value) {
    state.redirecting = value;
  },
};

// actions
const actions = {
  async getInitStatus({ commit }) {
    const config = await Initialize.getMachineInfo();

    commit(SET_MACHINE_INFO, config.result);
  },
  async fetchAccountInfo({ commit }) {
    const account = await SERVICES.INITIAL.getAccountInfo();

    commit(SET_ACCOUNT, account);
  },
  async getRegionSetting({ commit }) {
    const res = await System.getRegionSetting();

    commit(SET_REGION, res.result.region);
  },
  async patchLanguage({ commit }, payload) {
    await System.updateUiSetting('language', payload.language);
  },

  // miro
  async postInitSatellite({ commit }, payload) {
    const res = await SERVICES.MIRO.postSatellite(payload);

    return res;
  },
  async getInitSatellite({ commit }) {
    const config = await SERVICES.MIRO.getSatellite();

    commit(SET_SATELLITE_LIST, config.result);
  },
  async getInitMiroList({ commit }) {
    const config = await SERVICES.MIRO.getMiroListAll();

    commit(SET_INIT_MIRO_LIST, config.result);
  },
  async getQtsRedirect({ commit }) {
    const config = await SERVICES.INITIAL.getQtsRedirect();

    return config;
  },

  // QuRouter
  async goNextStep({
    state, rootGetters, commit, dispatch,
  }) {
    let { currentStepId } = state;
    const { stepIds } = state.initialConsts;

    if (!rootGetters['Profile/supportWireless']) {
      const nextStepId = currentStepId + 1;

      // if not support wireless, skip country code setting
      if (nextStepId === stepIds.countryCode) {
        currentStepId += 1;
      }
    }

    if (currentStepId === stepIds.firmware) {
      await dispatch('finishInit');
    } else if (currentStepId === stepIds.quwanPanel) {
      let nextStepId;

      try {
        const res = await Initialize.getPasswordDefault();

        nextStepId = res.result.isDefault ? stepIds.localAccount : stepIds.domain;
      } catch (error) {
        nextStepId = stepIds.localAccount;
      }
      commit(SET_STEP, nextStepId);
    } else if (currentStepId === stepIds.countryCode) {
      const nextStepId = rootGetters['Profile/isHoraSeries'] ? stepIds.firmware : stepIds.location;

      commit(SET_STEP, nextStepId);
    } else if (currentStepId === stepIds.nodeLoaction) {
      commit(SET_STEP, stepIds.nodePanel);
    } else {
      commit(SET_STEP, currentStepId + 1);
    }
  },
  goPreviousStep({ state, commit }) {
    const { currentStepId } = state;

    commit(SET_STEP, currentStepId - 1);
  },
  async skipInit({ commit, dispatch }) {
    const { currentStepId } = state;
    const { stepIds } = state.initialConsts;

    if (currentStepId === stepIds.firmware || currentStepId === stepIds.wan) {
      await dispatch('finishInit');
    } else {
      const nextStepId = stepIds.firmware;

      commit(SET_STEP, nextStepId);
    }
  },
  async finishInit() {
    await Initialize.postFinishInitial();
    await Client.deleteAllOfflineClient();
    await SERVICES.AUTH.logout();
    clearAuthorization();
    window.location.reload();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
