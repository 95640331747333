import NOTIFY_LEVEL from '@/enums/NotifyLevel';

const state = {
  confirm: () => {},
  cancel: () => {},
  visible: false,
  title: '',
  type: NOTIFY_LEVEL.HELP,
  key: 0,
};

const mutations = {
  activate(state, payload) {
    state.confirm = payload.confirm;
    state.cancel = payload.cancel;
    state.confirmLabel = payload.confirmLabel;
    state.cancelLabel = payload.cancelLabel;
    state.title = payload.title;
    state.type = payload.type;
    state.visible = true;
    state.key += 1;
  },
  hide(state) {
    state.visible = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
