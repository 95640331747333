import { mapMutations } from 'vuex';
import { Firmware } from '@/services';
import ERROR_CODE from '@/services/utilities/errorCode';
import { SET_IS_DEVICE_REBOOTING } from '@/store/mutation-types';
import { FIRMWARE_UPGRADE_STATUS } from '@/common/consts';
import FIRMWARE_TYPE from '@/enums/FirmwareType';
import FIRMWARE_UPGRADE_TYPE from '@/enums/FirmwareUpgradeType';
import RebootCountdown from '@/mixins/RebootCountdown';
/**
 * 10012: Unable to upload the firmware file.
 * 10014: Unable to download firmware.
 * 10015: Firmware update unavailable.
 * 10016: The firmware is up-to-date.
 * 10017: Unable to retrieve the firmware update information.
 * 10018: Unable to update the firmware.
 * 10019: The firmware you are trying to apply is older than the current version.
 * 10031: Expired token. Refresh the page.
 * 10032: Invalid token. Refresh the page.
 * 4: Updated firmware.
 */
const FIRMWARE_UPGRADE_STATUS_MESSAGE_MAPPING = {
  10012: 'ID_FIRMWARE_FAIL_UPL',
  10014: 'ID_HORA_INIT_UPGRADE_MSG1',
  10015: 'ID_HORA_INIT_UPGRADE_MSG2',
  10016: 'ID_HORA_INIT_UPGRADE_MSG3',
  10017: 'ID_HORA_INIT_UPGRADE_MSG4',
  10018: 'ID_HORA_INIT_UPGRADE_MSG5',
  [ERROR_CODE.FIRMWARE_OLD_VERSION]: 'ID_FIRMWARE_UPDATE_DESC',
  10031: 'ID_HORA_INIT_UPGRADE_MSG7',
  10032: 'ID_HORA_INIT_UPGRADE_MSG8',
  4: 'ID_HORA_INIT_UPGRADE_MSG6',
};

/**
 * When the backup of firmware upgrade fails, there are many possible reasons.
 * UI uses the same behavior to handle them.
 */
const FIRMWARE_UPGRADE_BACKUP_FAILED_ERROR_CODES = [
  10050,
  10113,
  10117,
  10118,
  10119,
  10120,
];

export default {
  mixins: [RebootCountdown],
  methods: {
    ...mapMutations('Initial', [SET_IS_DEVICE_REBOOTING]),
    ...mapMutations('Firmware', ['startFirmwareUpgrade', 'setRetryBackupInfo']),

    /**
     * Check firmware upgrade status and dynamically change loading text
     * @param {Object} options
     * @param {boolean} [options.showMessage] - if true, show message when not need to reboot
     * @param {boolean} [options.showLoading] - if true, show loading and change loading text when
     *                                          starting to poll testing firmware upgrade status.
     * @param {boolean} [options.downloadSkippable] - if true, hide loading backdrop when download
     * @param {boolean} [options.reloadAfterReboot] - if true, auto reload after reboot
     * @returns {Promise<number>} The firmware upgrade status when finish
     */
    async checkFirmwareUpgradeStatus({
      showMessage = false,
      showLoading = true,
      downloadSkippable = false,
      reloadAfterReboot = true,
    } = {}) {
      this[SET_IS_DEVICE_REBOOTING](true);

      let upgradeStatus = 0;
      const getFwUpdatingStatus = async () => {
        try {
          const config = await Firmware.getUpgradeStatus();

          upgradeStatus = config.result.fwUpgradeStatus;
        } catch (error) {
          // ignore error because status will periodically update
        }
      };

      let finished = false;
      let lastDetail = '';
      const retry = async (forceVisible = true) => {
        await getFwUpdatingStatus();
        let showBackDrop = true;

        if (upgradeStatus === FIRMWARE_UPGRADE_STATUS.NOT_UPGRADING) {
          finished = true;
        } else if (upgradeStatus === FIRMWARE_UPGRADE_STATUS.UPGRADING) {
          lastDetail = 'ID_FIRMWARE_UPGING';
        } else if (upgradeStatus === FIRMWARE_UPGRADE_STATUS.DOWNLOADING) {
          lastDetail = 'ID_FIRMWARE_DLING';

          if (downloadSkippable) {
            // user can click skip when downloading
            showBackDrop = false;
          }
        } else if (upgradeStatus === FIRMWARE_UPGRADE_STATUS.CHECKING_FILE) {
          lastDetail = 'ID_FIRMWARE_CKIMG';
        } else if (upgradeStatus === FIRMWARE_UPGRADE_STATUS.READY_TO_REBOOT) {
          finished = true;
        } else if (upgradeStatus === FIRMWARE_UPGRADE_STATUS.UPGRADE_SUCCESS
          || upgradeStatus > 10000) {
          finished = true;
        }

        if (finished) {
          return;
        }

        if (forceVisible) {
          // ensure loading not be hide
          this.$loading.setVisible(true);
        }
        this.$loading.setText('ID_MIROS_WAITING_AUTO_UPDATE');
        this.$loading.setDetail(lastDetail);
        this.$loading.setShowBackdrop(showBackDrop);
      };

      await retry(false);

      if (!finished) {
        let currentInterval;

        await new Promise((resolve) => {
          if (showLoading) {
            this.$loading.show({
              text: 'ID_MIROS_WAITING_AUTO_UPDATE',
              detail: lastDetail,
            });
          }
          currentInterval = setInterval(async () => {
            await retry();

            if (finished) {
              resolve();
            }
          }, 5000);
        }).finally(() => clearInterval(currentInterval));
      }

      if (upgradeStatus !== FIRMWARE_UPGRADE_STATUS.READY_TO_REBOOT) {
        if (showMessage) {
          this.showFwUpgradeStatusMessage(upgradeStatus);
        }
        this[SET_IS_DEVICE_REBOOTING](false);
        this.$loading.hide();

        return upgradeStatus;
      }

      this.$loading.setDetail('');
      await this.showRebootCountdown('ID_FIRMWARE_RBING', undefined, reloadAfterReboot);

      return upgradeStatus;
    },
    /**
     * Show message for firmware upgrade
     * @param {number} statusCode - firmware upgrade status code
     * @returns {Promise<undefined>}
     */
    async showFwUpgradeStatusMessage(statusCode) {
      const message = FIRMWARE_UPGRADE_STATUS_MESSAGE_MAPPING[statusCode];

      if (message) {
        if (statusCode > 10000) {
          await this.$notify.error(message);
        } else {
          await this.$notify.info(message);
        }
      }
    },
    /**
     * Handle the error of the firmware upgrade.
     * @param {Object} error - The error data form API response
     * @returns {void}
     */
    handleFirmwareUpgradeError(error) {
      if (error.error_code === 0) {
        return;
      }

      if (FIRMWARE_UPGRADE_BACKUP_FAILED_ERROR_CODES.includes(error.error_code)) {
        this.setRetryBackupInfo(error.result.fwInfo);
        this.$modal.show('firmware-restore-failed-confirm');

        return;
      }

      const message = FIRMWARE_UPGRADE_STATUS_MESSAGE_MAPPING[error.error_code];

      if (message) {
        this.$notify.error(message);
      } else {
        this.$notify(error.type, error.message);
      }
    },
    /**
     * Handle the firmware update steps.
     * @param {Function} fetchHandler - The function to call firmware update API
     * @param {Object} [options] - The extra options to handle special behavior
     * @param {boolean} [options.isInitialize] - True if is in the Initial page
     * @returns {Promise<void>}
     */
    async handleFirmwareUpdate(fetchHandler, { isInitialize = false } = {}) {
      await fetchHandler();

      this.$loading.setDetail('ID_FIRMWARE_CHECK');
      await new Promise((resolve) => {
        // wait 3 seconds for backend
        setTimeout(resolve, 3000);
      });

      const upgradeStatus = await this.checkFirmwareUpgradeStatus({
        showMessage: true,
        showLoading: false,
        downloadSkippable: isInitialize,
        reloadAfterReboot: !isInitialize,
      });

      /**
       * - Normally, the upgrade status will eventually stop at "Ready to reboot"
       * or any error code (10xxx).
       * - "Ready to reboot" means the firmware image applies successfully and needs
       * a reboot to finish upgrading.
       * - If the upgrade status is not the error code and not "Ready to reboot",
       * it means the unexpected error occurs.
       * - Therefore, the error code or status other than "Ready to reboot"
       * are regarded as the error.
       */
      if (upgradeStatus !== FIRMWARE_UPGRADE_STATUS.READY_TO_REBOOT) {
        const errorData = {
          upgradeStatus,
          error_code: 0,
        };

        throw errorData;
      }
    },
    /**
     * Update the firmware by remote.
     * @param {Object} [params]
     * @param {string} [params.type] - The firmware type
     * @param {boolean} [params.isInitialize] - True if is in the Initial page
     * @returns {Promise<boolean>} - False if this action is aborted, else true
     */
    async updateFirmwareByRemote({ type = FIRMWARE_TYPE.OFFICIAL, isInitialize = false } = {}) {
      if (!isInitialize) {
        const confirmed = await this.$confirm.help([
          'ID_FIRMWARE_UPDATE_CONFIRM_MSG_1',
          'ID_FIRMWARE_UPDATE_CONFIRM_MSG_2',
        ]);

        if (!confirmed) {
          return false;
        }
      }
      this.$loading.show({
        text: 'ID_MIROS_WAITING_AUTO_UPDATE',
      });
      this.startFirmwareUpgrade(FIRMWARE_UPGRADE_TYPE.ONLINE);

      const payload = {
        onlineUpdateNow: true,
        fwType: type,
      };

      try {
        await this.handleFirmwareUpdate(
          () => Firmware.putWebFirmware(payload),
          { isInitialize },
        );
      } finally {
        this.$loading.hide();
      }

      return true;
    },
    /**
     * Update the firmware by the file.
     * @param {Object} params
     * @param {File} params.file - The ".img" file to update firmware
     * @param {boolean} [params.isInitialize] - True if is in the Initial page
     * @returns {Promise<boolean>} - False if this action is aborted, else true
     */
    async updateFirmwareByFile({ file, isInitialize = false }) {
      if (file.size > 200000000) { // 200MB
        this.$notify.info('ID_FIRMWARE_LG_FILE');

        return false;
      }

      if (!file.name.endsWith('.img')) {
        this.$notify.info('ID_FIRMWARE_WRG_TYPE');

        return false;
      }

      if (!isInitialize) {
        const confirmed = await this.$confirm.help([
          'ID_FIRMWARE_UPDATE_CONFIRM_MSG_1',
          'ID_FIRMWARE_UPDATE_CONFIRM_MSG_2',
        ]);

        if (!confirmed) {
          return false;
        }
      }

      this.$loading.show({
        text: 'ID_MIROS_WAITING_AUTO_UPDATE',
        detail: 'ID_FIRMWARE_UPLING',
      });
      this.startFirmwareUpgrade(FIRMWARE_UPGRADE_TYPE.MANUAL);

      try {
        await this.handleFirmwareUpdate(
          () => this.uploadFirmwareFile(file),
          { isInitialize },
        );
      } finally {
        this.$loading.hide();
      }

      return true;
    },
    /**
     * Upload the file to update firmware.
     * @param {File} file - The ".img" file to update firmware
     * @returns {Promise<void>}
     */
    async uploadFirmwareFile(file) {
      const payload = new FormData();

      payload.append('file', file);

      await Firmware.postFirmware(payload);
      this.$loading.setDetail('ID_FIRMWARE_CHECK');
    },
  },
};
