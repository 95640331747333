import { toASCII } from 'punycode';
import apiPath from '@/services/apiPath';
import { qFetch } from '@/services/utilities';
import { handleRestoreFormData } from './utilities/fileUpload';

/**
 * Convert the payload of the diagnostic.
 * @param {Object} payload - The payload of the diagnostic
 * @returns {Object} The formatted payload of the diagnostic
 */
function handleDiagnosticRequest(payload) {
  return {
    wanInterfaceID: payload.selectedWan,
    destination: toASCII(payload.destination),
    count: payload.pingCount,
  };
}

export default {
  /**
   * Get operation mode setting
   * @returns {Promise<Object>}
   */
  async getOperationSetting() {
    return qFetch.get(apiPath.v2.OperationSetting);
  },
  /**
   * Update operation mode setting
   * @param {Object} setting
   * @returns {Promise<void>}
   */
  async putOperationSetting(setting) {
    await qFetch.put(apiPath.v2.OperationSetting, { data: setting });
  },
  /**
   * Get event log with certain query
   * @param {Object} query
   * @param {number} query.level - event severity level
   * @param {number} query.limit - log number per page
   * @param {number} query.page - current page number
   * @param {string} query.search - keyword used to filter
   * @returns {Promise<Object>}
   */
  async getEventLog(query) {
    return qFetch.get(apiPath.v2.EventLogs, query);
  },
  /**
   * Export event log
   * @param {string} fileName
   * @returns {Promise<void>}
   */
  async exportEventLog(fileName) {
    await qFetch.download(apiPath.v2.EventLogsExport, fileName);
  },
  /**
   * Delete all event log
   * @returns {Promise<void>}
   */
  async deleteAllEventLog() {
    await qFetch.delete(apiPath.v2.EventLogs);
  },
  /**
   * Get device name
   * @returns {Promise<Object>}
   */
  async getDeviceName() {
    return qFetch.get(apiPath.v2.SystemDeviceName);
  },
  /**
   * Update device name
   * @param {string} deviceName
   * @returns {Promise<void>}
   */
  async putDeviceName(deviceName) {
    await qFetch.put(apiPath.v2.SystemDeviceName, { data: { hostname: deviceName } });
  },
  /**
   * Reboot system
   * @param {number} type - source type which trigger reboot action
   * @returns {Promise<void>}
   */
  async rebootSystem(type) {
    await qFetch.post(apiPath.v2.SystemReboot, { data: { type } });
  },
  /**
   * Reset system
   * @returns {Promise<void>}
   */
  async resetSystem() {
    await qFetch.post(apiPath.v2.SystemReset);
  },
  /**
   * Download system backup image
   * @param {string} fileName
   * @returns {Promise<void>}
   */
  async backupSystem(fileName) {
    await qFetch.download(apiPath.v2.SystemBackup, fileName);
  },
  /**
   * Restore system with uploaded file
   *
   * @param {File} file - File for system restore
   * @returns {Promise<void>}
   */
  async restoreSystem(file) {
    const payload = handleRestoreFormData(file);

    await qFetch.upload(apiPath.v2.SystemRestore, payload);
  },
  /**
   * Restore system with the file from the device.
   * @param {string} fileName - The file name from the device
   * @returns {Promise<void>}
   */
  async restoreSystemByDeviceFile(fileName) {
    await qFetch.post(apiPath.v2.SystemRestoreFile, { data: { name: fileName } });
  },
  /**
   * Get system buzzer setting
   * @returns {Promise<Object>}
   */
  async getSystemBuzzer() {
    return qFetch.get(apiPath.v2.SystemBuzzer);
  },
  /**
   * Update system buzzer setting
   * @param {Object} buzzerSetting
   * @returns {Promise<void>}
   */
  async patchSystemBuzzer(buzzerSetting) {
    await qFetch.patch(apiPath.v2.SystemBuzzer, { data: buzzerSetting });
  },
  /**
   * Get region setting
   * @returns {Promise<Object>}
   */
  async getRegionSetting() {
    return qFetch.get(apiPath.v2.RegionSetting);
  },
  /**
   * Update region setting
   * @param {string} region - 'global' or 'china'
   * @returns {Promise<void>}
   */
  async updateRegionSetting(region) {
    await qFetch.put(apiPath.v2.RegionSetting, { data: { region } });
  },
  /**
   * Get system hardware information (BMAC, serial number)
   * @returns {Promise<Object>}
   */
  async getHardwareInfo() {
    return qFetch.get(apiPath.v2.SystemHardware);
  },
  /**
   * Get system hardware information (cpu time, temperature)
   * @returns {Promise<Object>}
   */
  async getHardwareStatus() {
    return qFetch.get(apiPath.v2.SystemHardwareStatus);
  },
  /**
   * Get information of ui config
   *  - ui_buzzer_check_box, guide_wan_setting, qid_account, language
   * @returns {Promise<Object>}
   */
  async getUiSetting() {
    return qFetch.get(apiPath.v2.UiSetting);
  },
  /**
   * Update some information of ui config
   *  - ui_buzzer_check_box, guide_wan_setting, qid_account, language
   * @param {string} key
   * @param {any} value
   * @returns {Promise<void>}
   */
  async updateUiSetting(key, value) {
    await qFetch.patch(apiPath.v2.UiSetting, { data: { key, value } });
  },
  /**
   * Ping certain destination
   * @param {Object} setting
   * @returns {Promise<Object>}
   */
  async ping(setting) {
    return qFetch.post(apiPath.v2.DiagnosticPing, { data: handleDiagnosticRequest(setting) });
  },
  /**
   * Traceroute certain destination
   * @param {Object} setting
   * @returns {Promise<Object>}
   */
  async traceroute(setting) {
    return qFetch.post(apiPath.v2.DiagnosticTraceroute, { data: handleDiagnosticRequest(setting) });
  },
  /**
   * Stop diagnostic (ping, traceroute) process
   * @returns {Promise<Object>}
   */
  async stopDiagnostic() {
    return qFetch.delete(apiPath.v2.Diagnostic);
  },
  /**
   * Get diagnostic (ping, traceroute) result
   * @returns {Promise<Object>}
   */
  async getDiagnosticResult() {
    return qFetch.get(apiPath.v2.Diagnostic);
  },
  // TODO: confirm if ap mode network v1 API will be replaced by v2 API
  /**
   * Get ap mode network information
   * @returns {Promise<Object>}
   */
  async getApModeNetworkInfo() {
    return qFetch.get(apiPath.ApModeNetworkInfo);
  },
  /**
   * Update ap mode network information
   * @param {Object} payload
   * @returns {Promise<void>}
   */
  async putApModeNetworkInfo(payload) {
    await qFetch.put(apiPath.ApModeNetworkInfo, payload);
  },
  /**
   * Get status of country code setting
   * @returns {Promise<Object>}
   */
  async getCountryCodeStatus() {
    return qFetch.get(apiPath.v2.CountryCodeStatus);
  },
  /**
   * Put country code config.
   * @param {Object} countryCodeConfig - config of country code
   * @returns {Promise<Object>}
   */
  async putCountryCode(countryCodeConfig) {
    return qFetch.put(apiPath.v2.CountryCode, { data: countryCodeConfig });
  },
  /**
   * Get usb packet capture info and progress
   * @returns {Promise<Object>}
   */
  async getPacketCaptureInfo() {
    return qFetch.get(apiPath.v2.UsbPacketCapture);
  },
  /**
   * Put config to start usb packet capture
   * @param {Object} config - config of usb packet capture
   * @returns {Promise<Object>}
   */
  async putPacketCaptureInfo(config) {
    return qFetch.put(apiPath.v2.UsbPacketCapture, { data: config });
  },
  /**
   * Init some device status when start Initial
   * @returns {Promise<Object>}
   */
  async postDevice() {
    return qFetch.post(apiPath.v2.SystemDevice);
  },
  /**
   * Get current system schedule of reboot function
   * @returns {Promise<Object>}
   */
  async getSystemScheduleReboot() {
    return qFetch.get(`${apiPath.v2.SystemSchedule}?task=reboot`);
  },
  /**
   * Skip system schedule of reboot function once
   * @returns {Promise<void>}
   */
  async skipOnceSystemScheduleReboot() {
    await qFetch.post(`${apiPath.v2.SystemScheduleSkip}?task=reboot`);
  },
  /**
   * Update system schedule
   * @param {Object} data - detail of specific type schedule task
   * @returns {Promise<void>}
   */
  async updateSystemSchedule(data) {
    await qFetch.put(apiPath.v2.SystemSchedule, { data });
  },
  /**
   * Get the backup files info for restore.
   * @returns {Promise<Object>} The backup files info
   */
  async getRestoreBackupInfo() {
    const { result } = await qFetch.get(apiPath.v2.RestoreBackupInfo);

    return {
      fileNames: result.name,
    };
  },
  /**
   * Download the backup file by the file name.
   * @param {string} fileName - The file name for download
   * @returns {Promise<void>}
   */
  async downloadRestoreBackupFile(fileName) {
    await qFetch.download(`${apiPath.v2.RestoreBackupFile}?name=${fileName}`);
  },
};
