import AUTH from './auth';
import CONTROLHOST from './controlhost';
import DDNS from './ddns';
import DEBUGMODE from './debugmode';
import FIREWALL from './firewall';
import INITIAL from './initial';
import MIRO from './miro';
import PARENTALCONTROL from './parentalcontrol';
import QOS from './qos';
import QUWAN from './quwan';
import SERVICEPORT from './serviceport';
import UPNP from './upnp';
import USB from './usb';
import VPN from './vpn';
import VPNCLIENT from './vpnclient';

// v2 API
import AccessControl from './modules/AccessControl';
import AirGap from './modules/AirGap';
import BlockedClient from './modules/BlockedClient';
import Client from './modules/Client';
import FaultyDeviceReplacement from './modules/FaultyDeviceReplacement';
import Firewall from './modules/Firewall';
import Firmware from './modules/Firmware';
import Initialize from './modules/Initialize';
import Iptv from './modules/Iptv';
import Nat from './modules/Nat';
import Network from './modules/Network';
import Qnap from './modules/Qnap';
import RemoteSupport from './modules/RemoteSupport';
import Route from './modules/Route';
import System from './modules/System';
import VpnServer from './modules/VpnServer';
import Wireless from './modules/Wireless';

export default {
  // v1 API
  AUTH,
  MIRO,
  PARENTALCONTROL,
  INITIAL,
  QUWAN,
  FIREWALL,
  VPN,
  VPNCLIENT,
  DEBUGMODE,
  USB,
  UPNP,
  DDNS,
  QOS,
  SERVICEPORT,
  CONTROLHOST,
};

export {
  // v2 API: camel case
  AccessControl,
  AirGap,
  BlockedClient,
  Client,
  FaultyDeviceReplacement,
  Network,
  System,
  Wireless,
  Nat,
  RemoteSupport,
  Route,
  Firmware,
  Initialize,
  VpnServer,
  Qnap,
  Firewall,
  Iptv,
};
