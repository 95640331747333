export const EVENT_NAME = {
  CLIENTS_RATE: 'CLIENTS',
  MIROS_RATE: 'MIROS',
  DASHBOARDS: 'DASHBOARDS',
  CPUTEMPS: 'CPUTEMPS',
  TRAFFICS: 'TRAFFICS',
};

export const YOUTUBE_SAFE_SEARCH_OPT = {
  MEDIUM: 'medium',
  RESTRICT: 'restrict',
};

export const RULE_MASK_ENUM = {
  act_safe_search: 1,
  act_web_filter: 2,
};

export const SAFE_SEARCH_ENUM = {
  googleSafeSearch: 1,
  youtubeMediumSafeSearch: 2,
  youtubeRestrictedSafeSearch: 4,
  bingSafeSearch: 8,
  youtubeSafeSearch: 16,
};

export const SAFE_SEARCH_DOMAIN_KEYWORD = {
  YOUTUBE: 'youtube',
  GOOGLE: 'google',
  BING: 'bing',
};

export const WIRELESS_LIMIT = {
  RAD_PORT_LOWBOUND: 1,
  RAD_PORT_UPBOUND: 65535,
  SSID_LOWBOUND: 2,
  SSID_UPBOUND: 32,
  PASSWORD_LOWBOUND: 8,
  PASSWORD_UPBOUND: 63,
  CTSRTS_LOWBOUND: 1,
  CTSRTS_UPBOUND: 2347,
};

export const SUBNET_MASK = {
  32: '255.255.255.255',
  31: '255.255.255.254',
  30: '255.255.255.252',
  29: '255.255.255.248',
  28: '255.255.255.240',
  27: '255.255.255.224',
  26: '255.255.255.192',
  25: '255.255.255.128',
  24: '255.255.255.0',
  23: '255.255.254.0',
  22: '255.255.252.0',
  21: '255.255.248.0',
  20: '255.255.240.0',
  19: '255.255.224.0',
  18: '255.255.192.0',
  17: '255.255.128.0',
  16: '255.255.0.0',
  15: '255.254.0.0',
  14: '255.252.0.0',
  13: '255.248.0.0',
  12: '255.240.0.0',
  11: '255.224.0.0',
  10: '255.192.0.0',
  9: '255.128.0.0',
  8: '255.0.0.0',
  7: '254.0.0.0',
  6: '252.0.0.0',
  5: '248.0.0.0',
  4: '240.0.0.0',
  3: '224.0.0.0',
  2: '192.0.0.0',
  1: '128.0.0.0',
};

export const CUSTOM_ERROR_TYPE = {
  QUNMS_NETWORK_CONFLICT: 'QunmsNetworkConflict',
  QUNMS_VPN_TUNNEL_CHECK: 'QunmsVpnTunnelCheck',
  QUNMS_DEPLOYMENT: 'QunmsDeployment',
  QUNMS_INTERFACE_IP_CONFLICT: 'QunmsInterfaceIpConflict',
};

export const MODAL_NOTIFY_ID = {
  NOTIFY_QUNMS_NETWORK_CONFLICT_ID: 'networkConflictError',
  NOTIFY_QUWAN_DEPLOYMENT_ERROR_ID: 'quwanDeploymentError',
};

export const FIRMWARE_UPGRADE_STATUS = {
  NOT_UPGRADING: 0,
  UPGRADING: 1,
  READY_TO_REBOOT: 2,
  UPGRADE_SUCCESS: 4,
  DOWNLOADING: 5,
  CHECKING_FILE: 6,
};

export const RESERVED_PORT_SERVICE_NAME = {
  QUWAN: 'quwan',
  QBELT: 'qbelt',
  L2TP: 'l2tp',
  OPENVPN: 'openvpn',
  WIREGUARD: 'wireguard',
};

export const SCHEDULE_FREQUENCY_TYPE = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

export const IPTV_INTERFACE_NAME_DISPLAY = {
  IPTV: ' - IPTV usage',
  VOIP: ' - VoIP usage',
  INTERNET: ' - Internet (IPTV function)',
};

export const SCHEDULE_TIME_HALF_HOUR_OPTIONS = [
  { text: '00:00', value: '0000' },
  { text: '00:30', value: '0030' },
  { text: '01:00', value: '0100' },
  { text: '01:30', value: '0130' },
  { text: '02:00', value: '0200' },
  { text: '02:30', value: '0230' },
  { text: '03:00', value: '0300' },
  { text: '03:30', value: '0330' },
  { text: '04:00', value: '0400' },
  { text: '04:30', value: '0430' },
  { text: '05:00', value: '0500' },
  { text: '05:30', value: '0530' },
  { text: '06:00', value: '0600' },
  { text: '06:30', value: '0630' },
  { text: '07:00', value: '0700' },
  { text: '07:30', value: '0730' },
  { text: '08:00', value: '0800' },
  { text: '08:30', value: '0830' },
  { text: '09:00', value: '0900' },
  { text: '09:30', value: '0930' },
  { text: '10:00', value: '1000' },
  { text: '10:30', value: '1030' },
  { text: '11:00', value: '1100' },
  { text: '11:30', value: '1130' },
  { text: '12:00', value: '1200' },
  { text: '12:30', value: '1230' },
  { text: '13:00', value: '1300' },
  { text: '13:30', value: '1330' },
  { text: '14:00', value: '1400' },
  { text: '14:30', value: '1430' },
  { text: '15:00', value: '1500' },
  { text: '15:30', value: '1530' },
  { text: '16:00', value: '1600' },
  { text: '16:30', value: '1630' },
  { text: '17:00', value: '1700' },
  { text: '17:30', value: '1730' },
  { text: '18:00', value: '1800' },
  { text: '18:30', value: '1830' },
  { text: '19:00', value: '1900' },
  { text: '19:30', value: '1930' },
  { text: '20:00', value: '2000' },
  { text: '20:30', value: '2030' },
  { text: '21:00', value: '2100' },
  { text: '21:30', value: '2130' },
  { text: '22:00', value: '2200' },
  { text: '22:30', value: '2230' },
  { text: '23:00', value: '2300' },
  { text: '23:30', value: '2330' },
];
