import { handleStoredTime } from './system';

const STARTING_IDLE_TIME_KEY = 'startingIdleTime';
const ALLOWED_IDLE_TIME_KEY = 'allowedIdleTime';

/**
 * Get the starting idle time of the user
 * @returns {number} The starting idle time of the user
 */
function getStartingIdleTime() {
  const startingIdleTime = localStorage.getItem(STARTING_IDLE_TIME_KEY);

  return handleStoredTime(startingIdleTime);
}

/**
 * Remove the allowed idle time from local storage
 * @returns {void}
 */
export function clearAllowedIdleTime() {
  localStorage.removeItem(ALLOWED_IDLE_TIME_KEY);
}

/**
 * Remove the starting idle time from local storage
 * @returns {void}
 */
export function clearStartingIdleTime() {
  localStorage.removeItem(STARTING_IDLE_TIME_KEY);
}

/**
 * Get the allowed idle time for the system
 * @returns {number} The allowed idle time for the system
 */
export function getAllowedIdleTime() {
  const allowedIdleTime = localStorage.getItem(ALLOWED_IDLE_TIME_KEY);

  return handleStoredTime(allowedIdleTime);
}

/**
 * Check whether the allowed idle time exists
 * @returns {boolean} True if the allowed idle time exists
 */
export function hasAllowedIdleTime() {
  const idleTime = localStorage.getItem(ALLOWED_IDLE_TIME_KEY);

  return handleStoredTime(idleTime) > 0;
}

/**
 * Check whether the idle time is over the allowed idle time
 * @returns {boolean} True if the idle time is over the allowed idle time
 */
export function hasLongIdleTime() {
  if (hasAllowedIdleTime()) {
    return (Date.now() - getStartingIdleTime()) > getAllowedIdleTime();
  }

  return false;
}

/**
 * Check whether the starting idle time exists
 * @returns {boolean} True if the starting idle time exists
 */
export function hasStartingIdleTime() {
  const idleTime = localStorage.getItem(STARTING_IDLE_TIME_KEY);

  return handleStoredTime(idleTime) > 0;
}

/**
 * Set the allowed idle time in local storage
 * @param {number} allowedIdleTime - Time for the user to be idle in GUI
 * @returns {void}
 */
export function setAllowedIdleTime(allowedIdleTime) {
  localStorage.setItem(ALLOWED_IDLE_TIME_KEY, allowedIdleTime);
}

/**
   * Set the starting idle time of the user in local storage
   * @param {number} startingIdleTime - Time for the user starting idle
   * @returns {void}
   */
export function setStartingIdleTime(statingIdleTime) {
  localStorage.setItem(STARTING_IDLE_TIME_KEY, statingIdleTime);
}
