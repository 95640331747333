import apiPath from './apiPath';
import { qFetch } from './utilities';

/**
 * Format region from API for GUI
 *
 * @param {Object} region - Region from API
 * @returns {Object} Formatted region for GUI
 */
function handleRegionResponse(region) {
  return {
    regionId: region.groupId,
    regionName: region.groupName,
    domainName: region.domainName,
    description: region.description,
    hubs: region.hubs,
    edges: region.edges,
  };
}

export default {
  /**
   * Get service of cloud
   *
   * @returns {Promise<Object>} Service of cloud
   */
  async getService() {
    const { result } = await qFetch.get(apiPath.CloudService);

    return {
      cloudCore: result.cloud_core,
    };
  },

  /**
   * Get whether device is on QuNMS
   *
   * @return {Promise<boolean>} True if device is created on QuNMS
   */
  async checkDeviceOnQunms() {
    const { result } = await qFetch.get(apiPath.QuwanStatus);

    return result.cloudAgentRunning;
  },

  /**
   * Check whether the list of device VPN tunnels reach limitation
   * @param {Object} device - Device to check VPN limitation
   * @param {string} device.organizationId - ID of the selected organization to deploy
   * @param {string} device.regionId - ID of the selected region to deploy
   * @param {string} device.role - Role of the device
   * @param {number} device.wanTotal - Total of the WAN interfaces
   * @returns {Promise<void>}
   */
  async checkVpnTunnelLimitation(device) {
    await qFetch.post(apiPath.VpnTunnelCheck, {
      organizationId: device.organizationId,
      groupId: device.regionId,
      vpnRole: device.role,
      wanNum: device.wanTotal,
    });
  },

  /**
   * Create a device on the QuWAN cloud
   * @param {Object} config - Configuration for the QuWAN deployment
   * @returns {Promise<Object>} Configuration for the QuWAN deployment
   */
  async createQunmsDevice(config) {
    const { result } = await qFetch.post(apiPath.v2.QuwanDevice, {
      data: {
        name: config.name,
        organizationId: config.organizationId,
        regionId: config.regionId,
        role: config.role,
        siteId: config.siteId,
      },
    });

    return result;
  },

  /**
   * Create region of organization
   *
   * @param {string} organizationId - Id of organization
   * @param {Object} regionSetting - Setting of region to create
   * @returns {Promise<Object>} Updated region of organization
   */
  async createRegion(organizationId, regionSetting) {
    const { result } = await qFetch.post(
      apiPath.GetRegions(organizationId),
      regionSetting,
    );

    return handleRegionResponse(result.vpnGroup);
  },

  /**
   * Delete device of QuNMS
   *
   * @returns {Promise>} No Content
   */
  async deleteQunmsDevice() {
    await qFetch.delete(apiPath.QunmsDevice);
  },

  /**
   * Get connection status of device
   *
   * @returns {Promise<Object>} Connection status of device
   */
  async getConnectionStatus() {
    const response = await qFetch.get(apiPath.ConnectionStatus);

    return response.result;
  },

  /**
   * Get organizations list
   *
   * @returns {Promise<Object[]>} List of organizations
   */
  async getOrganizations() {
    const { result } = await qFetch.get(apiPath.Organizations);

    return result.map((organization) => ({
      country: organization.country,
      createdAt: organization.created_at,
      isOwner: organization.is_owner,
      name: organization.name,
      organizationId: organization.organization_id,
      phone: organization.phone,
      size: organization.size,
      updatedAt: organization.updated_at,
      website: organization.website,
    }));
  },

  /**
   * Get status of deployment progress
   *
   * @returns {Promise<Object>} Status of deployment progress
   */
  async getDeploymentProgress() {
    const response = await qFetch.get(apiPath.DeploymentProgress);

    return response.result;
  },

  /**
   * Get settings of QuWAN
   *
   * @returns {Promise<Object>} Settings of QuWAN
   */
  async getQuwanSettings() {
    const response = await qFetch.get(apiPath.QuwanSettings);

    return response.result;
  },

  /**
   * Get region list
   *
   * @param {string} organizationId - Id of organization
   * @returns {Promise<Object[]>} List of regions
   */
  async getRegions(organizationId) {
    const { result } = await qFetch.get(apiPath.GetRegions(organizationId));

    return result.vpnGroups.map((group) => handleRegionResponse(group));
  },

  /**
   * Get site list
   *
   * @param {string} organizationId - Id of organization
   * @returns {Promise<Object[]>} List of sites
   */
  async getSites(organizationId) {
    const response = await qFetch.get(apiPath.GetSites(organizationId));

    return response.result;
  },

  /**
   * Get quwan device type
   * @returns {Promise<Object>}
   */
  async getDeviceType() {
    return qFetch.get(apiPath.QuwanType);
  },

  /**
   * Get quwan version
   * @returns {Promise<Object>}
   */
  async getQuwanVersion() {
    return qFetch.get(apiPath.v2.QuwanVersion);
  },

  /**
   * Get quwan vpn server setting and status
   * @returns {Promise<Object>}
   */
  async getQuwanVpnServers() {
    return qFetch.get(apiPath.v2.QuwanVpnServers);
  },

  /**
   * Get quwan vpn online user information
   * @returns {Promise<Object>}
   */
  async getQuwanVpnOnlineUsers() {
    return qFetch.get(apiPath.v2.QuwanVpnOnlineUsers);
  },

  /**
   * Check local IP or subnet does not conflict with remote.
   * @param {string} [orgId] - organization ID
   * @returns {Promise<Object>}
   */
  async postQuwanNetworkIpSubnetCheck(orgId) {
    const data = orgId === undefined ? {} : { orgId };

    return qFetch.post(apiPath.v2.QuwanNetworkIpSubnetCheck, { data });
  },

  /**
   * Check whether the single network config is conflict with QuWAN network
   * @param {string} orgId - ID of the QuWAN organization
   * @param {Object} networkConfig - Config of the network
   * @returns {Promise<void>}
   */
  async checkSingleNetworkConflict(orgId, networkConfig) {
    // The id would be used in the error message to map to which interface is conflict
    const id = networkConfig.id || networkConfig.bridgeId || networkConfig.vlanId || networkConfig.portName || '';

    await qFetch.post(apiPath.v2.QuwanNetworkSingleIpSubnetCheck, {
      item: {
        bridgeId: networkConfig.bridgeId || '',
        id: String(id),
        ip: networkConfig.ip4Address,
        metric: 0,
        nextHopValue: '',
        portNum: networkConfig.portName || '',
        shared: false,
        submask: String(networkConfig.ip4Prefix),
        type: networkConfig.type,
        vlanId: networkConfig.vlanId ? String(networkConfig.vlanId) : '',
      },
      orgId,
    });
  },
};
